import React, {useEffect, useState} from "react"
import {Alert, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {map} from "lodash";
import { Tooltip } from 'reactstrap';
import FeatherIcon from "feather-icons-react";
import TooltipComponent from "../../components/TooltipComponent";
export default function HealthandSafety( {inputFields, setInputFields}){
    const [error, setError] = useState(false);
    const [hsModal, setHsModal] = useState(false);
    const [hsStuff, setHsStuff] = useState(false);
    const[showCustom, setShowCustom]=useState(false)
    const [customFields, setCustomFields] = useState({});
    const [customId, setCustomId] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    function tog_hsModal() {
        setHsModal(!hsModal)
    }

    const handleHsFormChange = (key, event) => {
        const { name, value } = event.target;
        let updatedFields = [...inputFields];
        updatedFields[key][name] = value;
        setInputFields(updatedFields);
    };


    const handleHsFormSelChange = (key, event) => {

        if (showCustom){
            setShowCustom(false)
        }
        const { name, value } = event.target;
        let updatedFields = [...inputFields];
        updatedFields[key][name] = value;

        if (name === "hsIndicator" && value === "Custom") {
            setCustomFields({ ...customFields, [key]: true }); // Show custom field for this row
            updatedFields[key][name] = "";
        } else {
            setCustomFields({ ...customFields, [key]: false }); // Hide custom field
        }

        setInputFields(updatedFields);
        console.log(inputFields)
    };


    const leadingIndicators = [
        'Safety Meeting',
        'Talkbox Talk Meeting',
        'Orientation / Induction',
        'Inspection',
        'Corrective Action'
    ]
    const laggingIndicators = [
        'First Aids',
        'Medical Treatments',
        'Lost Time',
        'Other Recordable',
        'Reportable',
        'Near Misses',
        'LTI'
    ]
    const addHsFields = () => {
        let newfield = { hsIndicatorType: '', hsIndicator: '', hsQuantity: '', hsComment:'' };

        setInputFields([...inputFields, newfield]);
    };

    const removeHsFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    useEffect(() => {
       /* getLabourList()*/
    }, [])

    const handleShowCustom = (id)=>{
        setShowCustom(!showCustom)
        setCustomId(id)
    }
    return (
        <>
            <Modal
                isOpen={hsModal}
                toggle={() => {
                    tog_hsModal();
                }}
            >
                {/* <Form onSubmit={e => addMaterial(e)}> */}
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        H&S Log   <div className="tooltip1"> <FeatherIcon icon={"info"} />
                        <span className="tooltiptext">
                           Indicator Types <br/>
                            <b>Leading indicators</b> are proactive measures you took to prevent incidents eg. Safety Training
                            <br />
                           <b> Lagging indicators</b> are reactive measures of past safety performance eg. injury rates
                        </span>
                    </div>
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setHsModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {map(inputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="12" xs="12">
                                <div className="mb-3">
                                    <label>Indicator Type </label>
                                    <select
                                        className="form-select"
                                        required
                                        name="hsIndicatorType"
                                        onChange={(e) => handleHsFormSelChange(key, e)}
                                        value={item.hsIndicatorType}>
                                        <option value="">Select </option>
                                        <option value="Lagging Indicator">Lagging Indicator</option>
                                        <option value="Leading Indicator">Leading Indicator</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md="7" xs="7">
                                {showCustom && customId===key ? <>
                                    <div className="mb-3">
                                        <label>Indicator {inputFields[key].hsIndicatorType}</label>
                                        <select
                                            className="form-select"
                                            required
                                            name="hsIndicator"
                                            onChange={(e) => handleHsFormSelChange(key, e)}
                                            value={'Custom'}>
                                            <option value="">Select </option>
                                            {
                                                inputFields[key].hsIndicatorType === 'Lagging Indicator' ? <>
                                                    {laggingIndicators.map((indicator, index) => (
                                                        <option key={index} value={indicator}>
                                                            {indicator}
                                                        </option>
                                                    ))}
                                                </> : <>
                                                    {leadingIndicators.map((indicator, index) => (
                                                        <option key={index} value={indicator}>
                                                            {indicator}
                                                        </option>
                                                    ))}
                                                </>
                                            }

                                            <option onClick={()=>handleShowCustom(key)} value="Custom">Custom</option>
                                        </select>
                                    </div>

                                </> : <>

                                    <div className="mb-3">
                                        <label>Indicator</label>
                                        <select
                                            className="form-select"
                                            required
                                            name="hsIndicator"
                                            onChange={(e) => handleHsFormSelChange(key, e)}
                                            value={item.hsIndicator}>
                                            <option value="">Select </option>
                                            {
                                                inputFields[key].hsIndicatorType==='Lagging Indicator' ? <>
                                                    {laggingIndicators.map((indicator, index) => (
                                                        <option key={index} value={indicator}>
                                                            {indicator}
                                                        </option>
                                                    ))}
                                                </> : <>
                                                    {leadingIndicators.map((indicator, index) => (
                                                        <option key={index} value={indicator}>
                                                            {indicator}
                                                        </option>
                                                    ))}
                                                </>
                                            }

                                            <option onClick={()=>handleShowCustom(key)} value="Custom">Custom</option>
                                        </select>
                                    </div>
                                </>}

                            </Col>
                            <Col md="5" xs="5">
                                <FormGroup className="mb-3">
                                    <label>Quantity</label>
                                    <Input
                                        name="hsQuantity"
                                        required
                                        className="form-control"
                                        placeholder="Enter number"
                                        type="number"
                                        min={0}
                                        onChange={(e) => handleHsFormChange(key, e)}
                                        value={item.hsQuantity}
                                    />
                                </FormGroup>
                            </Col>

                            {customFields[key] && (
                                <Col md="12" xs="12">
                                    <FormGroup className="mb-3">
                                        <label>Custom Indicator Name</label>
                                        <Input
                                            name="hsIndicator"
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => handleHsFormChange(key, e)}
                                            value={item.hsIndicator}
                                        />
                                    </FormGroup>
                                </Col>
                            )}

                            <Col md="12" xs="12">
                                <FormGroup className="mb-3">
                                    <label>Comment</label>
                                    <Input
                                        name="hsComment"
                                        className="form-control"
                                        placeholder="Enter Comment"
                                        type="text"
                                        onChange={(e) => handleHsFormChange(key, e)}
                                        value={item.hsComment}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn add-new w-100"
                                onClick={addHsFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add indicator
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_hsModal();
                        }}
                        className="btn cancel-button "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn submit-button" onClick={() => {
                        setHsModal(false);
                        setHsStuff(true);
                    }}>
                        Add
                    </button>
                </div>
                {/* </Form> */}
            </Modal>
            <div className={"container d-flex justify-content-between align-items-center  px-3 py-3"}>
                <h5>Environmental Health and Safety</h5>
                <button type={"button"} className={"btn submit-button py-2"} onClick={tog_hsModal}>+ Add Items</button>
            </div>
            <div className={"container px-3"}>
                <div className={"row"}>
                    <div className={"col-md-6 pe-5"}>
                        <table className={"table task-progress mt-3 border"}>
                            <thead>
                            <tr>
                                <th colSpan={3}>Leading Indicators</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                inputFields ? <>
                                    {
                                        inputFields
                                            .filter(item => item.hsIndicatorType === "Leading Indicator") // Change to desired type
                                            .map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.hsIndicator}</td>
                                                    <td>{item.hsQuantity}</td>

                                                </tr>
                                            ))
                                    }
                                </> : <>

                                </>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className={"col-md-6 ps-5"}>
                        <table className={"table task-progress mt-3 border"}>
                            <thead>
                            <tr>
                                <th colSpan={3}>Lagging Indicators</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                inputFields ? <>
                                    {
                                        inputFields
                                            .filter(item => item.hsIndicatorType === "Lagging Indicator") // Change to desired type
                                            .map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.hsIndicator}</td>
                                                    <td>{item.hsQuantity}</td>
                                                </tr>
                                            ))
                                    }
                                </> : <>

                                </>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}