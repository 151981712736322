import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import axios from "axios";
import {Col, Row} from "reactstrap";
export default function Photos(){
    const {id}= useParams()
    const[startDate, setStartDate]=useState('')
    const[endDate, setEndDate]=useState('')
    const [photos, setPhotos]=useState([])
    const getPhotos = async ()=>{
        const api_url = process.env.REACT_APP_API_BASE + `track-logs/images/${id}?startDate=${localStorage.getItem('startDate')}&endDate=${localStorage.getItem('endDate')}`;
        const response = await axios.get(api_url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token,
            }})
        setPhotos(response.data.data)

    }
    useEffect(()=>{
        setStartDate(localStorage.getItem('startDate'))
        setEndDate(localStorage.getItem('endDate'))
        getPhotos()
    }, [])
    return (
        <>
            <Row>
                {
                    photos.length > 0 ? <>
                        {
                            photos.map((photo)=>(
                                <>
                                    <Col xl={2} md={2}>
                                        <img src={photo.image[0]} className={"w-100 equal-height-img"}/>
                                    </Col>
                                </>
                            ))
                        }
                    </> : <>
                    <p>No photos found</p>
                    </>
                }
            </Row>
        </>
    )
}