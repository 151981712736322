import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import axios from "axios";
export default function ChangeOrders(){
    const {id}=useParams()
    const[hsItems, setHsItems]=useState([])
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    var api_url =  process.env.REACT_APP_API_BASE + `reports/health-safety/${id}?startDate=${localStorage.getItem('startDate')}&endDate=${localStorage.getItem('endDate')}`;
    const getHSReports = async () =>{
        try {
            const response = await axios(api_url, config)
            const filteredData = response.data.data.flatMap(entry =>
                entry.healthAndSafety
                    .filter(item => item.hsIndicatorType && item.hsIndicator && item.hsQuantity)
                    .map(item => ({ ...item, logDate: entry.logDate }))
            );
            setHsItems(filteredData)
            console.log(filteredData)
        } catch (e) {

        } finally {

        }
    }
    useEffect(()=>{
        getHSReports()

    }, [])
    return (
        <>
            <div className={"row"}>
                <div className={"col-md-6 pe-5"}>
                    <table className={"table task-progress mt-3 border"}>
                        <thead>
                        <tr>
                            <th colSpan={3}>Leading Indicators</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            hsItems ? <>
                                {
                                    hsItems
                                        .filter(item => item.hsIndicatorType === "Leading Indicator") // Change to desired type
                                        .map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.hsIndicator}</td>
                                                <td>{item.hsQuantity}</td>
                                                <td><img style={{ width: '20px' }} src={"/Trash.png"} /></td>
                                            </tr>
                                        ))
                                }
                            </> : <>

                            </>
                        }
                        </tbody>
                    </table>
                </div>
                <div className={"col-md-6 ps-5"}>
                    <table className={"table task-progress mt-3 border"}>
                        <thead>
                        <tr>
                            <th colSpan={3}>Lagging Indicators</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            hsItems ? <>
                                {
                                   hsItems
                                        .filter(item => item.hsIndicatorType === "Lagging Indicator") // Change to desired type
                                        .map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.hsIndicator}</td>
                                                <td>{item.hsQuantity}</td>
                                                <td><img style={{ width: '20px' }} src={"/Trash.png"} /></td>
                                            </tr>
                                        ))
                                }
                            </> : <>

                            </>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}