import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from "reactstrap";
import { FaPaperPlane } from "react-icons/fa";
import ProjectDetailsReport from "./ProjectDetailsReport";
import Photos from "./Photos";
import axios from "axios";
import * as url from "../../../helpers/jengasmart_api_urls";
import { useParams } from "react-router-dom";
import ProgressReport from "./ProgressReport";
import CostReport from "./CostReport";
import MaterialsReports from "./MaterialsReports";
import EquipmentReports from "./EquipmentReports";
import LabourReports from "./LabourReports";
import ChangeOrders from "./ChangeOrders";
import ValuationReport from "./ValuationReport";
import HealthandSafety from "./HealthandSafety";

export default function ViewReport({reportTypes}) {
    const {id} = useParams()
    const [weatherDetails, setWeatherDetails] = useState({});
    const [loading, setLoading] = useState(true)
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };

    const getWeather = async () => {
        try {
            const api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/' + id;
            const projectinfo = await axios.get(api_url, config);

            const latitude = projectinfo.data.data.latitude;
            const longitude = projectinfo.data.data.longitude;

            let startDate = new Date(localStorage.getItem('startDate'));
            let endDate = new Date(localStorage.getItem('endDate'));

            let diffInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

            if (diffInDays > 7) {
                startDate = new Date(endDate);
                startDate.setDate(endDate.getDate() - 6);
            }

            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            const weather_url = `https://api.weatherapi.com/v1/history.json?key=${process.env.REACT_APP_WEATHER_APICOM_KEY}&q=${latitude},${longitude}&dt=${formattedStartDate}&end_dt=${formattedEndDate}`;

            const weatherRes = await axios.get(weather_url, config);
            setWeatherDetails(weatherRes.data.forecast.forecastday);
            setLoading(false)
            console.log(weatherRes.data.forecast.forecastday)
        } catch (error) {
            console.error("Error fetching weather data:", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'short', day: '2-digit' };
        return date.toLocaleDateString('en-US', options).replace(',', '');
    };

    const handleExport = () => {
        window.print();
    };

    useEffect(() => {
        getWeather()
        console.log(reportTypes)
    }, []);

    return (
        <>
            <style type="text/css" media="print">
                {`
                    @media print {
                        @page {
                            margin: 0.5in;
                        }
                        body * {
                            visibility: hidden;
                        }
                        .container-fluid, .container-fluid * {
                            visibility: visible;
                        }
                        .container-fluid {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            padding: 20px !important;
                        }
                        .container-fluid > div:first-child {
                            display: none !important;
                        }
                        .project-details-report {
                            margin-top: 0 !important;
                        }
                        .progress-section {
                            margin-top: 2rem !important;
                            background-color: rgba(0, 0, 0, 0.05) !important;
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                            page-break-inside: avoid !important;
                        }
                        .weather-container {
                            padding: 1rem !important;
                            page-break-inside: avoid !important;
                        }
                        .weather-container * {
                            visibility: visible !important;
                            display: block !important;
                        }
                        .card, .card-body {
                            page-break-inside: avoid !important;
                        }
                        .reports-navigation,
                        .nav,
                        .nav-tabs,
                        .nav-item,
                        .nav-link,
                        .tab-content,
                        .tab-pane {
                            display: none !important;
                            visibility: hidden !important;
                        }
                        .btn {
                            display: none;
                        }
                        img.equal-height-img {
                            max-width: 100% !important;
                            height: auto !important;
                            object-fit: contain !important;
                            page-break-inside: avoid !important;
                        }
                        .row img.equal-height-img {
                            width: 150px !important;
                            height: 150px !important;
                            object-fit: cover !important;
                            margin: 5px !important;
                        }
                        .row .col-xl-2,
                        .row .col-md-2 {
                            width: auto !important;
                            flex: none !important;
                            max-width: none !important;
                            display: inline-block !important;
                            page-break-inside: avoid !important;
                            padding: 5px !important;
                        }
                        .row {
                            display: flex !important;
                            flex-wrap: wrap !important;
                            justify-content: flex-start !important;
                            gap: 10px !important;
                            page-break-inside: auto !important;
                        }
                        .card {
                            background-color: inherit !important;
                            border: 1px solid #dee2e6 !important;
                            margin: 1rem 0 !important;
                            page-break-inside: avoid !important;
                        }
                        .black-50-bg {
                            background-color: rgba(0, 0, 0, 0.05) !important;
                        }
                        table {
                            width: 100% !important;
                            border-collapse: collapse !important;
                            page-break-inside: auto !important;
                        }
                        tr {
                            page-break-inside: avoid !important;
                            page-break-after: auto !important;
                        }
                        th, td {
                            border: 1px solid #dee2e6 !important;
                            padding: 8px !important;
                        }
                        th {
                            background-color: #f8f9fa !important;
                        }
                        .primary-text-color {
                            color: rgb(25, 73, 115) !important;
                            margin-bottom: 2rem !important;
                        }
                        .text-white {
                            color: white !important;
                        }
                    }
                `}
            </style>
            <div className={"container-fluid px-3"}>
                <div className={"container-fluid d-flex justify-content-between align-items-center mb-5"}>
                    <h5 className="primary-text-color fw-bold mb-0">
                        Custom Report
                    </h5>
                    <button 
                        className={"btn text-white fw-bold"} 
                        style={{background:"rgb(25, 73, 115)", fontSize:"11px"}}
                        onClick={handleExport}
                    >
                        <FaPaperPlane /> Export Report
                    </button>
                </div>
                <ProjectDetailsReport />
                <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                    <CardBody>
                        <h5 className="primary-text-color fw-bold">
                            Weather on Site
                        </h5>
                        <div className="weather-container container-fluid">
                            {loading ? (
                                <p>Loading weather...</p>
                            ) : (
                                weatherDetails.length > 0 && (
                                    <>
                                        {weatherDetails.map((weather) => (
                                            <div key={weather.date} className={"weather-item"}>
                                                {formatDate(weather.date)}
                                                <br />
                                                <img className={"weather-icon"} src={weather.day.condition.icon} alt="Weather icon"/>
                                                <br />
                                                {weather.day.avgtemp_c}
                                            </div>
                                        ))}
                                    </>
                                )
                            )}
                        </div>
                    </CardBody>
                </Card>
                {reportTypes.includes("Progress Report") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Progress Report
                            </h5>
                            <ProgressReport />
                        </CardBody>
                    </Card>
                )}
                {reportTypes.includes("Equipment") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Machinery and Equipment on Site
                            </h5>
                            <EquipmentReports />
                        </CardBody>
                    </Card>
                )}
                {reportTypes.includes("Labour") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Labour on Site
                            </h5>
                            <LabourReports />
                        </CardBody>
                    </Card>
                )}
                {reportTypes.includes("Materials") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Materials
                            </h5>
                            <MaterialsReports />
                        </CardBody>
                    </Card>
                )}
                {reportTypes.includes("Cost Report") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Cost Report
                            </h5>
                            <CostReport />
                        </CardBody>
                    </Card>
                )}
                {reportTypes.includes("Health Safety Report") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Environment Health and Safety
                            </h5>
                            <HealthandSafety />
                        </CardBody>
                    </Card>
                )}
                {reportTypes.includes("Change Orders") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Change Orders
                            </h5>
                            <ChangeOrders />
                        </CardBody>
                    </Card>
                )}
                {reportTypes.includes("Valuation Report") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Valuation Report
                            </h5>
                            <ValuationReport />
                        </CardBody>
                    </Card>
                )}
                {reportTypes.includes("Photos") && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Project Photos
                            </h5>
                            <Photos />
                        </CardBody>
                    </Card>
                )}
            </div>
        </>
    )
}