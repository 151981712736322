import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {useLocation, useParams} from "react-router-dom";
import {
    Alert,
    Container,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    Button,
    FormFeedback,
    Form,
    Modal
} from 'reactstrap';
// import Select from "react-select"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as url from "../../helpers/jengasmart_api_urls";

import axios from 'axios';
import { map } from "lodash";
import queryString from 'query-string';
import {toast, ToastContainer} from "react-toastify";
import {SubmitIndicator} from "../../components/Common";
import FeatherIcon from "feather-icons-react";

const EditLog = (props, {onDrop}) => {
    const params = useParams();
    const parsed = queryString.parse(location.search);
    const [customFields, setCustomFields] = useState({});
    const [projectError, setProjectError] = useState('');
    const [materialsModal, setMaterialsModal] = useState(false);
    const [hsModal, setHsModal] = useState(false);
    const [equipmentModal, setEquipmentModal] = useState(false);
    const [labourModal, setLabourModal] = useState(false);
    const [additionalCostModal, setAdditionalCostModal] = useState(false);
    const [subcontractorCostModal, setSubcontractorCostModal] = useState(false);
    const [quantitiesCostModal, setQuantitiesCostModal] = useState(false);
    const [uploading, setUploading] = useState(false)
    const [error, setError] = useState(false);
    const [taskType, setTaskType]=useState("")
    const [materialStuff, setMaterialStuff] = useState(false);
    const [materialsList, setMaterialsList] = useState([]);
    const [materialsItems, setMaterialsItems] = useState([]);
    const [materialInputFields, setMaterialInputFields] = useState([
        { materialName: '', materialUniqueId: '', materialQuantity: '', materialPrice: '' }
    ]);

    const [equipmentStuff, setEquipmentStuff] = useState(false);
    const [equipmentList, setEquipmentList] = useState([]);
    const [equipmentItems, setEquipmentItems] = useState([]);
    const [equipmentInputFields, setEquipmentInputFields] = useState([
        { equipmentName: '', equipmentNumber: '', equipmentRatePrice: '' }
    ]);
    const [logDetail, setLogDetail] = useState({});
    const [labourStuff, setLabourStuff] = useState(false);
    const [labourList, setLabourList] = useState([]);
    const [labourItems, setLabourItems] = useState([]);
    const [labourInputFields, setLabourInputFields] = useState([
        { labourName: '', labourNumber: '', labourRatePrice: '' }
    ]);
    const [hsStuff, setHsStuff] = useState(false);
    const [hsList, sethsList] = useState([]);
    const [hsItems, setHsItems] = useState([]);
    const [hsInputFields, setHsInputFields] = useState([
        { hsIndicatorType: '', hsIndicator: '', hsQuantity: '', hsComment:'' }
    ]);

    const [subcontractorCostsStuff, setSubcontractorCostsStuff] = useState(false);
    const [additionalCostsStuff, setAdditionalCostsCostsStuff] = useState(false);
    const [additionalCostsList, setAdditionalCostsList] = useState([]);
    const [additionalCostsItems, setAdditionalCostsItems] = useState([]);
    const [additionalCostsInputFields, setAdditionalCostsInputFields] = useState([
        { additionalCostDescription: '', additionalCostAmount: '' }
    ]);

    const [subContractorCostsItems, setSubContractorCostsItems] = useState([]);
    const [subcontractorCostsInputFields, setSubcontractorCostsInputFields] = useState([
        { subContractorName: '', subContractorCostAmount: '' }
    ]);

    const [quantitiesCostsStuff, setQuantitiesCostsStuff] = useState(false);
    const [quantitiesCostsList, setQuantitiesCostsList] = useState([]);
    const [quantitiesCostsItems, setQuantitiesCostsItems] = useState([]);
    const [quantitiesCostsInputFields, setQuantitiesCostsInputFields] = useState([
        { quantitiesName: '', quantitiesUnit: '', quantitiesQty: '', quantitiesRate: '' }
    ]);

    const [materialButtonDisabled, setMaterialButtonDisabled] = useState(true);
    const [equipmentButtonDisabled, setEquipmentButtonDisabled] = useState(true);
    const [LabourButtonDisabled, setLabourButtonDisabled] = useState(true);
    const [additionalCostButtonDisabled, setAdditionalCostButtonDisabled] = useState(true);
    const [subContractorCostButtonDisabled, setSubContractorCostButtonDisabled] = useState(true);
    const [quantitiesCostButtonDisabled, setQuantitiesCostButtonDisabled] = useState(true);
    const leadingIndicators = [
        'Safety Meeting',
        'Talkbox Talk Meeting',
        'Orientation / Induction',
        'Inspection',
        'Corrective Action'
    ]
    const laggingIndicators = [
        'First Aids',
        'Medical Treatments',
        'Lost Time',
        'Other Recordable',
        'Reportable',
        'Near Misses',
        'LTI'
    ]
    const handleShowCustom = (id)=>{
        setShowCustom(!showCustom)
        setCustomId(id)
    }
    const [parentTaskTagged, setParentTaskTagged]=useState('')
    const [subTaskTagged, setSubTaskTagged]=useState('')
    const [taskTagged, setTaskTagged]=useState('')
    const[completedToday, setCompletedToday]=useState("")
    const[forTomorrow, setForTomorrow]=useState("")
    const[delays, setDelays]=useState("")
    const addHsFields = () => {
        let newfield = { hsIndicatorType: '', hsIndicator: '', hsQuantity: '', hsComment:'' };

        setHsInputFields([...hsInputFields, newfield]);
    };

    const [draggedFiles, setDraggedFiles] = useState([]);
    const[showCustom, setShowCustom]=useState(false)
    const [customId, setCustomId] = useState(null);
    const [files, setFiles] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [parentTask, setParentTask]= useState([])
    const [subTask, setSubTask]= useState([])
    const[existingImages, setExistingImages]=useState([])
    const [taskData, setTaskData] = useState({
        projectId: params.id,
        userId: JSON.parse(localStorage.getItem('authUser')).user.id,
        logDate: '',
        weatherCondition: '',
        taskTagged: '',
        description: '',
        image:[]
    })
    const PARENT_TASK_URL = process.env.REACT_APP_API_BASE + "parenttasks/project/" + parsed.project
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getParentTasks = async ()=>{
        const parentTasks = await axios.get(PARENT_TASK_URL, config)
        setParentTask(parentTasks.data.data)
    }
    const onDropHandler = useCallback(
        (acceptedFiles) => {
            if (onDrop) {
                onDrop(acceptedFiles);
            }
        },
        [onDrop]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            setDraggedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
            onDropHandler(acceptedFiles);
            const fileInfo = acceptedFiles.map(({ name, size, type, lastModified, preview }) => ({
                name,
                size,
                type,
                lastModified,

            }));
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        },
        accept: {
            'image/png': ['.png'],
            'image/jpg':['.jpg'],
            'image/jpeg':['.jpeg'],
        },

    });
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));


    function tog_materialsModal() {
        setMaterialsModal(!materialsModal);
    }

    function tog_equipmentModal() {
        setEquipmentModal(!equipmentModal);
    }

    function tog_labourModal() {
        setLabourModal(!labourModal);
    }

    function tog_additionalCostsModal() {
        setAdditionalCostModal(!additionalCostModal);
    }
    function tog_subcontractorCostsModal() {
        setSubcontractorCostModal(!subcontractorCostModal);
    }
    function tog_quantitiesCostsModal() {
      setQuantitiesCostModal(!quantitiesCostModal);
    }

    const submitForm = async (e) => {
        e.preventDefault();
        setUploading(true);

        const formData = new FormData();
        existingImages.forEach((imageUrl) => {
            formData.append('image', imageUrl);
        });
        files.forEach((file) => {
            formData.append('image', file);
        });

        const mergeArrays = (existingItems, newItems, key) => {
            const existingKeys = existingItems.map(item => item[key]);
            const filteredNewItems = newItems.filter(item => !existingKeys.includes(item[key]));
            const mergedArray = [...existingItems, ...filteredNewItems];
            return mergedArray.filter(item =>
                Object.values(item).some(value => value !== "")
            );
        };
        const mergedLabourItems = mergeArrays(labourItems, labourInputFields, 'labourName');
        const mergedMaterialsItems = mergeArrays(materialsItems, materialInputFields, 'materialName');
        const mergedEquipmentItems = mergeArrays(equipmentItems, equipmentInputFields, 'equipmentName');
        const mergedHsItems = mergeArrays(hsItems, hsInputFields, 'hsQuantity');
        const arrays = [
            { array: mergedLabourItems, prefix: 'labour' },
            { array: mergedMaterialsItems, prefix: 'material' },
            { array: mergedEquipmentItems, prefix: 'equipment' },
            {array: mergedHsItems, prefix: 'healthAndSafety'},
        ];
        console.log(arrays, "arrays")
        arrays.forEach(({ array, prefix }) => {
            if (array.length > 0) {
                array.forEach((item, index) => {
                    Object.entries(item).forEach(([key, value]) => {
                        formData.append(`${prefix}[${index}][${key}]`, value);
                    });
                });
            }
        });

        formData.append('taskTagged', taskData.taskTagged);
        formData.append('weatherCondition', taskData.weatherCondition);
        formData.append('logDate', taskData.logDate);
        formData.append('projectId', taskData.projectId);
        formData.append('userId', taskData.userId);
        formData.append('workCompletedToday', completedToday );
        formData.append('workPlanForTomorrow', forTomorrow );
        formData.append('workChallenges', delays );
        formData.append('parentTaskTagged', parentTaskTagged)
        formData.append('subTaskTagged', subTaskTagged)
        formData.append('taskType', taskType)
        formData.append('description', taskData.description )

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
            }
        };

        const api_url = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS + `/${params.id}`;
        console.log(formData)
        axios.put(api_url, formData, config)
            .then(res => {
                toast.success("Log updated successfully",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
                setUploading(false);
                window.location.assign(`/daily-logs/${taskData.projectId}#3`)

            })
            .catch(error => {
                setUploading(false);
                if (error.response) {
                    console.log(error.response);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    };
    const handleHsFormChange = (key, event) => {
        const { name, value } = event.target;
        let updatedFields = [...hsInputFields];
        updatedFields[key][name] = value;
        setHsInputFields(updatedFields);
    };


    const handleHsFormSelChange = (key, event) => {

        if (showCustom){
            setShowCustom(false)
        }
        const { name, value } = event.target;
        let updatedFields = [...hsInputFields];
        updatedFields[key][name] = value;

        if (name === "hsIndicator" && value === "Custom") {
            setCustomFields({ ...customFields, [key]: true }); // Show custom field for this row
            updatedFields[key][name] = "";
        } else {
            setCustomFields({ ...customFields, [key]: false }); // Hide custom field
        }

       setHsInputFields(updatedFields);
    };
    //meta title
    document.title = "Add Log | Jenga App";

    useEffect(() => {
        getParentTasks()
        var api_url = process.env.REACT_APP_API_BASE + url.POST_MATERIAL_DETAILS + '/project/' + parsed.project;

        var api_url1 = process.env.REACT_APP_API_BASE + url.POST_EQUIPMENT_DETAILS + '/project/' + parsed.project;

        var api_url2 = process.env.REACT_APP_API_BASE + url.POST_LABOUR_DETAILS + '/project/' + parsed.project;

        var api_url3 = process.env.REACT_APP_API_BASE + url.POST_TASKS_DETAILS + '/project/' + parsed.project;
        axios.get(api_url, config)
            .then(res => {
                setMaterialsList(res.data.data);
                setMaterialButtonDisabled(false);
                setAdditionalCostButtonDisabled(false);
                setQuantitiesCostButtonDisabled(false);
            })
            .catch(error => {
                setMaterialButtonDisabled(true);
                if (error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        props.history.push('/login');
                    }
                    else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });

        axios.get(api_url1, config)
            .then(res => {
                setEquipmentList(res.data.data);
                setEquipmentButtonDisabled(false);
            })
            .catch(error => {
                setEquipmentButtonDisabled(true);
                if (error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        props.history.push('/login');
                    }
                    else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });

        axios.get(api_url2, config)
            .then(res => {
                setLabourList(res.data.data);
                setLabourButtonDisabled(false);
            })
            .catch(error => {
                setLabourButtonDisabled(true);
                if (error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        props.history.push('/login');
                    }
                    else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        const api_url_log_details = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS + '/' + params.id;

        axios.get(api_url_log_details, config)
            .then(res => {
                setLogDetail(res.data.data);
                setCompletedToday(res.data.data.workCompletedToday)
                setForTomorrow(res.data.data.workPlanForTomorrow)
                setDelays(res.data.data.workChallenges)
                setMaterialsItems(res.data.data.materialsUsed.filter(material=> material.materialName !==''));
                setLabourItems(res.data.data.labourUsed.filter(labour=> labour.labourName !==''))
                setEquipmentItems(res.data.data.equipmentUsed.filter(equipment=> equipment.equipmentName !==''))
                setHsItems(res.data.data.healthAndSafety.filter(hs=> hs.hsIndicator !==''))
                setTaskTagged(res.data.data.taskTagged)
                setParentTaskTagged(res.data.data.parentTaskTagged)
                setSubTaskTagged(res.data.data.subTaskTagged)
                setTaskType(res.data.data.taskType)
                setExistingImages(res.data.data.image)
                if (res.data.data.image && res.data.data.image.length > 0) {
                    const formattedFiles = res.data.data.image.map((url, index) => ({
                        name: `image-${index}`,
                        preview: url,
                    }));
                    setFiles(formattedFiles);
                }
               setTaskData({
                   logDate: res.data.data.logDate,
                   weatherCondition: res.data.data.weatherCondition,
                   taskTagged: res.data.data.taskTagged._id,
                   description: res.data.data.description,
                   projectId: res.data.data.projectId._id,
                   userId: res.data.data.userId._id
               })
            })
            .catch(error => {
                if (error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        props.history.push('/login');
                    } else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }, []);
    /* Material Items */
    const handleMaterialFormChange = (index, event) => {
        let data = [...materialInputFields];
        data[index][event.target.name] = event.target.value;
        setMaterialInputFields(data);
    };

    const handleMaterialFormSelChange = (index, event) => {
        let data = [...materialInputFields];
        data[index]['materialName'] = event.target.value;
        data[index]['materialUniqueId'] = event.target[event.target.selectedIndex].getAttribute('data-matunique');
        data[index]['materialPrice'] = event.target[event.target.selectedIndex].getAttribute('data-matprice');
        const newMaterialItem = {
            materialName: event.target.value,
            materialUniqueId:  event.target[event.target.selectedIndex].getAttribute('data-matunique'),
            materialPrice: event.target[event.target.selectedIndex].getAttribute('data-matprice')
        };
        data[index] = newMaterialItem;
        setMaterialInputFields(data);
        setMaterialsItems(prevItems => [...prevItems, newMaterialItem]);
    };
    const addMaterialFields = () => {
        let newfield = { materialName: '', materialUniqueId: '', materialQuantity: '', materialPrice: '' };
        setMaterialInputFields([...materialInputFields, newfield]);
    };

    const removeMaterialFields = (index) => {
        let data = [...materialsItems];
        data.splice(index, 1);
        setMaterialsItems(data);
    };
    /* Material Items */

    /* Equipment Items */
    const handleEquipmentFormChange = (index, event) => {
        let data = [...equipmentInputFields];
        data[index][event.target.name] = event.target.value;
        setEquipmentInputFields(data);
    };

    const handleEquipmentFormSelChange = (index, event) => {
        let data = [...equipmentInputFields];
        data[index]['equipmentName'] = event.target.value;
        data[index]['equipmentRatePrice'] = event.target[event.target.selectedIndex].getAttribute('data-equrateprice');
        data[index]['equipmentUniqueId'] = event.target[event.target.selectedIndex].getAttribute('data-equnique');
        const newEquipmentItem = {
            equipmentName: event.target.value,
            equipmentRatePrice: event.target[event.target.selectedIndex].getAttribute('data-equrateprice'),
        };
        data[index] = newEquipmentItem;
        setEquipmentItems(prevItems => [...prevItems, newEquipmentItem]);
        setEquipmentInputFields(data);
    };

    const addEquipmentFields = () => {
        let newfield = { equipmentName: '', equipmentNumber: '', equipmentRatePrice: '' };
        setEquipmentInputFields([...equipmentInputFields, newfield]);
    };

    const removeEquipmentFields = (index) => {
        let data = [...equipmentItems];
        data.splice(index, 1);
        setEquipmentItems(data);
    };
    /* Equipment Items */

    /* Labour Items */
    const handleLabourFormChange = (index, event) => {
        let data = [...labourInputFields];
        data[index][event.target.name] = event.target.value;
        setLabourInputFields(data);
    };

    const handleLabourFormSelChange = (index, event) => {
        let data = [...labourInputFields];
        data[index]['labourName'] = event.target.value;
        data[index]['labourRatePrice'] = event.target[event.target.selectedIndex].getAttribute('data-labrateprice');
        const newLabourItem = {
            labourName: event.target.value,
            labourRatePrice: event.target[event.target.selectedIndex].getAttribute('data-labrateprice')
        };
        data[index] = newLabourItem;
        setLabourItems(prevItems => [...prevItems, newLabourItem]);
        setLabourInputFields(data);
    };

    const addLabourFields = () => {
        let newfield = { labourName: '', labourNumber: '', labourRatePrice: '' };

        setLabourInputFields([...labourInputFields, newfield]);
    };

    const removeLabourFields = (index) => {
        let data = [...labourItems];
        data.splice(index, 1);
        setLabourItems(data);
    };
    /* Labour Items */

    /* Additional Cost Items */
    const handleAdditionalCostsFormChange = (index, event) => {
        let data = [...additionalCostsInputFields];
        data[index][event.target.name] = event.target.value;
        setAdditionalCostsInputFields(data);
    };

    const addAdditionalCostsFields = () => {
        let newfield = { additionalCostDescription: '', additionalCostAmount: '' };
        setAdditionalCostsInputFields([...additionalCostsInputFields, newfield]);
    };

    const removeAdditionalCostsFields = (index) => {
        let data = [...additionalCostsInputFields];
        data.splice(index, 1);
        setAdditionalCostsInputFields(data);
    };
    /* Subcontractor Cost Items */
    const handleSubcontractorCostsFormChange = (index, event) => {
        let data = [...subcontractorCostsInputFields];
        data[index][event.target.name] = event.target.value;
        setSubcontractorCostsInputFields(data);
    };

    const addSubcontractorCostsFields = () => {
        let newfield = { subcontractorCostDescription: '', subcontractorCostAmount: '' };
        setSubcontractorCostsInputFields([...subcontractorCostsInputFields, newfield]);
    };

    const removeSubcontractorCostsFields = (index) => {
        let data = [...subcontractorCostsInputFields];
        data.splice(index, 1);
        setSubcontractorCostsInputFields(data);
    };
    const handleQuantitiesCostsFormChange = (index, event) => {
        let data = [...quantitiesCostsInputFields];
        data[index][event.target.name] = event.target.value;
        setQuantitiesCostsInputFields(data);
    };

    const addQuantitiesCostsFields = () => {
        let newfield = { subcontractorCostDescription: '', subcontractorCostAmount: '' };
        setQuantitiesCostsInputFields([...quantitiesCostsInputFields, newfield]);
    };

    const removeQuantitiesCostsFields = (index) => {
        let data = [...quantitiesCostsInputFields];
        data.splice(index, 1);
        setQuantitiesCostsInputFields(data);
    };
    const removeHSFields = (index) => {
        let data = [...hsItems];
        data.splice(index, 1);
        setHsItems(data);
    };
    function tog_hsModal() {
        setHsModal(false)
    }
    const handleParentTaskSelect = (id) => {
        setTaskType('parenttask')
        if(id){
            setParentTaskTagged(id)
            let filteredParentTasks = parentTask.filter(parent => parent._id === id)
            setSubTask(filteredParentTasks[0].subtasks)
        } else {
            setTaskList([])
            setSubTask([])
        }
    }
    const handleSubTaskSelect = (id) => {
        setSubTaskTagged(id)
        let filteredSubTasks = subTask.filter(sub => sub._id === id)
        setTaskType('subtask')
        setTaskList(filteredSubTasks[0].subtasks)
    }
    return (
        <React.Fragment>
            <ToastContainer autoClose={3000} />
            <div className="page-content">
                <Container fluid id="basic-pills-wizard" className="twitter-bs-wizard">
                    {/* Render Breadcrumbs */}
                    <i className="fas fa-arrow-circle-left" style={{ fontSize: 20, color: 'blue', cursor: 'pointer', marginBottom: 10 }} onClick={() => props.history.goBack()}></i>

                    <Row>
                        <Col xl={8}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Edit Log Details for {parsed?.projectname}</h4>
                                    <p className="card-title-desc">Provide all the required details about the progress of this project.</p>
                                </CardHeader>
                                <CardBody>
                                    <Form className="needs-validation"
                                          onSubmit={submitForm}
                                    >

                                        <Row>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="example-datetime-local-input" className="form-Label">Log Date</Label>
                                                    <Input
                                                        name="logDate"
                                                        className="form-control"
                                                        type="date"
                                                        id="example-datetime-local-input"
                                                        required={true}
                                                        defaultValue={logDetail.logDate ? new Date(logDetail.logDate).toISOString().substr(0,10) : ""}
                                                        onChange={(e) => setTaskData({ ...taskData, logDate: e.target.value })}
                                                    />

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                      {/*  <Row>
                                            <Col md="12" className="mb-3">
                                                <Label className="form-label">Weather Conditions</Label>
                                                <select
                                                    className="form-select"
                                                    name="weatherCondition"
                                                    value={logDetail.weatherCondition}
                                                    onChange={(e) => setTaskData({ ...taskData, weatherCondition: e.target.value })}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Cloudy">Cloudy</option>
                                                    <option value="Rainy">Rainy</option>
                                                    <option value="Sunny">Sunny</option>
                                                </select>
                                            </Col>
                                        </Row>*/}
                                        <Row>
                                            <Col className={"mt-3"}  xl={4} md={4}>
                                                <Label className="form-label">Parent Task</Label>
                                                <select
                                                    className="form-select"

                                                    onChange={(e) => handleParentTaskSelect(e.target.value)}
                                                >
                                                    <option value="">Select</option>
                                                    {map(parentTask, (taskItem, index) => (
                                                        <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                                    ))}
                                                </select>

                                            </Col>
                                            {
                                                subTask.length > 0 && (
                                                    <Col className={"mt-3"}  xl={4} md={4} >
                                                        <Label className="form-label">Sub Task</Label>
                                                        <select
                                                            className="form-select"
                                                            name="subtaskTagged"
                                                            onChange={(e) => handleSubTaskSelect(e.target.value)}

                                                        >
                                                            <option value="">Select</option>
                                                            {map(subTask, (taskItem, index) => (
                                                                <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                )
                                            }

                                            {
                                                taskList.length > 0 && (
                                                    <Col className={"mt-3"}  xl={4} md={4}>
                                                        <Label className="form-label">Task</Label>
                                                        <select
                                                            className="form-select"
                                                            name="taskTagged"
                                                            onChange={(e) => {
                                                                setTaskData({ ...taskData, taskTagged: e.target.value })
                                                                setTaskType('task')
                                                                setTaskTagged(e.target.value)
                                                            } }
                                                        >
                                                            <option value="">Select</option>
                                                            {map(taskList, (taskItem, index) => (
                                                                <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                                            ))}
                                                        </select>

                                                    </Col>
                                                )
                                            }
                                        </Row>

                                        <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                            <h5 className="title">Work Progress</h5>
                                            <label className={"fw-lighter"}>Completed Today</label>
                                            <textarea value={completedToday}    onChange={(e)=>setCompletedToday(e.target.value)} className={"form-control"} rows={2} placeholder={"Add notes"}></textarea>

                                            <label className={"fw-lighter  mt-3"}>Planned for Tomorrow</label>
                                            <textarea value={forTomorrow} onChange={(e)=>setForTomorrow(e.target.value)} className={"form-control"} rows={2} placeholder={"Add notes"}></textarea>

                                            <label className={"fw-lighter mt-3"}>Delays and Challenges</label>
                                            <textarea value={delays}  onChange={(e)=>setDelays(e.target.value)} className={"form-control"} rows={2} placeholder={"Add notes"}></textarea>
                                        </div>
                                        <Row>
                                            <Col md="12">
                                                <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                                    <h5 className="title">Log Items</h5>
                                                    <div className={"container-fluid d-flex justify-content-between align-items-center"}>
                                                        <h6>Materials</h6>
                                                        <Button
                                                            type="button"
                                                            className={"submit-button py-2"}
                                                            onClick={() => {
                                                                setMaterialsModal(true);
                                                            }}>
                                                           + Add Materials
                                                        </Button>
                                                    </div>
                                                    <table className={"table task-progress mt-3 border"}>
                                                        <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Quantity</th>
                                                            <th className={"text-end"}>Actions</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {map(
                                                            materialsItems,
                                                            (item, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <h5 className="font-size-15 mb-1">{item.materialName}</h5>
                                                                        <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p>
                                                                    </td>
                                                                    <td>{item.materialQuantity}</td>
                                                                    <td className={"text-end"}>
                                                                        {/* <button className={"btn cancel-button p-2 me-3"}>Edit</button>*/}
                                                                        <img onClick={() => removeMaterialFields(key)} src={"/Trash.png"} style={{height:"20px", width:"20px"}}/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                        </tbody>
                                                    </table>
                                                    <div className={"container-fluid d-flex justify-content-between align-items-center"}>
                                                        <h6>Equipment</h6>
                                                        <Button
                                                            type="button"
                                                            className={"submit-button py-2"}
                                                            onClick={() => {
                                                                setEquipmentModal(true);
                                                            }}>
                                                           + Add Equipment
                                                        </Button>
                                                    </div>
                                                    <table className={"table task-progress mt-3 border"}>
                                                        <thead>
                                                        <tr>
                                                            <th>Item</th>
                                                            <th>Quantity</th>
                                                            <th className={"text-end"}>Actions</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {map(
                                                            equipmentItems,
                                                            (item, key) => (
                                                                <tr key={key}>

                                                                    <td>
                                                                        <h5 className="font-size-15 mb-1">{item.equipmentName}</h5>
                                                                        {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                                                    </td>

                                                                    <td>{item.equipmentNumber}</td>
                                                                    <td className={"text-end"}>
                                                                        {/* <button className={"btn cancel-button p-2 me-3"}>Edit</button>*/}
                                                                        <img onClick={() => removeEquipmentFields(key)} src={"/Trash.png"} style={{height:"20px", width:"20px"}}/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                        </tbody>
                                                    </table>
                                                    <div className={"container-fluid d-flex justify-content-between align-items-center"}>
                                                        <h6>Labour</h6>
                                                        <Button
                                                            type="button"
                                                            className={"submit-button py-2"}
                                                            onClick={() => {
                                                                setLabourModal(true);
                                                            }}>
                                                            + Add Labour
                                                        </Button>
                                                    </div>
                                                    <table className={"table task-progress mt-3 border"}>
                                                        <thead>
                                                        <tr>
                                                            <th>Role</th>
                                                            <th>Quantity</th>
                                                            <th className={"text-end"}>Actions</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {map(
                                                            labourItems,
                                                            (item, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <h5 className="font-size-15 mb-1">{item.labourName}</h5>
                                                                        {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                                                    </td>
                                                                    <td>{item.labourNumber}</td>
                                                                    <td className={"text-end"}>
                                                                        {/* <button className={"btn cancel-button p-2 me-3"}>Edit</button>*/}
                                                                        <img onClick={() => removeLabourFields(key)} src={"/Trash.png"} style={{height:"20px", width:"20px"}}/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                        </tbody>
                                                    </table>
                                                    <div className={"container-fluid d-flex justify-content-between align-items-center"}>
                                                        <h6>Health and Safety</h6>
                                                        <Button
                                                            type="button"
                                                           className={"submit-button py-2"}
                                                            onClick={() => {
                                                                setHsModal(true);
                                                            }}>
                                                           + Add H&S Items
                                                        </Button>
                                                    </div>
                                                    <table className={"table task-progress mt-3 border"}>
                                                        <thead>
                                                        <tr>
                                                            <th >Indicator Type</th>
                                                            <th>Indicator</th>
                                                            <th >Quantity</th>
                                                            <th >Comment</th>
                                                            <th className={"text-end"}>Actions</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {map(
                                                            hsItems,
                                                            (item, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        {item.hsIndicatorType}
                                                                        {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                                                    </td>
                                                                    <td >{item.hsIndicator}</td>
                                                                    <td >{item.hsQuantity}</td>
                                                                    <td>{item.hsComment}</td>
                                                                    <td className={"text-end"}>
                                                                        {/*  <button className={"btn cancel-button p-2 me-3"}>Edit</button>*/}
                                                                        <img onClick={() => removeHSFields(key)} src={"/Trash.png"} style={{height:"20px", width:"20px"}}/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12 my-3">
                                                <Label htmlFor="basicpill-address-input" className="form-Label">Upload Photos</Label>
                                                <div {...getRootProps()} className={`dropzone  ${isDragActive ? 'active text-center' : 'text-center'}`}>
                                                    <input {...getInputProps({accept: 'image/jpeg, image/png'})} />
                                                    {isDragActive ? (
                                                        <p>  <FeatherIcon
                                                            icon="plus-circle"
                                                        /> Drop the files here ...</p>
                                                    ) : (
                                                        <>

                                                            <p> <FeatherIcon
                                                                icon="plus-circle"
                                                            />  <br/>   Drag 'n' drop images here, or click to select files</p>
                                                        </>
                                                    )}
                                                </div>
                                                <aside style={thumbsContainer}>
                                                    {thumbs}
                                                </aside>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="basicpill-address-input" className="form-Label">Extra Notes</Label>

                                                    <Input
                                                        name="description"
                                                        className="form-control"
                                                        type="textarea"
                                                        id="basicpill-address-input"
                                                        onChange={(e) => setTaskData({ ...taskData, description: e.target.value })}
                                                        defaultValue={logDetail.description}  />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {projectError && projectError ? (
                                            <Alert color="danger">{projectError}</Alert>
                                        ) : null}


                                        <Button color="dark" type="submit">
                                            {
                                                uploading ? <>
                                                    <span>Submitting...</span>  <SubmitIndicator />
                                                </> : <>
                                                    Submit
                                                </>
                                            }
                                        </Button>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={materialsModal}
                toggle={() => {
                    tog_materialsModal();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Materials
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setMaterialsModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {map(materialInputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="6" xs="6">
                                <div className="mb-3">
                                    <label>Material</label>
                                    <select
                                        className="form-select"
                                        name="materialName"
                                        onChange={(e) => handleMaterialFormSelChange(key, e)}
                                        value={item.materialName}>
                                        <option value="">Select Item</option>
                                        {map(materialsList, (materialsItem, index) => (
                                            <option value={materialsItem.materialName} key={index} data-matunique={materialsItem.matUniqueIdentifier} data-matprice={materialsItem.price}>{materialsItem.materialName}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                            <Col md="5" xs="5">
                                <FormGroup className="mb-3">
                                    <label>Quantity</label>
                                    <Input
                                        name="materialQuantity"
                                        className="form-control"
                                        placeholder="Enter quantity"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleMaterialFormChange(key, e)}
                                        value={item.materialQuantity} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1"  className={"d-flex align-items-center"} style={{ paddingLeft: 0 }}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeMaterialFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn add-new"
                                onClick={addMaterialFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add material item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_materialsModal();
                        }}
                        className="btn cancel-button"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn submit-button" onClick={() => {
                        setMaterialsModal(false);
                        setMaterialStuff(true);
                    }}>
                        Add
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={equipmentModal}
                toggle={() => {
                    tog_equipmentModal();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Equipment
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setEquipmentModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {map(equipmentInputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="7" xs="7">
                                <div className="mb-3">
                                    <label>Equipment</label>
                                    <select
                                        className="form-select"
                                        name="equipmentName"
                                        onChange={(e) => handleEquipmentFormSelChange(key, e)}
                                        value={item.equipmentName}>
                                        <option value="">Select Equipment</option>
                                        {map(equipmentList, (equipmentItem, index) => (
                                            <option value={equipmentItem.equipmentName} key={index} data-equrateprice={equipmentItem.ratePrice}>{equipmentItem.equipmentName}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                            <Col md="4" xs="4">
                                <FormGroup className="mb-3">
                                    <label>Quantity</label>
                                    <Input
                                        name="equipmentNumber"
                                        className="form-control"
                                        placeholder="Enter quantity"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleEquipmentFormChange(key, e)}
                                        value={item.equipmentNumber} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1" className={"d-flex align-items-center"}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeEquipmentFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn add-new"
                                onClick={addEquipmentFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add equipment item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_equipmentModal();
                        }}
                        className="btn cancel-button "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn submit-button" onClick={() => {
                        setEquipmentModal(false);
                        setEquipmentStuff(true);
                    }}>
                        Add
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={labourModal}
                toggle={() => {
                    tog_labourModal();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Labour
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setLabourModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {map(labourInputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="6" xs="6">
                                <div className="mb-3">
                                    <label>Labour</label>
                                    <select
                                        className="form-select"
                                        name="labourName"
                                        onChange={(e) => handleLabourFormSelChange(key, e)}
                                        value={item.labourName}>
                                        <option value="">Select Role</option>
                                        {map(labourList, (labourItem, index) => (
                                            <option value={labourItem.labourName} key={index} data-labrateprice={labourItem.rate}>{labourItem.labourName}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                            <Col md="5" xs="5">
                                <FormGroup className="mb-3">
                                    <label>Quantity</label>
                                    <Input
                                        name="labourNumber"
                                        className="form-control"
                                        placeholder="Enter number"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleLabourFormChange(key, e)}
                                        value={item.labourNumber} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1" className={"d-flex align-items-center"}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeLabourFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn add-new"
                                onClick={addLabourFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add labour item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_labourModal();
                        }}
                        className="btn cancel-button "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn submit-button" onClick={() => {
                        setLabourModal(false);
                        setLabourStuff(true);
                    }}>
                        Add
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={additionalCostModal}
                toggle={() => {
                    tog_additionalCostsModal();
                }}
            >
                {/* <Form onSubmit={e => addMaterial(e)}> */}
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Additional Costs
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setAdditionalCostModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ height: 400, overflowY: 'scroll' }}>
                    {map(additionalCostsInputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="7" xs="7">
                                <FormGroup className="mb-3">
                                    <Input
                                        name="additionalCostDescription"
                                        className="form-control"
                                        placeholder="Enter item"
                                        type="text"
                                        onChange={(e) => handleAdditionalCostsFormChange(key, e)}
                                        value={item.additionalCostDescription} />
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="4">
                                <FormGroup className="mb-3">
                                    <Input
                                        name="additionalCostAmount"
                                        className="form-control"
                                        placeholder="Enter amount"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleAdditionalCostsFormChange(key, e)}
                                        value={item.additionalCostAmount} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1" style={{ paddingLeft: 0 }}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeAdditionalCostsFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary waves-effect btn-label waves-light"
                                onClick={addAdditionalCostsFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add new item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_additionalCostsModal();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                        setAdditionalCostModal(false);
                        setAdditionalCostsCostsStuff(true);
                    }}>
                        Add
                    </button>
                </div>
                {/* </Form> */}
            </Modal>
            <Modal
                isOpen={hsModal}
                toggle={() => {
                    tog_hsModal();
                }}
            >
                {/* <Form onSubmit={e => addMaterial(e)}> */}
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        H&S Log   <div className="tooltip1"> <FeatherIcon icon={"info"} />
                        <span className="tooltiptext">
                           Indicator Types <br/>
                            <b>Leading indicators</b> are proactive measures you took to prevent incidents eg. Safety Training
                            <br />
                           <b> Lagging indicators</b> are reactive measures of past safety performance eg. injury rates
                        </span>
                    </div>
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setHsModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ height: 400, overflowY: 'scroll' }}>
                    {map(hsInputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="12" xs="12">
                                <div className="mb-3">
                                    <label>Indicator Type </label>
                                    <select
                                        className="form-select"
                                        required
                                        name="hsIndicatorType"
                                        onChange={(e) => handleHsFormSelChange(key, e)}
                                        value={item.hsIndicatorType}>
                                        <option value="">Select </option>
                                        <option value="Lagging Indicator">Lagging Indicator</option>
                                        <option value="Leading Indicator">Leading Indicator</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md="7" xs="7">
                                {showCustom && customId===key ? <>
                                    <div className="mb-3">
                                        <label>Indicator {hsInputFields[key].hsIndicatorType}</label>
                                        <select
                                            className="form-select"
                                            required
                                            name="hsIndicator"
                                            onChange={(e) => handleHsFormSelChange(key, e)}
                                            value={'Custom'}>
                                            <option value="">Select </option>
                                            {
                                                hsInputFields[key].hsIndicatorType === 'Lagging Indicator' ? <>
                                                    {laggingIndicators.map((indicator, index) => (
                                                        <option key={index} value={indicator}>
                                                            {indicator}
                                                        </option>
                                                    ))}
                                                </> : <>
                                                    {leadingIndicators.map((indicator, index) => (
                                                        <option key={index} value={indicator}>
                                                            {indicator}
                                                        </option>
                                                    ))}
                                                </>
                                            }

                                            <option onClick={()=>handleShowCustom(key)} value="Custom">Custom</option>
                                        </select>
                                    </div>

                                </> : <>

                                    <div className="mb-3">
                                        <label>Indicator</label>
                                        <select
                                            className="form-select"
                                            required
                                            name="hsIndicator"
                                            onChange={(e) => handleHsFormSelChange(key, e)}
                                            value={item.hsIndicator}>
                                            <option value="">Select </option>
                                            {
                                                hsInputFields[key].hsIndicatorType==='Lagging Indicator' ? <>
                                                    {laggingIndicators.map((indicator, index) => (
                                                        <option key={index} value={indicator}>
                                                            {indicator}
                                                        </option>
                                                    ))}
                                                </> : <>
                                                    {leadingIndicators.map((indicator, index) => (
                                                        <option key={index} value={indicator}>
                                                            {indicator}
                                                        </option>
                                                    ))}
                                                </>
                                            }

                                            <option onClick={()=>handleShowCustom(key)} value="Custom">Custom</option>
                                        </select>
                                    </div>
                                </>}

                            </Col>
                            <Col md="5" xs="5">
                                <FormGroup className="mb-3">
                                    <label>Quantity</label>
                                    <Input
                                        name="hsQuantity"
                                        required
                                        className="form-control"
                                        placeholder="Enter number"
                                        type="number"
                                        min={0}
                                        onChange={(e) => handleHsFormChange(key, e)}
                                        value={item.hsQuantity}
                                    />
                                </FormGroup>
                            </Col>

                            {customFields[key] && (
                                <Col md="12" xs="12">
                                    <FormGroup className="mb-3">
                                        <label>Custom Indicator Name</label>
                                        <Input
                                            name="hsIndicator"
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => handleHsFormChange(key, e)}
                                            value={item.hsIndicator}
                                        />
                                    </FormGroup>
                                </Col>
                            )}

                            <Col md="12" xs="12">
                                <FormGroup className="mb-3">
                                    <label>Comment</label>
                                    <Input
                                        name="hsComment"
                                        className="form-control"
                                        placeholder="Enter Comment"
                                        type="text"
                                        onChange={(e) => handleHsFormChange(key, e)}
                                        value={item.hsComment}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    ))}
                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn add-new w-100"
                                onClick={addHsFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add indicator
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_hsModal();
                        }}
                        className="btn cancel-button "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn submit-button" onClick={() => {
                        setHsModal(false);
                        setHsStuff(true);
                    }}>
                        Add
                    </button>
                </div>
                {/* </Form> */}
            </Modal>
            <Modal
                isOpen={subcontractorCostModal}
                toggle={() => {
                    tog_subcontractorCostsModal();
                }}
            >
                {/* <Form onSubmit={e => addMaterial(e)}> */}
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Subcontractor Costs
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setSubcontractorCostModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ height: 400, overflowY: 'scroll' }}>
                    {map(subcontractorCostsInputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="7" xs="7">
                                <FormGroup className="mb-3">
                                    <Input
                                        name="subContractorName"
                                        className="form-control"
                                        placeholder="Enter Subcontractor Name"
                                        type="text"
                                        onChange={(e) => handleSubcontractorCostsFormChange(key, e)}
                                        value={item.subContractorName} />
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="4">
                                <FormGroup className="mb-3">
                                    <Input
                                        name="subContractorCostAmount"
                                        className="form-control"
                                        placeholder="Enter amount"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleSubcontractorCostsFormChange(key, e)}
                                        value={item.subContractorCostAmount} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1" style={{ paddingLeft: 0 }}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeSubcontractorCostsFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary waves-effect btn-label waves-light"
                                onClick={addSubcontractorCostsFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add new item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_subcontractorCostsModal();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                        setSubcontractorCostModal(false);
                        setSubcontractorCostsStuff(true);
                    }}>
                        Add
                    </button>
                </div>
                {/* </Form> */}
            </Modal>
            
            
            <Modal
                isOpen={quantitiesCostModal}
                toggle={() => {
                  tog_quantitiesCostsModal();
                }}
                size={"lg"}
            >
                {/* <Form onSubmit={e => addMaterial(e)}> */}
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Quantities Costs
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setQuantitiesCostModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ height: 400, overflowY: 'scroll' }}>
                    {map(quantitiesCostsInputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="5" xs="5">
                                <FormGroup className="mb-3">
                                    <Input
                                        name="quantitiesName"
                                        className="form-control"
                                        placeholder="Enter Quantity Name"
                                        type="text"
                                        onChange={(e) => handleQuantitiesCostsFormChange(key, e)}
                                        value={item.quantitiesName} />
                                </FormGroup>
                            </Col>
                            <Col md="2" xs="2">
                                <FormGroup className="mb-3">
                                    <Input
                                        name="quantitiesUnit"
                                        className="form-control"
                                        placeholder="Unit"
                                        type="text"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleQuantitiesCostsFormChange(key, e)}
                                        value={item.quantitiesUnit} />
                                </FormGroup>
                            </Col>
                            <Col md="2" xs="2">
                                <FormGroup className="mb-3">
                                    <Input
                                        name="quantitiesQty"
                                        className="form-control"
                                        placeholder="Qty"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleQuantitiesCostsFormChange(key, e)}
                                        value={item.quantitiesQty} />
                                </FormGroup>
                            </Col>
                            <Col md="2" xs="2">
                                <FormGroup className="mb-3">
                                    <Input
                                        name="quantitiesRate"
                                        className="form-control"
                                        placeholder="Rate"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleQuantitiesCostsFormChange(key, e)}
                                        value={item.quantitiesRate} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1" style={{ paddingLeft: 0 }}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeQuantitiesCostsFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary waves-effect btn-label waves-light"
                                onClick={addQuantitiesCostsFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add new item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                          tog_quantitiesCostsModal();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                        setQuantitiesCostModal(false);
                        setQuantitiesCostsStuff(true);
                    }}>
                        Add
                    </button>
                </div>
                {/* </Form> */}
            </Modal>
        </React.Fragment>
    );
}

export default EditLog;