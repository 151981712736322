import React, {useEffect, useState} from "react"
import {Alert, Col, Form, Input, Label, Modal, Row} from "reactstrap";
import * as url from "../../../../helpers/jengasmart_api_urls";
import {useParams} from "react-router-dom";
import axios from "axios";
import CollaboratorsPermissionList from "./ProjectSetting/CollaboratorsPermissionList";
import FinancialsPermissions from "./ProjectSetting/FinancialsPermissions";
import DocumentsPermissionsList from "./ProjectSetting/DocumentsPermissionsList";
import InventoryPermissionsList from "./ProjectSetting/InventoryPermissionsList";
import InventoryList from "./InventoryList";
import DocumentsList from "./DocumentsList";
import PermissionsList from "./ProjectSetting/PermissionList";
import ProjectScheduler from "./ProjectSetting/ProjectScheduler";
import DaillyLogsPermissions from "./ProjectSetting/DaillyLogsPermissions";
import {toast, ToastContainer} from "react-toastify";
export default function AddUserRoles({addRole, onClose, getRoles}){
    const {id}= useParams()
    const [loading, setLoading] = useState(true);
    const api_url = process.env.REACT_APP_API_BASE + 'permissions';
    const topNavigation = [
        {
            title:'Projects',
            key:'1'
        },
       /* {
            title:'Inventory',
            key:'2'
        },*/
       /* {
            title:'Documents',
            key:'3'
        },*/
        {
            title:'Members',
            key:'4'
        },
        {
            title:'Reports',
            key:'5'
        },
    ]
    const sideNavigation = [
        /* {
              title:"Project List",
              key:"1"
          },
          {
              title:"Project Details",
              key:"2"
          },*/
        {
            title:"Overview",
            key:"3"
        },
        //parent tasks, sub tasks, tasks,  milestones, boq,
        {
            title:"Project Scheduler",
            key:"4"
        },
        {
            title:"Daily Logs",
            key:"5"
        },
        {
            title:"Collaborators",
            key:"6"
        },
        {
            title:"Financials",
            key:"7"
        },
        {
            title:"Inventory",
            key:"8"
        },
        {
            title:"Documents",
            key:"9"
        },
        {
            title:"Reports",
            key:"9"
        }
    ]
    const [activeSidemenu, setActiveSideMenu]=useState("3")
    const [activeTopNav, setActiveTopNav] = useState("1")
    const [selectedOptions, setSelectedOptions] = useState([]);
    const[schedulerPermissions, setSchedulerPermissions]=useState([])
    const[dailyLogsPermissions, setDailyLogsPermissions]=useState([])
    const [materialsPermissions, setMaterialsPermissions] = useState([])
    const [labourPermissions, setLabourPermissions] = useState([])
    const [equipmentPermissions, setEquipmentPermissions] = useState([])
    const [projectPermissions, setProjectPermissions] = useState([])
    const [permissionsData, setPermissionsData] = useState({
        name: '',
        description: '',
        projectId: id,
        permissions: []
    });
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPermissionsData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const api_url = `${process.env.REACT_APP_API_BASE}roles`;
            const { name, description, projectId, permissions } = permissionsData;
            const payload = { name, description, projectId, permissions };
            const response = await axios.post(api_url, payload, config);
            if (response.data.success){
                toast.success("Role added successfully",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
                setPermissionsData({
                    name: '',
                    description: '',
                    projectId: id,
                    permissions: []
                })
                setSelectedOptions([])
                getRoles()
            } else {
                toast.error("Failed! Try again",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
            }


        } catch (error) {
            toast.error("Failed! Try again",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        }
    };

    useEffect(() => {
        const order = ["Tasks", "Sub Tasks", "Boq", "Milestones", "Boq", "Order"];
        const fetchPermissions = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE}permissions`, config);
                setProjectPermissions(response.data.data.filter((perm)=>(perm.resourceModel==='Project')))
                const filteredAndOrderedPermissions = response.data.data
                    .filter((perm) => order.includes(perm.resourceModel)) // Filter based on the resourceModel
                    .sort((a, b) => order.indexOf(a.resourceModel) - order.indexOf(b.resourceModel)); // Sort based on the order array
                setSchedulerPermissions(filteredAndOrderedPermissions);
                setDailyLogsPermissions(response.data.data.filter((perm)=>(perm.resourceModel==='DailyLogs')))
                setEquipmentPermissions(response.data.data.filter((perm)=>(perm.resourceModel==='Equipment')))
                setMaterialsPermissions(response.data.data.filter((perm)=>(perm.resourceModel==='Material')))
                setLabourPermissions(response.data.data.filter((perm)=>(perm.resourceModel==='Labour')))
            } catch (error) {
                if (error.response.status=403){
                    window.location.assign('/login')
                }
                console.error("Error fetching permissions:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchPermissions();
    }, [activeSidemenu]);
    const handleCheckboxChange = (event) => {

        const { value } = event.target;
        console.log(value)
        setSelectedOptions((prevSelected) => {
            const updatedSelected = prevSelected.includes(value)
                ? prevSelected.filter((item) => item !== value)
                : [...prevSelected, value];
            setPermissionsData(prevData => ({
                ...prevData,
                permissions: updatedSelected
            }));
            return updatedSelected;
        });
    };

    const handleClose = () =>{
        onClose()
    }

    return (
        <>
            <ToastContainer autoClose={3000} />
            <div className={"adduser-role"}>
                <Modal
                    className={"adduser-role-modal"}
                    centered
                    backdrop="static"
                    isOpen={addRole}
                    fullscreen={true}
                    dialogClassName="slide-in-right"
                >
                    <div className="modal-header">
                        <h5 className="card-title mt-0" id="myModalLabel">
                            User Role Settings
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <Row>
                                <Col xl={6} md={6} xs={12}>
                                    <Label>Role Name</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Financial Analyst"
                                        id="project-name"
                                        name="name"
                                        onChange={handleInputChange}
                                        value={permissionsData.name}
                                        required />
                                </Col>
                                <Col xl={6} md={6} xs={12}>
                                    <Label>Description</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Description"
                                        id="project-description"
                                        required
                                        name="description"
                                        onChange={handleInputChange}
                                        value={permissionsData.description}
                                    />
                                </Col>
                            </Row>
                            <div className={"container-fluid mt-3 p-3"} style={{background:"#F7F7F7"}}>
                                <h5 className={"title"}>Configure Permissions</h5>
                                <div className={"container-fluid config-top-menu mb-2"}>
                                    <ul className={""}>
                                        {topNavigation.map((nav, index)=>(
                                            <li key={index} onClick={()=>setActiveTopNav(nav.key)} className={activeTopNav===nav.key ? "active-config-menu-item":"config-menu-item"} >{nav.title}</li>
                                        ))}
                                    </ul>
                                </div>
                                <Row>
                                    <Col xl={4} md={4}>
                                        <div className={"config-left-menu mt-3"}>
                                            {
                                                sideNavigation.map((menu, index)=>(
                                                    <div key={index} onClick={()=>setActiveSideMenu(menu.key)} className={activeSidemenu===menu.key ? "active-config-menu-item":"config-menu-item"}>
                                                        {menu.title}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Col>
                                    <Col xl={8} md={8}>
                                        <div className={"container bg-white px-3 py-2"}>
                                            {
                                                activeTopNav ==="1" ? <>
                                                    {activeSidemenu === "3" &&
                                                        <>
                                                            {loading ? (
                                                                <p>Loading overview permissions...</p>
                                                            ) : (
                                                                projectPermissions.length > 0 ? (
                                                                    projectPermissions.map((item, index) => (
                                                                        <div className="form-check mt-3" key={`${index}`}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`scheduler-check-${index}`}
                                                                                value={item._id}
                                                                                onClick={handleCheckboxChange}
                                                                                checked={selectedOptions.includes(item._id)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                            >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <p>No permissions found.</p>
                                                                )
                                                            )}
                                                        </>
                                                    }
                                                    {activeSidemenu === "4" &&
                                                        <>
                                                            {loading ? (
                                                                <p>Loading Scheduler permissions...</p>
                                                            ) : (
                                                                schedulerPermissions.length > 0 ? (
                                                                    schedulerPermissions.map((item, index) => (
                                                                        <div className="form-check mt-3" key={`${index}`}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`scheduler-check-${index}`}
                                                                                value={item._id}
                                                                                onClick={handleCheckboxChange}
                                                                                checked={selectedOptions.includes(item._id)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                            >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <p>No permissions found.</p>
                                                                )
                                                            )}
                                                        </>
                                                    }
                                                    {activeSidemenu === "5" &&
                                                        <>
                                                            {loading ? (
                                                                <p>Loading daily logs permissions...</p>
                                                            ) : (
                                                                dailyLogsPermissions.length > 0 ? (
                                                                    dailyLogsPermissions.map((item, index) => (
                                                                        <div className="form-check mt-3" key={`${index}`}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`scheduler-check-${index}`}
                                                                                value={item._id}
                                                                                onClick={handleCheckboxChange}
                                                                                checked={selectedOptions.includes(item._id)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                            >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <p>No permissions found.</p>
                                                                )
                                                            )}
                                                        </>
                                                    }
                                                    {activeSidemenu === "6" && <CollaboratorsPermissionList />}
                                                    {activeSidemenu === "7" && <FinancialsPermissions/>}
                                                    {activeSidemenu === "8" &&
                                                        <>
                                                            {loading ? (
                                                                <p>Loading inventory permissions...</p>
                                                            ) : (
                                                                ['materialsPermissions', 'equipmentPermissions', 'labourPermissions'].map((permissionType, typeIndex) => {
                                                                    const permissions = eval(permissionType);
                                                                    return permissions && permissions.length > 0 ? (
                                                                        permissions.map((item, index) => (
                                                                            <div className="form-check mt-3" key={`${permissionType}-${index}`}>
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    id={`scheduler-check-${permissionType}-${index}`}
                                                                                    value={item._id}
                                                                                    onClick={handleCheckboxChange}
                                                                                    checked={selectedOptions.includes(item._id)}
                                                                                />
                                                                                <label className="form-check-label">
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <p key={`no-permissions-${typeIndex}`}>No permissions found for {permissionType.replace('Permissions', '')}.</p>
                                                                    );
                                                                })
                                                            )}
                                                        </>
                                                    }
                                                    {activeSidemenu === "9" && <DocumentsPermissionsList />}
                                                </> :  activeTopNav ==="2" ?  <>


                                                </> : <>
                                                    <DocumentsList />
                                                </>
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary "
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button className="btn btn-primary">
                                Create Role
                            </button>
                        </div>
                    </form>

                </Modal>
            </div>
        </>
    )
}