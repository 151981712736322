import React, {useEffect, useState} from "react"
import {Col, Input, Label, Row} from "reactstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
export default function ProjectInformation({project}){
    const [loading, setLoading] = useState(true)
    const {id} = useParams()
    const api_url = process.env.REACT_APP_API_BASE + 'projects/' + id;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const [formData, setFormData] = useState({
        projectName: "",
        _id: "",
        description: "",
        startDate: "",
        endDate: "",
    });
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            projectName: formData.projectName,
            projectId: formData._id,
            description: formData.description,
            startDate: formData.startDate,
            endDate: formData.endDate,
        };
        try {
            await axios.put(api_url, payload, config)
            toast.success("Project stakeholders updated successfully",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        } catch (e) {
            toast.error("An error occurred! Please try again later",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        }
    };
    useEffect(() => {
        if (project) {
            setFormData({
                projectName: project.projectName || "",
                _id: project._id || "",
                description: project.description || "",
                startDate: project.startDate ? project.startDate.split("T")[0] : "",
                endDate: project.endDate ? project.endDate.split("T")[0] : "",
            });
        }
    }, [project]);

    return (
        <>
            <h4 className="card-title fw-bold">Project Information</h4>
            <p className={"card-title-desc"}>
               Project Details
            </p>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col xl={6} md={6}>
                        <div className="mb-3 mt-3">
                            <Label>Project Name</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="projectName"
                                value={formData.projectName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <Label>Project ID</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="_id"
                                readOnly
                                value={formData._id}
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <Label>Description</Label>
                            <textarea
                                className="form-control"
                                rows={4}
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>

                        <h6>Project Images</h6>

                        <h5 className="card-title fw-bold mt-5">Project Scope</h5>

                        <div className="mb-3">
                            <Label>Start Date</Label>
                            <Input
                                className="form-control"
                                type="date"
                                name="startDate"
                                value={formData.startDate}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <Label>End Date</Label>
                            <Input
                                className="form-control"
                                type="date"
                                name="endDate"
                                value={formData.endDate}
                                readOnly
                                required
                            />
                        </div>

                        <button type="submit" className="btn w-100 text-white" style={{ backgroundColor: "#194973" }}>
                            Save
                        </button>
                    </Col>
                </Row>
            </form>
        </>
    )
}