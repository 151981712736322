import React, {useEffect, useState} from "react"
import axios from "axios";
import * as url from "../../../helpers/jengasmart_api_urls";
import {useParams} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
export default function ProgressReport(){
    const[tasksList, setTasksList]=useState([])
    const [openTask, setOpenTask] = useState(null);
    const [openSubTask, setOpenSubTask] = useState(null);
    const toggleTask = (taskId) => {
        setOpenTask(openTask === taskId ? null : taskId);
    };
    const toggleSubTask = (subTaskId) => {
        setOpenSubTask(openSubTask === subTaskId ? null : subTaskId); // Toggle the open task
    };
    const {id}=useParams()
    var progress_url =  process.env.REACT_APP_API_BASE + `parenttasks/project/report/${id}/date-range?startDate=${localStorage.getItem('startDate')}&endDate=${localStorage.getItem('endDate')}`;
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getProgressReport  = async ()=>{
        try {
            const response  =  await axios.get(progress_url, config)
            console.log(response)
            setTasksList(response.data.data)
        } catch (e){
            console.log(e)
        } finally {

        }


    }
    useEffect(()=>{
        getProgressReport()
    }, [])
    return (
        <>
            <table className={"table task-progress mt-3 border"}>
                <thead>
                <tr>
                    <th>Task</th>
                    <th>Start Date</th>
                    <th>Duration</th>
                    <th>Assigned to</th>
                    <th>Status</th>
                    <th>Progress (%)</th>
                </tr>
                </thead>
                <tbody>
                {tasksList.length > 0 ? (
                    tasksList.map((task) => (
                        <React.Fragment key={task.taskUniqueIdentifier}>
                            <tr onClick={() => toggleTask(task.taskUniqueIdentifier)}>
                                <td> {openTask === task.taskUniqueIdentifier ? <>
                                    <FeatherIcon
                                        icon="chevron-right"
                                        className={"table-icon"}
                                    />
                                </> : <>
                                    <FeatherIcon
                                        icon="chevron-down"
                                        className={"table-icon"}
                                    />
                                </>}{task.taskName}</td>
                                <td>{new Date(task.startDate).toLocaleDateString()}</td>
                                <td>{Math.floor((new Date(task.endDate) - new Date(task.startDate)) / (1000 * 60 * 60 * 24))} Days</td>
                                <td>{task.assignee}</td>
                                <td><span className={task.status==="Not Started" ? "badge status-badge": task.status==="Completed" ? "badge complete-status-badge" :  "badge progress-status-badge"}>{task.status}</span></td>
                                <td>{task.percentageProgress} %</td>
                            </tr>
                            {task.subtasks && task.subtasks.length > 0 && (
                                task.subtasks.map((subtask, index) => (
                                    <>
                                        <tr onClick={()=>toggleSubTask(subtask.taskUniqueIdentifier)}  key={`${task.taskUniqueIdentifier}-${index}`} className={"subtask"}>
                                            <td> {subtask.subtasks.length > 0 && <>
                                                {openSubTask === subtask.taskUniqueIdentifier ? <>
                                                    <FeatherIcon
                                                        icon="chevron-right"
                                                        className={"table-icon"}
                                                    />
                                                </> : <>
                                                    <FeatherIcon
                                                        icon="chevron-down"
                                                        className={"table-icon"}
                                                    />
                                                </>}
                                            </>}{subtask.taskName}</td>
                                            <td>{new Date(subtask.startDate).toLocaleDateString()}</td>
                                            <td>{Math.floor((new Date(subtask.endDate) - new Date(subtask.startDate)) / (1000 * 60 * 60 * 24))} Days</td>
                                            <td>{subtask.assignee}</td>
                                            <td><span className={subtask.status==="Not Started" ? "badge status-badge": subtask.status==="Completed" ? "badge complete-status-badge" :  "badge progress-status-badge"}>{subtask.status}</span></td>
                                            <td>{subtask.percentageProgress} %</td>
                                        </tr>
                                        {
                                            subtask.subtasks && subtask.subtasks.length > 0 && (
                                                subtask.subtasks.map((subtask1, subtask1index)=>(
                                                    <>
                                                        <tr key={`${subtask.taskUniqueIdentifier}-${subtask1index}`} className={"subtask1"}>
                                                            <td>{subtask1.taskName}</td>
                                                            <td>{new Date(subtask1.startDate).toLocaleDateString()}</td>
                                                            <td>{Math.floor((new Date(subtask1.endDate) - new Date(subtask1.startDate)) / (1000 * 60 * 60 * 24))} Days</td>
                                                            <td>{subtask1.assignee}</td>
                                                            <td><span className={subtask1.status === "Not Started" ? "badge status-badge" : subtask1.status === "Completed" ? "badge complete-status-badge" : subtask1.status === "delayed" ? "badge delayed-status-badge" : "badge progress-status-badge"}>{subtask1.status}</span></td>
                                                            <td>{subtask1.percentageProgress} %</td>
                                                        </tr>
                                                    </>
                                                ))
                                            )
                                        }
                                    </>
                                ))
                            )}

                        </React.Fragment>
                    ))
                ) : (
                    <tr>
                        <td colSpan={5}>No tasks completed</td>
                    </tr>
                )}
                </tbody>

            </table>
        </>
    )
}