import React from "react"
export default function EquipmentCostBreakdown({data}){
    return (
        <>
            <table className={"table task-progress mt-3 border"}>
                <thead>
                <tr>
                    <th>Equipment</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Total Cost</th>
                </tr>
                </thead>
                <tbody>
                {
                    data.length > 0 ? <>
                        {
                            data.map((item, index)=>(
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.unit_price}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.total_price}</td>
                                </tr>
                            ))
                        }
                    </> : <>
                        <tr>
                            <td colSpan={4}>No records found</td>
                        </tr>
                    </>
                }
                <tr>

                </tr>
                </tbody>

            </table>
        </>
    )
}