import React, {useEffect, useState} from "react"
import axios from "axios";
import {useParams} from "react-router-dom";
export default function MaterialsReports(){
    const {id}=useParams()
    const[materials, setMaterials]=useState([])
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    var api_url =  process.env.REACT_APP_API_BASE + `reports/material/${id}?startDate=${localStorage.getItem('startDate')}&endDate=${localStorage.getItem('endDate')}`;
    const getMaterialReports = async () =>{
        try {
            const response = await axios(api_url, config)
            setMaterials(response.data.data)
            console.log(response.data)
        } catch (e) {

        } finally {

        }
    }
    useEffect(()=>{
        getMaterialReports()
    }, [])
    return (
        <>
            <table className={"table task-progress mt-3 border"}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                </tr>
                </thead>
                <tbody>
                {
                    materials.length > 0 ? <>
                        {
                            materials.map((material, index)=>(
                                <tr key={index}>
                                    <td>{material.materialName}</td>
                                    <td>{material.unitOfMeasurement}</td>
                                    <td>{material.quantity}</td>
                                </tr>
                            ))
                        }
                    </> : <>

                    </>
                }

                </tbody>

            </table>
        </>
    )
}