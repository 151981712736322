import React, {useState, useEffect, useRef} from "react"
import { useParams, useHistory } from "react-router-dom";
import { Alert, Button, Card, CardHeader, CardBody, Container, Col, Form, Label, Input, Row, Table, Modal } from "reactstrap";

import * as url from "../../helpers/jengasmart_api_urls";

import axios from 'axios';
import { map } from "lodash";

import moment from 'moment';
import FeatherIcon from "feather-icons-react";
import ReactPaginate from "react-paginate";

const DailyLogs = (props) => {
  const params = useParams();
  const history = useHistory();
  const [page, setPage]=useState(1)
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const [listError, setListError] = useState(false);
  const[totalPages, setTotalPages]=useState(0)
  const tableRef = useRef(null);
  const handlePageClick = (event) => {
    setPage(event.selected +1)
  };
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
      }
    };
    var api_url = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS + '/project/' + props.projectId + `?page=${page}`;
  const getLogs = async ()=>{
    try {
      const log = await axios.get(api_url, config)
      setList(log.data.data)
      setTotalPages(log.data.totalPages)
      if (tableRef.current) {
        tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } catch (e){
      /*console.log(e)
      if(e.response.status === 401 || error.response.status === 403) {
        props.history.push('/login');
      }*/
    }
  }
  useEffect(() => {
    getLogs()

    }, [page]);

  return (
      <Row>
        <Col sm="12">
          <div className={"container-fluid p-0"} ref={tableRef}>
            <Card>
              <CardHeader >
                <Row>
                  <Col xl={6} md={6} xs={12}>
                    <h4 className="card-title">Viewing Logs</h4>
                    <p className="card-title-desc mb-3">
                      Track progress of work on field each day
                    </p>
                  </Col>
                  <Col xl={6} md={6} xs={12} style={{ textAlign: 'right' }}>
                    <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          props.history.push(`/add-progress/${params.id}?projectname=${props.projectName}`);
                        }}>
                      Add New
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="table-responsive" >
                  <Table className="table mb-0">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Project</th>
                      <th>User</th>
                      <th>Log Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      listError || list.length === 0 ? (
                          <tr>
                            <td colSpan={11} className="text-center">No logs added</td>
                          </tr>
                      ) : (
                          <>
                            {map(list, (item, key) => (
                                <tr key={key} onClick={() => history.push("/view-progress/" + item._id)}>
                                  <th scope="row">{key + 1}</th>
                                  <td>{item.projectId?.projectName}</td>
                                  <td>{item.userId?.firstName + " " + item.userId?.lastName}</td>
                                  <td>{moment(item.logDate).format("MMMM Do YYYY")}</td>
                                </tr>
                            ))}
                          </>
                      )
                    }
                    </tbody>
                  </Table>
                  <div className={"container paginate mt-4"}>
                    <ReactPaginate pageCount={totalPages}
                                   previousLabel={'Previous'}
                                   nextLabel={'Next'}
                                   breakLabel={'...'}
                                   breakClassName={'break-me'}
                                   marginPagesDisplayed={1}
                                   pageRangeDisplayed={10}
                                   onPageChange={handlePageClick}
                                   containerClassName={'pagination'}
                                   subContainerClassName={'pages pagination'}
                                   pageClassName={"page-item"}
                                   activeClassName={"active"}
                    />
                  </div>

                </div>
              </CardBody>
            </Card>
          </div>

        </Col>
      </Row>
  )
}

export default DailyLogs
