import React, {useEffect} from "react"
import queryString from "query-string";
import {useState} from "react";
import * as url from "../../../helpers/jengasmart_api_urls";
import CostReport from "../Components/CostReport";
import ProgressReport from "../Components/ProgressReport";
import {useParams} from "react-router-dom";
import axios from "axios";
import {Card, CardBody, Col} from "reactstrap";
import {Line, Pie} from "react-chartjs-2";
import CustomReports from "../CustomReports";
import ViewReport from "../CustomReports/ViewReport";
export default function ReportDetails(props){
    const [project, setProject] = useState([]);
    const [activeReport, setActiveReport] = useState(0)
    const [selectedReportTypes, setSelectedReportTypes]=useState('')
    document.title = "Project | Jenga App";
    const parsed = queryString.parse(location.search);
    const {id} = useParams()
    var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/' + id;
    var stats_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/project/' + id;
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getStats = async () => {
        const statValues = await axios.get(stats_url, config)
    }
    const getProjectDetails = async ()=>{
        const projectDetails = await axios.get(api_url, config)
        setProject(projectDetails.data.data)
    }
    useEffect(()=>{
        getProjectDetails()
        getStats()
    }, [])

    return(
        <>  
        <div className="page-content">

            <div className={"px-4"}>
                <h3 className={"primary-text-color"}>{project.projectName}</h3>
            </div>
            <div className={"container-fluid mt-4"} style={{background:"#F2F7F9"}}>
                <div className={"container-fluid reports-navigation"}>
                    <style type="text/css" media="print">
                        {`
                            @media print {
                                .report-nav-btn,
                                .report-nav-btn-active,
                                .gap-1.mb-3 {
                                    display: none !important;
                                    visibility: hidden !important;
                                }
                            }
                        `}
                    </style>
                    <div className={"d-flex flex-row flex-md-wrap w-100 gap-1 mb-3"}>
                        <Col className="p-2 rounded-3 flex-grow-1">
                           <button className={activeReport===0 ? "report-nav-btn-active w-100" : "report-nav-btn w-100"} onClick={()=>setActiveReport(0)}>Cost Report</button>
                        </Col>
                        <Col className="p-2 rounded-3 flex-grow-1">
                            <button className={activeReport===1 ? "report-nav-btn-active w-100" : "report-nav-btn w-100"} onClick={()=>setActiveReport(1)}>Progress Report</button>
                        </Col>
                        <Col className="p-2 rounded-3 flex-grow-1">
                            <button className={activeReport===2 || activeReport===3 ? "report-nav-btn-active w-100" : "report-nav-btn w-100"} onClick={()=>setActiveReport(2)}>Custom Reports</button>
                        </Col>
                    </div>
                </div>
            </div>

            {
                activeReport===0 ? <>
                    <CostReport />
                </> : activeReport ===1 ?  <>
                    <ProgressReport />
                </> : activeReport===2 ? <>
                    <CustomReports handleViewReport={()=>setActiveReport(3)}  setSelectedReportTypes={setSelectedReportTypes}   />
                </> : <>
                    <ViewReport reportTypes={selectedReportTypes}  />
                </>
            }

        </div>
        </>
    )
}