import React, {useState} from 'react'
import {useParams} from "react-router-dom";
import DashboardNavigation from "./DashboardNavigation";
import {Button, Card, CardBody, Col, Row, Table} from "reactstrap";
import {map} from "lodash";
import {EquipmentModal} from "../../../components/Modals/EquipmentModal";
import {EditequipmentModal} from "../../../components/Modals/EditEquipmentModal";
import Equipment from "./Equipment";
import Materials from "./Materials";
import Labour from "./Labour";
export default function Inventory(){
    const [activeTab, setActiveTab] = useState(1)
    const tabs = [
        {
            name:'Materials',
            key:1
        },
        {
            name:'Equipment',
            key:2
        },
        {
            name:'Labour',
            key:3
        }
    ]
    const {id} = useParams()
    return (
        <>
            <div className="page-content">
                <DashboardNavigation projectTitle={"Inventory"} />
                <div className={"container-fluid px-3 mb-3"}>
                <Row>
                {
                    tabs.map((tab, index)=>(

                            <Col key={index} xl={4} md={4}>
                                <div className={tab.key===activeTab ? "container inventory-tabs-active" : "container inventory-tabs"} onClick={()=>setActiveTab(tab.key)}>
                                    <h6>{tab.name}</h6>
                                </div>
                            </Col>

                    ))
                }
                </Row>
                </div>
                {
                    activeTab ===1 ? <>
                    <Materials />
                    </>  : activeTab===2 ?  <>
                        <Equipment />
                    </> : <>
                    <Labour />
                    </>
                }

            </div>
        </>
    )
}