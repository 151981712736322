import React, {useEffect, useState} from "react"
import {Card, CardBody, Col, Row} from "reactstrap";
import {Line} from "react-chartjs-2";
import axios from "axios";
import * as url from "../../../helpers/jengasmart_api_urls";
import {useParams} from "react-router-dom";
import MaterialCostBreakdown from "./MaterialCostBreakdown";
import LabourCostBreakdown from "./LabourCostBreakdown";
import EquipmentCostBreakdown from "./EquipmentCostBreakdown";
import MiscellaneousCostBreakDown from "./MiscellaneousCostBreakDown";
export default function CostReport(){
    const [graphLoading, setGraphLoading] = useState(true);
    const[maximumCostValue, setMaximumCostValue]=useState(0)
    const[costBreakdownChart, setcostBreakdownChart] = useState([])
    const[materialBreakDown, setMaterialBreakDown]=useState([])
    const[equipmentBreakDown, setEquipmentBreakDown]=useState([])
    const[labourBreakDown, setLabourBreakDown]=useState([])
    const[miscBreakDown, setMiscBreakDown]=useState([])
    const[breakDownTitle, setBreakDownTitle]=useState("Materials")
    const[activeBreakDown, setActiveBreakDown]=useState(0)
    const[categoryNames, setCategoryNames]=useState([])
    const {id}=useParams()
    var summary_url =  process.env.REACT_APP_API_BASE + `cost-reports/summary/${id}?startDate=${localStorage.getItem('startDate')}&endDate=${localStorage.getItem('endDate')}`;
    var breakdown_url =  process.env.REACT_APP_API_BASE + `cost-reports/breakdown/${id}?startDate=${localStorage.getItem('startDate')}&endDate=${localStorage.getItem('endDate')}`;
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const costBreakdownData = {
        type:'line',
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        datasets: [
            {
                label: "Total Material",
                fill: true,
                lineTension: 0,
                backgroundColor: "#ffffff",
                borderColor: "#556ee6",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#556ee6",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#556ee6",
                pointHoverBorderColor: "#fff",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalMaterialAmountsByMonth,
            },
            {
                label: "Total Equipment",
                fill: true,
                lineTension: 0,
                borderColor: "#F2D8A7",
                backgroundColor: "#ffffff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#ebeff2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ebeff2",
                pointHoverBorderColor: "#eef0f2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalEquipmentAmountsByMonth,
            },
            {
                label: "Total Labour",
                fill: true,
                lineTension: 0,
                borderColor: "#91B7D9",
                backgroundColor: "#ffffff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#ebeff2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ebeff2",
                pointHoverBorderColor: "#eef0f2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalLabourAmountsByMonth,
            },
            {
                label: "Subcontractor",
                fill: true,
                lineTension: 0,
                borderColor: "#d931ff",
                backgroundColor: "#ffffff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#ebeff2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ebeff2",
                pointHoverBorderColor: "#eef0f2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalSubContractorCostsAmountsByMonth,
            },
            {
                label: "Misc",
                fill: true,
                lineTension: 0,
                borderColor: "#91d992",
                backgroundColor: "#ffffff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#ebeff2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ebeff2",
                pointHoverBorderColor: "#eef0f2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalAdditionalCostsAmountsByMonth,
            },

        ],

    }
    var option = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        max: maximumCostValue,
                        stepSize: maximumCostValue / 10,
                        beginAtZero: false,
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    }
                },

            ],
            xAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                }
            }],
        },

    }
    const getBreakDownData=async ()=>{
        try {
            const response =  await axios.get(breakdown_url, config)
            const materialItems = response.data.results.category_breakdowns.find(category=>category.category==="Materials")?.items || []
            setMaterialBreakDown(materialItems)
            const labourItems = response.data.results.category_breakdowns.find(category=>category.category==="Labour")?.items || []
            setLabourBreakDown(labourItems)
            const equipmentItems = response.data.results.category_breakdowns.find(category=>category.category==="Equipment")?.items || []
            setEquipmentBreakDown(equipmentItems)
            const miscItems = response.data.results.category_breakdowns.find(category=>category.category==="Misc")?.items || []
            setMiscBreakDown(miscItems)
            console.log(materialBreakDown)
            const categories = response.data.results.category_breakdowns.map(category => ({
                category: category.category,
                total_cost: category.total_cost
            }));
            setCategoryNames(categories)
            console.log(categoryNames, "Categories")
        } catch (e) {

        } finally {

        }
    }
    const getCostSummary = async ()=>{
        try {
            const response = await axios.get(summary_url, config)

        } catch (e) {

        } finally {

        }
    }
    const handleSwitchBreakdown = (name, index)=>{
        setActiveBreakDown(index)
        setBreakDownTitle(name)
    }
    useEffect(()=>{
        getBreakDownData()
        getCostSummary()
    }, [])
    return (
        <>
            <div className={"d-flex flex-row flex-md-wrap w-100 gap-2 mb-3"}>
                <Col className="p-3 rounded-3 flex-grow-1">
                    <Row>
                        <Col xl={4} md={4} sm={12}>
                            {
                                categoryNames.length > 0 && (
                                    <>
                                        {
                                            categoryNames.map((cat, index)=>(
                                                <div onClick={()=>handleSwitchBreakdown(cat.category, index)} key={index} className={"container py-3 d-flex justify-content-between align-items-center px-3 bg-white mt-2"}>
                                                    <h6 className={"mb-0"}>{cat.category} <span className={"total-cost-tag"}>KES {cat.total_cost} </span></h6> <img src={"/arrow-right.png"} />
                                                </div>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </Col>
                        <Col xl={8} md={8} sm={12}>
                            <Card className="p-3 rounded-3 border-0 w-100  mb-0">
                                <CardBody className="p-1">
                                    <h5 className="primary-text-color fw-bold mb-3">
                                        {breakDownTitle} Cost Breakdown
                                    </h5>
                                    <div className="w-100">
                                        {
                                        activeBreakDown ===0 ? <>
                                            <MaterialCostBreakdown data={materialBreakDown} />
                                        </> : activeBreakDown===1 ?  <>
                                            <LabourCostBreakdown data={labourBreakDown} />
                                        </> : activeBreakDown===2 ? <>
                                            <EquipmentCostBreakdown data={equipmentBreakDown} />
                                        </> : <>
                                        <MiscellaneousCostBreakDown data={miscBreakDown} />
                                        </>
                                    }

                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                </Col>

            </div>
        </>
    )
}