import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Alert,
    Container,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    Button,
    FormFeedback,
    Form,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap';
import Dropzone from "react-dropzone";
import classnames from "classnames";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import avtar4 from "../../assets/images/users/avatar-4.jpg";
import avtar3 from "../../assets/images/users/avatar-3.jpg";
import avtar5 from "../../assets/images/users/avatar-5.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as url from "../../helpers/jengasmart_api_urls";

import axios from 'axios';
import {  map } from "lodash";
import currencies from '../../common/data/currencies';

const NewProject = (props) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [activeTab, setactiveTab] = useState(1);

  const [projectError, setProjectError] = useState('');
  const [teamMemberError, setTeamMemberError] = useState('');

  const [projectId, setProjectId] = useState('');

  const [memberEmail, setMemberEmail] = useState('');
  const [projectRole, setProjectRole] = useState('');
  const [membersList, setMembersList] = useState([]);

    //meta title
    document.title = "New Project | Jengaapp";
    
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            projectName: '',
            projectId: '',
            currency: '',
            budget: '',
            progress: 0,
            startDate: '',
            endDate: '',
            description: '',
            latitude:'',
            longitude:'',
            projectCollaborators: [
              {
                memberEmail: JSON.parse(localStorage.getItem('authUser')).user.email,
                creator: true,
                accepted: true
              }
            ],
            client:'',
            projectType:'',
            engineer: '',
            architect: '',
            structuralEngineer: '',
            quantitySurveyor: '',
            contractor: '',
            projectManager: '',
            createdBy: JSON.parse(localStorage.getItem('authUser')).user.id
        },
        validationSchema: Yup.object({
          projectName: Yup.string().required("Please Enter Your Project Name"),
          projectId: Yup.string().required("Please Enter Project ID"),
          currency: Yup.string().required("Please Select Currency"),
          budget: Yup.number().positive().integer().required("Please Enter Your Budget"),
          startDate: Yup.date().required("Please Enter a Start Date"),
          endDate: Yup.date().required("Please Enter an End Date"),
          description: Yup.string().required("Please Enter Your Description"),
          client: Yup.string().required("Please Enter Client Name"),
          projectType: Yup.string().required("Please Select Project Type"),
          engineer: Yup.string(),
          architect: Yup.string(),
          structuralEngineer: Yup.string(),
          quantitySurveyor: Yup.string(),
          contractor: Yup.string(),
          projectManager: Yup.string(),
          latitude: Yup.number().nullable(),
          longitude: Yup.number().nullable()
        }),
        onSubmit: (values) => {
            var config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
              }
            };
            var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS;
        
            axios.post(api_url, values, config)
            .then(res => {
              setactiveTab(2);
              setProjectId(res.data.data._id);
            })
            .catch(error => {
              if (error.response) {
                setProjectError(error.response.data.error[0]);
              } else if (error.request) {
                setProjectError("Server Error. Please try later.");
              } else {
                setProjectError("Server Error. Please try later.");
              }
            });
        }
    });

    function onSubmitTeamMembers() {

      if(membersList.length > 0) {
        var config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
          }
        };
  
        var data = {
          projectCollaborators: membersList
        };
        
        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/add/collaborators/' + projectId;
    
        axios.put(api_url, data, config)
        .then(res => {
          props.history.push('/projects');
        })
        .catch(error => {
          if (error.response) {
            setTeamMemberError(error.response.data.error[0]);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        });
      }
    }

    function addMemberToList() {
      var newMemberObj = {};
      var newMemberArr = [];

      if(!memberEmail || !projectRole) {
        setTeamMemberError('Fields cannot be empty');
      }
      else {
        if((JSON.parse(localStorage.getItem('authUser')).user.email) !== memberEmail) {
          newMemberObj['memberEmail'] = memberEmail.toLowerCase();
          newMemberObj['projectRole'] = projectRole;
          newMemberObj['creator'] = false;
          newMemberObj['accepted'] = false;

          newMemberArr.push(newMemberObj);

          var updatedList = membersList.concat(newMemberArr);

          setMembersList(updatedList);
          setMemberEmail('');
          setProjectRole('');
        }
        else {
          setTeamMemberError('Cannot add your email');
        }
      }
      
    }

    function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 3) {
          setactiveTab(tab);
        }
      }
    }

    function submitImage() {
      var config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };

      var bodyFormData = new FormData();
      if(selectedFiles !== null) {
        selectedFiles.forEach(file => {
          bodyFormData.append("image", file);
        });
      }
      
      var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/image/' + projectId;
  
      axios.put(api_url, bodyFormData, config)
      .then(res => {
        // setactiveTab(3);
        // setProjectId(res.data.data._id);
        props.history.push('/projects');
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
    }

    function handleAcceptedFiles(files) {
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setselectedFiles(files);
    }
  
    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    function removeCollaborator(passedEmail) {
      var newCollaboratorArr = [];

      newCollaboratorArr = membersList.filter(item => { return item.memberEmail !== passedEmail });

      setMembersList(newCollaboratorArr);
    }
  

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid id="basic-pills-wizard" className="twitter-bs-wizard">
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Projects" breadcrumbItem="New Project" />

                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({ active: activeTab === 1 })}
                        >
                          <div
                            className="step-icon mb-3"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Seller Details"
                          >
                            <i className="bx bx-list-ul"></i>
                          </div>
                          <p>Start</p>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({ active: activeTab === 2 })}
                        >
                          <div
                            className="step-icon mb-3"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Company Document"
                          >
                            <i className="bx bx-camera"></i>
                          </div>
                          <p>Almost there!</p>
                        </NavLink>
                      </NavItem>

                      {/* <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({ active: activeTab === 3 })}
                        >
                          <div
                            className="step-icon mb-3"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Bank Details"
                          >
                            <i className="bx bx-user"></i>
                          </div>
                          <p>Finishing...</p>
                        </NavLink>
                      </NavItem> */}
                    </ul>

                    <TabContent
                      className="twitter-bs-wizard-tab-content"
                      activeTab={activeTab}
                    >
                      <TabPane tabId={1}>
                        <Row>
                          <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Adding Details</h4>
                                    <p className="card-title-desc">Provide all the required details about your new project.</p>
                                </CardHeader>
                                <CardBody>
                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Project Name</Label>
                                                    <Input
                                                        name="projectName"
                                                        placeholder="Astoria Construction"
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.projectName || ""}
                                                        invalid={
                                                            validation.touched.projectName && validation.errors.projectName ? true : false
                                                        }
                                                    />
                                                    {validation.touched.projectName && validation.errors.projectName ? (
                                                        <FormFeedback type="invalid">{validation.errors.projectName}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="projectId">Project Unique ID</Label>
                                                    <Input
                                                        name="projectId"
                                                        placeholder="Enter project unique ID"
                                                        type="text"
                                                        className="form-control"
                                                        id="projectId"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.projectId || ""}
                                                        invalid={
                                                            validation.touched.projectId && validation.errors.projectId ? true : false
                                                        }
                                                    />
                                                    {validation.touched.projectId && validation.errors.projectId ? (
                                                        <FormFeedback type="invalid">{validation.errors.projectId}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label>Project Manager</Label>
                                                    <Input
                                                        name="projectManager"
                                                        placeholder="Enter project manager name"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.projectManager || ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Label className="form-label">Currency</Label>
                                                  <select 
                                                    className="form-select"
                                                    name="currency"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.currency || ""}>
                                                    <option value="">Select</option>
                                                    {map(currencies,
                                                      (item, key) => (
                                                        <option value={item.code} key={key}>{item.name}</option>
                                                      )
                                                    )}
                                                  </select>
                                                  {validation.touched.currency && validation.errors.currency ? (
                                                    <FormFeedback type="invalid">{validation.errors.currency}</FormFeedback>
                                                  ) : null}
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                  <Label htmlFor="validationCustom02">Budget</Label>
                                                  <Input
                                                      name="budget"
                                                      placeholder="2000000"
                                                      type="number"
                                                      className="form-control"
                                                      id="validationCustom02"
                                                      onChange={validation.handleChange}
                                                      onBlur={validation.handleBlur}
                                                      value={validation.values.budget || ""}
                                                      invalid={
                                                          validation.touched.budget && validation.errors.budget ? true : false
                                                      }
                                                  />
                                                  {validation.touched.budget && validation.errors.budget ? (
                                                      <FormFeedback type="invalid">{validation.errors.budget}</FormFeedback>
                                                  ) : null}
                                              </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="example-datetime-local-input" className="form-Label">Start Date</Label>
                                              <Input 
                                                name="startDate"
                                                className="form-control"
                                                type="date"
                                                id="example-datetime-local-input"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.startDate || ""}
                                                invalid={
                                                    validation.touched.startDate && validation.errors.startDate ? true : false
                                                } />
                                                {validation.touched.startDate && validation.errors.startDate ? (
                                                    <FormFeedback type="invalid">{validation.errors.startDate}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="example-datetime-local-input1" className="form-Label">End Date</Label>
                                              <Input 
                                                name="endDate"
                                                className="form-control"
                                                type="date" 
                                                id="example-datetime-local-input1"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.endDate || ""}
                                                invalid={
                                                    validation.touched.endDate && validation.errors.endDate ? true : false
                                                } />
                                                {validation.touched.endDate && validation.errors.endDate ? (
                                                    <FormFeedback type="invalid">{validation.errors.endDate}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-Label">Latitude</Label>
                                                    <Input
                                                        name="latitude"
                                                        className="form-control"
                                                        placeholder="Enter latitude"
                                                        type="number"
                                                        step="any"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.latitude || ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-Label">Longitude</Label>
                                                    <Input
                                                        name="longitude"
                                                        className="form-control"
                                                        placeholder="Enter longitude"
                                                        type="number"
                                                        step="any"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.longitude || ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="example-datetime-local-input" className="form-Label">Client</Label>
                                                    <Input
                                                        name="client"
                                                        placeholder="Enter client name"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.client || ""}
                                                        invalid={
                                                            validation.touched.client && validation.errors.client ? true : false
                                                        }
                                                    />
                                                    {validation.touched.client && validation.errors.client ? (
                                                        <FormFeedback type="invalid">{validation.errors.client}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="projectType">Project Type</Label>
                                                    <Input
                                                        type="select"
                                                        name="projectType"
                                                        id="projectType"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.projectType || ""}
                                                        invalid={validation.touched.projectType && validation.errors.projectType}
                                                    >
                                                        <option value="">Select Type</option>
                                                        <option value="residential">Residential</option>
                                                        <option value="commercial">Commercial</option>
                                                        <option value="industrial">Industrial</option>
                                                        <option value="infrastructure">Infrastructure</option>
                                                    </Input>
                                                    {validation.touched.projectType && validation.errors.projectType ? (
                                                        <FormFeedback type="invalid">{validation.errors.projectType}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label>Engineer</Label>
                                                    <Input
                                                        name="engineer"
                                                        placeholder="Enter engineer name"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.engineer || ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label>Architect</Label>
                                                    <Input
                                                        name="architect"
                                                        placeholder="Enter architect name"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.architect || ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label>Structural Engineer</Label>
                                                    <Input
                                                        name="structuralEngineer"
                                                        placeholder="Enter structural engineer name"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.structuralEngineer || ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label>Quantity Surveyor</Label>
                                                    <Input
                                                        name="quantitySurveyor"
                                                        placeholder="Enter quantity surveyor name"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.quantitySurveyor || ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label>Contractor</Label>
                                                    <Input
                                                        name="contractor"
                                                        placeholder="Enter contractor name"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.contractor || ""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="basicpill-address-input" className="form-Label">Description</Label>

                                              <Input 
                                                name="description"
                                                className="form-control"
                                                type="textarea" 
                                                id="basicpill-address-input"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.description || ""}
                                                invalid={
                                                    validation.touched.description && validation.errors.description ? true : false
                                                } />
                                                {validation.touched.description && validation.errors.description ? (
                                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        {projectError && projectError ? (
                                          <Alert color="danger">{projectError}</Alert>
                                        ) : null}

                                        <Button color="primary" type="submit">
                                            Create Project
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={2}>
                        <Row>
                          <Col xl={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Uploading Image</h4>
                                    <p className="card-title-desc">Spice up your project with a picture.</p>
                                </CardHeader>
                                <CardBody>
                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            submitImage();
                                        }}
                                    >
                                        <Row>
                                          <Col md="12">
                                            <Dropzone
                                              onDrop={(acceptedFiles) => {
                                                handleAcceptedFiles(acceptedFiles);
                                              }}
                                            >
                                              {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone">
                                                  <div
                                                    className="dz-message needsclick mt-2"
                                                    {...getRootProps()}
                                                  >
                                                    <input {...getInputProps()} />
                                                    <div className="mb-3">
                                                      <i className="display-4 text-muted bx bx-cloud-upload" />
                                                    </div>
                                                    <h4>Drop file here or click to upload.</h4>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                            <div className="dropzone-previews mt-3 mb-3" id="file-previews">
                                              {selectedFiles.map((f, i) => {
                                                return (
                                                  <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                  >
                                                    <div className="p-2">
                                                      <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                          <img
                                                            data-dz-thumbnail=""
                                                            height="80"
                                                            className="avatar-sm rounded bg-light"
                                                            alt={f.name}
                                                            src={f.preview}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Link
                                                            to="#"
                                                            className="text-muted font-weight-bold"
                                                          >
                                                            {f.name}
                                                          </Link>
                                                          <p className="mb-0">
                                                            <strong>{f.formattedSize}</strong>
                                                          </p>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Card>
                                                );
                                              })}
                                            </div>
                                          </Col>
                                        </Row>
                                        <button
                                          type="button"
                                          className="btn btn-link btn-rounded waves-effect"
                                          onClick={() => props.history.push('/projects')}
                                        >
                                          Skip
                                        </button>
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId={3}>
                      <Row>
                        <Col xl={6}>
                          <Form 
                            className="needs-validation"
                            onSubmit={(e) => {
                                e.preventDefault();
                                onSubmitTeamMembers();
                                return false;
                            }}>
                            <Card className="mb-5">
                                <CardHeader>
                                    <h4 className="card-title">Adding Collaborators</h4>
                                    <p className="card-title-desc mb-3">Type their email and select their role</p>

                                    <div className="mt-4 mt-md-0">
                                      <img className="img-thumbnail rounded-circle avatar-md" alt="200x200" src={avtar4} data-holder-rendered="true" />

                                      <img className="img-thumbnail rounded-circle avatar-md" alt="200x200" src={avtar3} data-holder-rendered="true" />

                                      <img className="img-thumbnail rounded-circle avatar-md" alt="200x200" src={avtar5} data-holder-rendered="true" />
                                  </div>
                                </CardHeader>
                                <CardBody>
                                    
                                        <Row>
                                            <Col md="8">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom04">Email</Label>
                                                    <Input
                                                        name="email"
                                                        placeholder="gladys@jengaapp.com"
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom04"
                                                        onChange={(e) => setMemberEmail(e.target.value)}
                                                        value={memberEmail}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="8">
                                              <FormGroup className="mb-3">
                                                <Label className="form-label">Role</Label>
                                                <Input
                                                    name="projectRole"
                                                    placeholder="Project Supervisor"
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom05"
                                                    onChange={(e) => setProjectRole(e.target.value)}
                                                    value={projectRole}
                                                />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {teamMemberError && teamMemberError ? (
                                          <Alert color="danger">{teamMemberError} <span onClick={() => setTeamMemberError('')} style={{ float: 'right' }}><i className="mdi mdi-close me-2"></i></span></Alert>
                                        ) : null}

                                        <button
                                          type="button"
                                          className="btn btn-link btn-rounded waves-effect"
                                          onClick={() => props.history.push('/projects')}
                                        >
                                          Skip
                                        </button>
                                        <Button color="primary" onClick={() => addMemberToList()}>
                                            Add To List
                                        </Button>
                                    
                                </CardBody>
                            </Card>

                            <h4 className="card-title mb-3">Click here to submit list of added collaborators</h4>

                            <Button color="success" type="submit">
                              Submit List
                            </Button>
                          </Form>
                        </Col>
                        <Col xl={6}>
                          <Row>
                          {membersList.map((user, key) => (
                            <Col xl="6" sm="12" key={key}>
                              <Card className="text-center">
                                <CardBody>
                                  <UncontrolledDropdown className="dropdown text-end">
                                    <DropdownToggle className="text-muted font-size-16" tag="a">
                                      <i className="bx bx-dots-horizontal-rounded"></i>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                      <DropdownItem to="#" onClick={() => removeCollaborator(user.memberEmail)}>Remove</DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                  {!user.img ? (
                                    <div className="avatar-xl mx-auto mb-4">
                                      <span
                                        className={
                                          "avatar-title bg-soft-light text-light display-4 m-0 rounded-circle"
                                        }
                                      >
                                        <i className="bx bxs-user-circle"></i>
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="mx-auto mb-4">
                                      <img
                                        className="avatar-xl rounded-circle img-thumbnail"
                                        src={user.img}
                                        alt=""
                                      />
                                    </div>
                                  )}

                                  <h5 className="font-size-15 mb-1">
                                    <Link to="#" className="text-dark">
                                      {user.memberEmail}
                                    </Link>
                                  </h5>
                                  <p className="text-muted mb-2">{user.projectRole}</p>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                          </Row>
                        </Col>
                      </Row>
                    </TabPane> */}
                  </TabContent>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default NewProject;