import React, {useState, useCallback, useEffect} from "react"
import {toast, ToastContainer} from "react-toastify";
import {Col, Input, Label, Modal, Row, TabContent, Nav, NavItem, NavLink, TabPane} from "reactstrap";
import axios from "axios";
import {useParams} from "react-router-dom";
import {map} from "lodash";
import * as url from "../../helpers/jengasmart_api_urls";

export default function UpdateChangeOrderModal({ showModal, closeModal, selectedOrder, refresh}){
    const [changeOrderTitle, setChangeOrderTitle]=useState('')
    const[changeOrderId, setChangeOrderId]=useState('')
    const[changeOrderDate, setChangeOrderDate]=useState('')
    const {id} = useParams()
    const [taskList, setTaskList] = useState([]);
    const [parentTask, setParentTask]= useState([])
    const [subTask, setSubTask]= useState([])
    const [task, setTask]= useState([])
    const [fields, setFields] = useState([]);
    const [list, setList] = useState([]);
    const [error, setError] = useState(false);
    const[assignTo, setAssignTo]=useState('')
    const[scope, setScope]=useState('')
    const [changeOrderDescription, setChangeOrderDescription]=useState('')
    const[scopeImpact, setScopeImpact]=useState('')
    const[costImpact, setCostImpact]=useState('')
    const[scheduleImpact, setScheduleImpact]=useState('')
    const[otherImpact, setOtherImpact]=useState('')
    const[uniqueIdentifier, setUniqueIdentifier]=useState("")
    const[changeOrderStatus, setChangeOrderStatus]=useState("")
    const addField = () => {
        setFields([...fields, { category: "", name: "", quantity: "", rate: "", description: "" }]);
    };
    const handleInputChange = (index, field, value) => {
        const updatedFields = [...fields];
        updatedFields[index][field] = value;
        setFields(updatedFields);
    };
    const terms = "By signing this change order, either by printing and physically signing or digitally signing, the Owner hereby agrees that the contract shall be adjusted in the amounts described within this change order. Time is of the essence. Change orders must be approved by both the Owner and Contractor to be valid. Change orders may become invalid if work has progressed since the Change Order was issued but before it was approved by the Owner. In this event, the Builder will notify the Owner and issue an updated Change Order for consideration. Changes to the project may also affect the project schedule. The Owner acknowledges that the project timeline may be extended by the Builder, as necessary, with their approval of change orders. The Owner agrees to fund this change under the terms of their contract with the Builder and their construction Lender (if applicable). Many lenders will require full payment of changes in conjunction with the payment request following the approval of Change Orders."
    const handleClose = () =>{
        closeModal()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const CHANGE_ORDER_URL = process.env.REACT_APP_API_BASE + `change-orders/${changeOrderId}`
        const payload = {
            changeOrderStatus:changeOrderStatus
        }
        try {
            const response = await axios.put(CHANGE_ORDER_URL, payload, config)
            if (response.status===200){
                toast.success("Change order updated successfully",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
                refresh()
                closeModal()
                setChangeOrderTitle("");
                setChangeOrderDescription("");
                setScopeImpact("");
                setScheduleImpact("");
                setAssignTo("");
                setCostImpact("");
                setFields([{ category: "", name: "", quantity: "", rate: "", description: "" }]);
            } else {
                toast.error("An error occurred! Try again later",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
            }
        } catch (e) {
            toast.error("An error occurred! Try again later",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        }

    }
    const PARENT_TASK_URL = process.env.REACT_APP_API_BASE + "parenttasks/project/" + id
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getParentTasks = async ()=>{
        const parentTasks = await axios.get(PARENT_TASK_URL, config)
        setParentTask(parentTasks.data.data)
    }
    useEffect(() => {
        getParentTasks()
    }, []);
    useEffect(() => {
        console.log(selectedOrder)
        if (selectedOrder) {
            setChangeOrderDescription(selectedOrder.changeOrderDescription || '');
            setScopeImpact(selectedOrder.scopeImpact || '');
            setCostImpact(selectedOrder.costImpact || '');
            setScheduleImpact(selectedOrder.scheduleImpact || '');
            setChangeOrderTitle(selectedOrder.changeOrderTitle || '')
            setAssignTo(selectedOrder.requestedBy._id || '')
            setUniqueIdentifier(selectedOrder.coUniqueIdentifier || '')
            setChangeOrderStatus(selectedOrder.changeOrderStatus || '')
            setChangeOrderId(selectedOrder._id || '')
        }
        if (selectedOrder?.changeOrderDate) {
            const formattedDate = new Date(selectedOrder.changeOrderDate).toISOString().split("T")[0];
            setChangeOrderDate(formattedDate);
        }
        if (selectedOrder?.lineItems?.length > 0) {
            setFields(selectedOrder.lineItems.map(item => ({
                category: item.category || "",
                name: item.name || "",
                quantity: item.quantity || "",
                rate: item.rate || "",
                description: item.description || ""
            })));
        } else {
            setFields([]);
        }
    }, [selectedOrder]);
    const handleParentTaskSelect = (id) => {
        if(id){
            setScope(id)
            let filteredParentTasks = parentTask.filter(parent => parent._id === id)
            setSubTask(filteredParentTasks[0].subtasks)
        } else {
            setTaskList([])
            setSubTask([])
        }
    }
    const handleSubTaskSelect = (id) => {
        setScope(id)
        let filteredSubTasks = subTask.filter(sub => sub._id === id)
        setTaskList(filteredSubTasks[0].subtasks)
    }

    const getCollaborators = async()=>{
        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/collaborators/' + id;
        const collaboratorsResponse = await axios.get(api_url, config)
        setList(collaboratorsResponse.data.data)
    }
    useEffect(() => {
        console.log(selectedOrder)
        getCollaborators()
    }, []);
    return (
        <>
            <ToastContainer autoClose={3000} />
            <div className={"adduser-role"}>
                <Modal
                    className={"add-log-modal"}
                    centered
                    backdrop={true}
                    isOpen={showModal}
                    fullscreen={true}
                    dialogClassName="slide-in-right"
                    toggle={handleClose}
                >
                    <div className="modal-header">
                        <h5 className="card-title mt-0" id="myModalLabel">
                           View Change Order
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <Row>
                                <Col xl={3} md={3}>
                                    <div className={"container-fluid h-100 d-flex align-items-center justify-content-center"} style={{backgroundColor:"#F1F1F1"}}>
                                        <h6 className={"mb-0 text-center title"}>
                                            # {uniqueIdentifier}
                                        </h6>
                                    </div>
                                </Col>
                                <Col xl={6} md={6}>
                                    <Input
                                        name="changeOrderTitle"
                                        className="form-control"
                                        type="text"
                                        id="example-datetime-local-input"
                                        placeholder={"Change Order Title"}
                                        required={true}
                                        value={changeOrderTitle}
                                        readOnly={true}
                                        onChange={(e) => setChangeOrderTitle(e.target.value)}
                                    />
                                </Col>
                                <Col xl={2} md={2}>
                                    <select onChange={(e)=>setChangeOrderStatus(e.target.value)} className={"form-control"} value={changeOrderStatus}>
                                        <option value={"in-review"}>In Review</option>
                                        <option value={"approved"}>Approved</option>
                                    </select>
                                </Col>
                            </Row>
                            <h5 className={"title mt-3"}>Co Details</h5>
                            <div className="container-fluid p-3" style={{ background: "#F7F7F7" }}>
                              {/*  <Row>
                                    <Col className={"mt-3"}  xl={4} md={4}>
                                        <Label className="form-label fw-lighter">Parent Task</Label>
                                        <select
                                            className="form-select"

                                            onChange={(e) => handleParentTaskSelect(e.target.value)}
                                        >
                                            <option value="">Select</option>
                                            {map(parentTask, (taskItem, index) => (
                                                <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                            ))}
                                        </select>

                                    </Col>
                                    {
                                        subTask.length > 0 && (
                                            <Col className={"mt-3"}  xl={4} md={4} >
                                                <Label className="form-label fw-lighter">Sub Task</Label>
                                                <select
                                                    className="form-select"
                                                    name="subtaskTagged"
                                                    onChange={(e) => handleSubTaskSelect(e.target.value)}

                                                >
                                                    <option value="">Select</option>
                                                    {map(subTask, (taskItem, index) => (
                                                        <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        )
                                    }

                                    {
                                        taskList.length > 0 && (
                                            <Col className={"mt-3"}  xl={4} md={4}>
                                                <Label className="form-label fw-lighter">Task</Label>
                                                <select
                                                    className="form-select"
                                                    name="assignTo"

                                                    onChange={(e) => {
                                                        setTask(e.target.value)
                                                        setScope(e.target.value)
                                                    } }
                                                >
                                                    <option value="">Select</option>
                                                    {map(taskList, (taskItem, index) => (
                                                        <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                                    ))}

                                                </select>

                                            </Col>
                                        )
                                    }
                                </Row>*/}
                                <Row>

                                    <Col className={"mt-3"}  xl={6} md={6}>
                                        <Label className="form-label fw-lighter">Completion Date</Label>
                                        <input readOnly={true} value={changeOrderDate}  type={"date"} className={"form-control"} placeholder={"dd-mm-yyyy"}/>
                                    </Col>
                                    <Col className={"mt-3"}  xl={6} md={6}>
                                        <Label className="form-label fw-lighter">Assign To: </Label>
                                        <select
                                            className="form-select"
                                            name="taskTagged"
                                            onChange={(e) => {
                                                setAssignTo(e.target.value)
                                            } }
                                            value={assignTo}

                                        >
                                            <option value="">Select</option>
                                            {map(list, (colab, index) => (
                                                <option value={colab._id} key={index}>{colab.memberEmail} {colab._id}</option>
                                            ))}
                                        </select>
                                    </Col>
                                </Row>
                                <label  className={"fw-lighter mt-3"}>Description</label>
                                <textarea readOnly={true} value={changeOrderDescription} onChange={(e)=>setChangeOrderDescription(e.target.value)}  className={"form-control"} rows={4} placeholder={"Provide a clear and detailed description of the change being requested and the justification for this change"}></textarea>
                                <Row className={"mt-3"}>
                                    <Col xl={3} md={3} xs={12}>
                                        <label className={"fw-lighter"}>Scope Impact</label>
                                        <Input
                                            name="scopeImpact"
                                            className="form-control"
                                            type="text"
                                            id="example-datetime-local-input"
                                            required={true}
                                            value={scopeImpact}
                                            readOnly={true}
                                            onChange={(e) => setScopeImpact(e.target.value)}
                                        />
                                    </Col>
                                    <Col  xl={3} md={3}>

                                        <Label className={"fw-lighter"}> Schedule Impact</Label>
                                        <Input
                                            name="scopeImpact"
                                            className="form-control"
                                            type="text"
                                            id="example-datetime-local-input"
                                            required={true}
                                            value={scheduleImpact}
                                            readOnly={true}
                                            onChange={(e) => setScheduleImpact(e.target.value)}
                                        />

                                    </Col>
                                    <Col   xl={3} md={3}>

                                        <Label className={"fw-lighter"}>Cost Impact</Label>
                                        <Input
                                            name="scopeImpact"
                                            className="form-control"
                                            type="text"
                                            id="example-datetime-local-input"
                                            required={true}
                                            value={costImpact}
                                            readOnly={true}
                                            onChange={(e) => setCostImpact(e.target.value)}
                                        />
                                    </Col>
                                    <Col   xl={3} md={3}>
                                        <Label className={"fw-lighter"}>Other Impact</Label>
                                        <Input
                                            name="scopeImpact"
                                            className="form-control"
                                            type="text"
                                            id="example-datetime-local-input"
                                            required={true}
                                            value={otherImpact}
                                            readOnly={true}
                                            onChange={(e) => setOtherImpact(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {fields.map((item, index) => (
                                <div key={index}className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                    <Row>
                                        <Col xl={3} md={3}>
                                            <Label className={"fw-lighter"}>Category</Label>
                                            <input
                                                type="text"
                                                placeholder="Category"
                                                value={item.category}
                                                readOnly={true}
                                                onChange={(e) => handleInputChange(index, "category", e.target.value)}
                                                className="form-control mb-2"
                                            />
                                        </Col>
                                        <Col xl={3} md={3}>
                                            <Label className={"fw-lighter"}>Name</Label>
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                value={item.name}
                                                readOnly={true}
                                                onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                                className="form-control mb-2"
                                            />
                                        </Col>
                                        <Col xl={2} md={2}>
                                            <Label className={"fw-lighter"}>Quantity</Label>
                                            <input
                                                type="number"
                                                placeholder="Quantity"
                                                value={item.quantity}
                                                readOnly={true}
                                                onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                                                className="form-control mb-2"
                                            />
                                        </Col>
                                        <Col xl={2} md={2}>
                                            <Label className={"fw-lighter"}>Rate</Label>
                                            <input
                                                type="number"
                                                placeholder="Rate"
                                                value={item.rate}
                                                readOnly={true}
                                                onChange={(e) => handleInputChange(index, "rate", e.target.value)}
                                                className="form-control mb-2"
                                            />
                                        </Col>
                                        <Col xl={2} md={2}></Col>
                                        <Col xl={12} md={12}>
                                            <Label className={"fw-lighter mt-2"}>Description</Label>
                                            <textarea
                                                placeholder="Line Item Description"
                                                value={item.description}
                                                readOnly={true}
                                                onChange={(e) => handleInputChange(index, "description", e.target.value)}
                                                className="form-control"
                                            />
                                        </Col>
                                    </Row>






                                </div>
                            ))}
                        {/*    <div onClick={addField} className="container-fluid border border-dashed mt-3 p-3 text-center" style={{ background: "#F7F7F7" }}>
                                <h6 className={"title mb-0"}> + Add Line Item</h6>
                            </div>*/}
                          {/*  <h5 className={"title mt-5"}>Terms</h5>
                            <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                <textarea className={"form-control"} rows={5} readOnly={true}>
                                    {terms}
                                </textarea>
                            </div>
                            <div className={"container-fluid d-flex justify-content-end mt-3"}>
                                <button className={"btn submit-button"} >
                                    Save & Submit
                                </button>
                            </div>*/}
                            <div className={"container-fluid d-flex justify-content-end mt-3"}>
                                <button className={"btn submit-button"}>
                                    Update
                                </button>
                            </div>
                        </div>

                    </form>

                </Modal>
            </div>
        </>
    )
}