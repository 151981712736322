import React, {useState, useCallback, useEffect} from "react"
import {toast, ToastContainer} from "react-toastify";
import {Col, Input, Label, Modal, Row, TabContent, Nav, NavItem, NavLink, TabPane, Table} from "reactstrap";
import classnames from "classnames";
import AddMaterialLogs from "./AddMaterialLogs";
import {map} from "lodash";
import * as url from "../../helpers/jengasmart_api_urls";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import queryString from "query-string";
import {useDropzone} from "react-dropzone";
import AddEquipmentLog from "./AddEquipmentLog";
import AddLabourLog from "./AddLabourLog";
import HealthandSafety from "./HealthandSafety";
import PhotoLogs from "./PhotoLogs";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import EditDailyLogModal from "./EditDailyLogModal";
export default function ViewDailyLogModal({viewLog, onClose, onDrop, logId}){
    const [activeTab, setActiveTab] = useState("1");
    const [logDetail, setLogDetail] = useState({});
    const [taskType, setTaskType]=useState("")
    const topNavigation = [
        {
            title:'Materials',
            key:'1'
        },
        {
            title:'Equipment',
            key:'2'
        },
        {
            title:'Labour',
            key:'3'
        },
        {
            title:'Health & Safety logs',
            key:'4'
        },
        {
            title:'Photos',
            key:'5'
        },
    ]
    const handleClose = () =>{
        onClose()
    }
    const handleSubmit = (e)=>{
        e.preventDefault()
    }
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const params = useParams();
    const [draggedFiles, setDraggedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [parentTask, setParentTask]= useState([])
    const [subTask, setSubTask]= useState([])
    const [uploading, setUploading] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [parentTaskTagged, setParentTaskTagged]=useState('')
    const [subTaskTagged, setSubTaskTagged]=useState('')
    const [taskTagged, setTaskTagged]=useState('')
    const[completedToday, setCompletedToday]=useState("")
    const[forTomorrow, setForTomorrow]=useState("")
    const[logDate, setLogDate]=useState('')
    const[delays, setDelays]=useState("")
    const [labourItems, setLabourItems] = useState([]);
    const [equipmentItems, setEquipmentItems] = useState([]);
    const [materialsItems, setMaterialsItems] = useState([]);
    const [hsItems, setHsItems] = useState([]);
    const[editLog, setEditLog]=useState(false)

    const [materialInputFields, setMaterialInputFields] = useState([
        { materialName: '', materialUniqueId: '', materialQuantity: '', materialPrice: '' }
    ])
    const [equipmentInputFields, setEquipmentInputFields] = useState([
        { equipmentName: '', equipmentNumber: '', equipmentRatePrice: '' }
    ]);
    const [labourInputFields, setLabourInputFields] = useState([
        { labourName: '', labourNumber: '', labourRatePrice: '' }
    ]);
    const [hsInputFields, setHsInputFields] = useState([
        { hsIndicatorType: '', hsIndicator: '', hsQuantity: '', hsComment:'' }
    ]);
    const [comments, setComments] = useState("")
    const [taskData, setTaskData] = useState({
        projectId: params.id,
        userId: JSON.parse(localStorage.getItem('authUser')).user.id,
        logDate: '',
        weatherCondition: '',
        taskTagged: '',
        description: '',
        image:[]
    })
    const PARENT_TASK_URL = process.env.REACT_APP_API_BASE + "parenttasks/project/" + params.id
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getParentTasks = async ()=>{
        const parentTasks = await axios.get(PARENT_TASK_URL, config)
        setParentTask(parentTasks.data.data)
    }
    const submitForm = async (e) => {
        e.preventDefault();
        setUploading(true);

        const formData = new FormData();
        files.forEach((file) => {
            formData.append('image', file);
        });

        const mergeArrays = (existingItems, newItems, key) => {
            const existingKeys = existingItems.map(item => item[key]);
            const filteredNewItems = newItems.filter(item => !existingKeys.includes(item[key]));
            return [...existingItems, ...filteredNewItems];
        };
        const mergedLabourItems = mergeArrays(labourItems, labourInputFields, 'labourName');
        const mergedMaterialsItems = mergeArrays(materialsItems, materialInputFields, 'materialName');
        const mergedEquipmentItems = mergeArrays(equipmentItems, equipmentInputFields, 'equipmentName');
        const mergedHsItems = mergeArrays(hsItems, hsInputFields, 'hsQuantity');
        const arrays = [
            { array: mergedLabourItems, prefix: 'labour' },
            { array: mergedMaterialsItems, prefix: 'material' },
            { array: mergedEquipmentItems, prefix: 'equipment' },
            {array: mergedHsItems, prefix: 'healthAndSafety'},
        ];
        console.log(arrays)
        arrays.forEach(({ array, prefix }) => {
            if (array.length > 0) {
                array.forEach((item, index) => {
                    Object.entries(item).forEach(([key, value]) => {
                        formData.append(`${prefix}[${index}][${key}]`, value);
                    });
                });
            }
        });

        formData.append('description', taskData.description);
        formData.append('taskTagged', taskData.taskTagged);
        formData.append('weatherCondition', taskData.weatherCondition);
        formData.append('logDate', taskData.logDate);
        formData.append('projectId', taskData.projectId);
        formData.append('userId', taskData.userId);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
            }
        };

        const api_url = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS + `/${params.id}`;
        axios.put(api_url, formData, config)
            .then(res => {
                toast.success("Log updated successfully",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
                setUploading(false);

            })
            .catch(error => {
                setUploading(false);
                if (error.response) {
                    console.log(error.response);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    };
    const printInvoice = () => {
        let printContents = document.getElementById('adduser-role').innerHTML;
        let newWindow = window.open('', '_blank');

        if (!newWindow) {
            alert("Popup blocked! Allow popups for this site.");
            return;
        }

        // Collect styles from <link> and <style> tags
        let styles = [...document.styleSheets].map(styleSheet => {
            try {
                return [...styleSheet.cssRules].map(rule => rule.cssText).join("\n");
            } catch (e) {
                return ""; // Ignore CORS issues
            }
        }).join("\n");

        newWindow.document.open();
        newWindow.document.write(`
        <html>
        <head>
            <title>Print</title>
            <style>${styles}</style>
            <link rel="stylesheet" href="${window.location.origin}/styles.css">
        </head>
        <body onload="setTimeout(() => { window.print(); window.close(); }, 500);">
            ${printContents}
        </body>
        </html>
    `);
        newWindow.document.close();
    };



    const submitForm1 = async (e) => {
        e.preventDefault();
        if(taskData.taskTagged){
            setUploading(true)
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('image', file);
            });
            const arrays = [
                { array: labourInputFields, prefix: 'labour' },
                { array: materialInputFields, prefix: 'material' },
                { array: equipmentInputFields, prefix: 'equipment' },
                { array: hsInputFields, prefix: 'healthAndSafety' },
            ];

            arrays.forEach(({ array, prefix }) => {
                if (array.length > 0) {
                    array.forEach((item, index) => {
                        Object.entries(item).forEach(([key, value]) => {
                            formData.append(`${prefix}[${index}][${key}]`, value);
                        });
                    });
                }
            });
            console.log(taskData.logDate)
            formData.append('logDate', taskData.logDate);
            formData.append('projectId', taskData.projectId);
            formData.append('userId', taskData.userId);
            formData.append('workCompletedToday', completedToday );
            formData.append('workPlanForTomorrow', forTomorrow );
            formData.append('workChallenges', delays );
            formData.append('parentTaskTagged', parentTaskTagged)
            formData.append('subTaskTagged', subTaskTagged)
            formData.append('taskTagged', taskTagged)
            formData.append('taskType', taskType)
            formData.append('weatherCondition', 'Sunny')

            var config = {
                headers: {
                    'Content-Type': 'multipart/form-data', // Use multipart/form-data for FormData
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
                }
            };


            var api_url = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS;
            console.log(formData)
            setDisableButton(true)
            axios.post(api_url, formData, config)

                .then(res => {
                    toast.success("Log added successfully",{
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: "logId"
                    })
                    setUploading(false)
                    handleClose()
                })
                .catch(error => {
                    toast.error(error.response.data.error,{
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: "logId"
                    })
                    setUploading(false)
                    if (error.response) {
                        console.log(error.response)
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        } else {
            toast.error("Select a task to continue",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        }

    }

    document.title = "Add Log | Jenga App";

    useEffect(() => {
        getParentTasks()

    }, []);

    useEffect(() => {
        if (logId){
            var api_url = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS + '/' + logId;
            axios.get(api_url, config)
                .then(res => {
                    console.log(res.data.data)
                    setLogDetail(res.data.data);
                    const formattedDate = new Date(res.data.data.logDate).toISOString().split("T")[0];
                    setLogDate(formattedDate);
                })
                .catch(error => {
                    if (error.response) {
                        if(error.response.status === 401 || error.response.status === 403) {
                            props.history.push('/login');
                        }
                        else {

                        }
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        }
    }, [logId]);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const openLightbox = (index) => {
        setSelectedImageIndex(index);
        setLightboxIsOpen(true);
    };
    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };
    const removeMaterialFields = (index) => {
        let data = [...materialsItems];
        data.splice(index, 1);
        setMaterialsItems(data);
    };
    const removeEquipmentFields = (index) => {
        let data = [...equipmentItems];
        data.splice(index, 1);
        setEquipmentItems(data);
    };
    const removeLabourFields = (index) => {
        let data = [...labourItems];
        data.splice(index, 1);
        setLabourItems(data);
    };
    const removeHSFields = (index) => {
        let data = [...hsItems];
        data.splice(index, 1);
        setHsItems(data);
    };
    useEffect(()=>{
        const style = document.createElement('style');
        style.textContent = `
      @media print {
        .nav-tabs { display: none !important; }
        .tab-pane { display: block !important; opacity: 1 !important; }
        .tab-content > .tab-pane {
          display: block !important;
          opacity: 1 !important;
          visibility: visible !important;
        }
        .d-print-none { display: none !important; }
        .page-content { padding: 0 !important; }
         .print-button {
        display:none !important;
    }
      }
    `;
        document.head.appendChild(style);
        return () => document.head.removeChild(style);
    }, [])

    return (
        <>
            <ToastContainer autoClose={3000} />
            <div className={"adduser-role"}>
                <Modal
                    className={"add-log-modal"}
                    centered
                    backdrop={true}
                    isOpen={viewLog}
                    fullscreen={true}
                    dialogClassName="slide-in-right"
                    id={'adduser-role'}
                    toggle={handleClose}
                >
                    <div className="modal-header">
                        <h5 className="card-title mt-0" id="myModalLabel">
                            View Daily Log
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}


                        >
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>

                        <div className="modal-body">
                            <div className={"container-fluid d-flex justify-content-end"}>
                                {/*<button
                                    onClick={() => {
                                        setEditLog(true)
                                    }}
                                    className="btn submit-button mx-3"
                                    type={"button"}
                                > Edit Log1
                                    <i className="ms-2 fa fa-edit" />
                                </button>*/}
                                <Link
                                    onClick={() => {
                                        window.location.assign(`/edit-log/${logDetail._id}?project=${logDetail.projectId._id}`);
                                    }}
                                    className="btn submit-button mx-3 print-button "
                                    type={"button"}
                                > Edit Log
                                    <i className="ms-2 fa fa-edit" />
                                </Link>
                                <button
                                    onClick={printInvoice}
                                    className="btn btn-success me-2 print-button"
                                    type={"button"}
                                > Export to pdf
                                    <i className="ms-2 fa fa-print" />
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xl={12} md={12} xs={12}>
                                        <Label>Log Date</Label>
                                        <Input
                                            name="logDate"
                                            className="form-control"
                                            type="date"
                                            id="example-datetime-local-input"
                                            required={true}
                                            value={logDate}
                                            onChange={(e) => setTaskData({ ...taskData, logDate: e.target.value })}
                                        />
                                    </Col>
                                    {/* <Col xl={4} md={4} xs={12}>
                                    <Label>Log Name</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Log Name (Optional)"
                                        id="project-name"
                                        name="name"
                                        required />
                                </Col>

                                <Col xl={4} md={4} xs={12} className={"mt-3"}>
                                    <Label>Weather</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Sunny"
                                        id="project-name"
                                        name="name"
                                        required />
                                </Col>*/}
                                    <Col className={"mt-3"}  xl={4} md={4}>
                                        <Label className="form-label">Task Name</Label>
                                        <p className="mb-1">{logDetail.taskTagged?.taskName}</p>

                                    </Col>

                                </Row>
                                <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                    <h5 className="title">Work Progress</h5>
                                    <label className={"fw-lighter"}>Completed Today</label>
                                    <div className={"container-fluid border bg-white form-control p-2"}>
                                        <p>
                                            {logDetail.workCompletedToday}
                                        </p>
                                    </div>

                                    <label className={"fw-lighter  mt-3"}>Planned for Tomorrow</label>
                                    <div className={"container-fluid border bg-white form-control p-2"}>
                                        <p>
                                            {logDetail.workPlanForTomorrow}
                                        </p>
                                    </div>
                                    <label className={"fw-lighter mt-3"}>Delays and Challenges</label>
                                    <div className={"container-fluid border bg-white form-control p-2"}>
                                        <p>
                                            {logDetail.workChallenges}
                                        </p>
                                    </div>
                                </div>
                                <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                    <h5 className="title">Log Items</h5>
                                    <Nav tabs>
                                        {
                                            topNavigation.map((topnav, index)=>(
                                                <>
                                                    <NavItem key={index}>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === topnav.key })}
                                                            onClick={() => toggle(topnav.key)}
                                                        >
                                                            {topnav.title}
                                                        </NavLink>
                                                    </NavItem>
                                                </>
                                            ))
                                        }
                                    </Nav>
                                    <TabContent className={"logs-tab bg-white border border-top-0"} activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <table className={"table task-progress mt-3 border"}>
                                                <thead>
                                                <tr>
                                                    <th>Material</th>
                                                    <th>Quantity</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {map(
                                                    logDetail.materialsUsed,
                                                    (item, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                <h5 className="font-size-15 mb-1">{item.materialName}</h5>
                                                                <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p>
                                                            </td>
                                                            <td>{item.materialQuantity}</td>

                                                        </tr>
                                                    )
                                                )}
                                                </tbody>
                                            </table>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <table className={"table task-progress mt-3 border"}>
                                                <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th className="text-end">Quantity</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {map(
                                                    logDetail.equipmentUsed,
                                                    (item, key) => (
                                                        <tr key={key}>

                                                            <td>
                                                                <h5 className="font-size-15 mb-1">{item.equipmentName}</h5>
                                                                {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                                            </td>

                                                            <td className="text-end">{item.equipmentNumber}</td>

                                                        </tr>
                                                    )
                                                )}
                                                </tbody>
                                            </table>

                                        </TabPane>
                                        <TabPane tabId="3">
                                            <table className={"table task-progress mt-3 border"}>
                                                <thead>
                                                <tr>
                                                    <th>Role</th>
                                                    <th>Quantity</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {map(
                                                    logDetail.labourUsed,
                                                    (item, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                <h5 className="font-size-15 mb-1">{item.labourName}</h5>
                                                                {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                                            </td>
                                                            <td>{item.labourNumber}</td>

                                                        </tr>
                                                    )
                                                )}
                                                </tbody>
                                            </table>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <table className={"table task-progress mt-3 border"}>
                                                <thead>
                                                <tr>
                                                    <th >Indicator Type</th>
                                                    <th>Indicator</th>
                                                    <th >Quantity</th>
                                                    <th >Comment</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {map(
                                                    logDetail.healthAndSafety,
                                                    (item, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                {item.hsIndicatorType}
                                                                {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                                            </td>
                                                            <td >{item.hsIndicator}</td>
                                                            <td >{item.hsQuantity}</td>
                                                            <td>{item.hsComment}</td>

                                                        </tr>
                                                    )
                                                )}
                                                </tbody>
                                            </table>

                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Row className={"p-2"}>
                                                {map(
                                                    logDetail.image,
                                                    (item, key) => (
                                                        <Col sm={3} md={2} lg={2} key={key}>
                                                            <img src={item} className={"w-100 log-image"} onClick={() => openLightbox(key)}
                                                                 style={{ cursor: 'pointer' }}/>
                                                        </Col>
                                                    )
                                                )}
                                            </Row>

                                            {lightboxIsOpen && (
                                                <Lightbox
                                                    mainSrc={logDetail.image[selectedImageIndex]}
                                                    nextSrc={logDetail.image[(selectedImageIndex + 1) % logDetail.image.length]}
                                                    prevSrc={logDetail.image[(selectedImageIndex + logDetail.image.length - 1) % logDetail.image.length]}
                                                    onCloseRequest={closeLightbox}
                                                    onMovePrevRequest={() =>
                                                        setSelectedImageIndex((selectedImageIndex + logDetail.image.length - 1) % logDetail.image.length)
                                                    }
                                                    onMoveNextRequest={() =>
                                                        setSelectedImageIndex((selectedImageIndex + 1) % logDetail.image.length)
                                                    }
                                                />
                                            )}
                                        </TabPane>
                                    </TabContent>
                                </div>
                                <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                    <h5 className="title">Extra Comments</h5>
                                    <div className={"container-fluid border bg-white form-control p-2"}>
                                        <p>
                                            {logDetail.description}
                                        </p>
                                    </div>
                                </div>

                                {/*<div className={"container-fluid d-flex justify-content-end mt-3"}>
                                <button className={"btn submit-button"} onClick={submitForm}>
                                    Save & Submit
                                </button>
                            </div>*/}
                            </form>
                        </div>


                </Modal>
            </div>
        </>
    )
}