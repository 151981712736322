import React, { useState, useEffect } from "react";
import {map, some} from "lodash";
import axios from 'axios';
import * as url from "../../../helpers/jengasmart_api_urls";
import DashboardNavigation from "./DashboardNavigation";
import UserRoles from "./Settings/UserRoles";
import {Col, Row} from "reactstrap";
import ProjectInformation from "./Settings/ProjectInformation";
import {useParams} from "react-router-dom";
import ProjectStakeholders from "./Settings/ProjectStakeholders";
import {ToastContainer} from "react-toastify";
const Settings = props => {
    const {id} = useParams()
    const [projectDetails, setProjectDetails] = useState([])
    const getReportDetails = async ()=>{
        const api_url = process.env.REACT_APP_API_BASE + `projects/report/${id}`;
        try {
            const response = await axios.get(api_url, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token,
                }})
            setProjectDetails(response.data.data)

        } catch (e) {
            console.log(e)
        } finally {

        }

    }
    const leftmenus = [

        {
            title:'User Roles',
            key:"2"
        },
        {
            title:'Project Information',
            key:"3"
        },
        {
            title:'Location(s)',
            key:"4"
        },
        {
            title:'Project Stakeholders',
            key:"5"
        },
    ]
    const [activeMenu, setActiveMenu]=useState("2")
    useEffect(()=>{
        getReportDetails()
    }, [])
    return (
        <div className="page-content">
            <ToastContainer autoClose={5000} />
            <DashboardNavigation projectTitle={"Settings"} />
            <div className={"container-fluid px-3 py-3"}>
                <Row>
                    <Col xl={2} md={3}>
                        <div className={"container-fluid border-left settings"}>
                            {
                                leftmenus.map((menu, index)=>(
                                    <div onClick={()=>setActiveMenu(menu.key)} className={activeMenu===menu.key ? "active-setting-menu" : "setting-menu"} key={index}>
                                        {menu.title}
                                    </div>
                                ))
                            }
                        </div>
                    </Col>
                    <Col xl={10} md={10}>
                        {
                            activeMenu === "2" ? <>
                                <UserRoles />
                            </> :  activeMenu==="3" ?  <>
                            <ProjectInformation project={projectDetails} />
                            </> : <>
                                <ProjectStakeholders project={projectDetails} />
                            </>
                        }

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Settings;