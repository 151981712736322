import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import axios from "axios";
export default function ChangeOrders(){
    const {id}=useParams()
    const[orders, setOrders]=useState([])
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    var api_url =  process.env.REACT_APP_API_BASE + `change-orders/project/${id}?startDate=${localStorage.getItem('startDate')}&endDate=${localStorage.getItem('endDate')}`;
    const getChangeOrdersReports = async () =>{
        try {
            const response = await axios(api_url, config)
            setOrders(response.data.data)
        } catch (e) {

        } finally {

        }
    }
    useEffect(()=>{
        getChangeOrdersReports()
    }, [])
    return (
        <>
            <table className={"table task-progress mt-3 border"}>
                <thead>
                <tr>
                    <th>Co#</th>
                    <th>Title</th>
                    <th>Status</th>
                    <th>Scope</th>
                    <th>Date Added</th>
                    <th>Date Due</th>
                    <th>Requestor</th>
                    <th>Total Cost</th>
                </tr>
                </thead>
                <tbody>
                {
                    orders.length > 0 ? <>
                        {
                            orders.map((order, index)=>(
                                <tr key={index}>
                                    <td>{order.coUniqueIdentifier}</td>
                                    <td>{order.changeOrderTitle}</td>
                                    <td>{order.changeOrderStatus}</td>
                                    <td></td>
                                    <td>{new Date(order.createdAt).toLocaleString()}</td>
                                    <td>{new Date(order.changeOrderDate).toLocaleDateString()}</td>
                                    <td>{order.requestedBy.firstName} {order.requestedBy.lastName}</td>
                                    <td>{order.totalCosts}</td>
                                </tr>
                            ))
                        }
                    </> : <>

                    </>
                }

                </tbody>

            </table>
        </>
    )
}