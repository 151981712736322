import React, {useState, useCallback, useEffect} from "react"
import {toast, ToastContainer} from "react-toastify";
import {Col, Input, Label, Modal, Row, TabContent, Nav, NavItem, NavLink, TabPane} from "reactstrap";
import classnames from "classnames";
import AddMaterialLogs from "./AddMaterialLogs";
import {map} from "lodash";
import * as url from "../../helpers/jengasmart_api_urls";
import axios from "axios";
import {useParams} from "react-router-dom";
import queryString from "query-string";
import {useDropzone} from "react-dropzone";
import AddEquipmentLog from "./AddEquipmentLog";
import AddLabourLog from "./AddLabourLog";
import HealthandSafety from "./HealthandSafety";
import PhotoLogs from "./PhotoLogs";
export default function AddDailyLogModal({addLog, onClose, onDrop, refreshList}){
    const [activeTab, setActiveTab] = useState("1");
    const [taskType, setTaskType]=useState("")
    const topNavigation = [
        {
            title:'Materials',
            key:'1'
        },
         {
             title:'Equipment',
             key:'2'
         },
         {
             title:'Labour',
             key:'3'
         },
        {
            title:'Health & Safety logs',
            key:'4'
        },
        {
            title:'Photos',
            key:'5'
        },
    ]
    const handleClose = () =>{
        onClose()
    }
    const handleSubmit = (e)=>{
        e.preventDefault()
    }
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const params = useParams();
    const [draggedFiles, setDraggedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [parentTask, setParentTask]= useState([])
    const [subTask, setSubTask]= useState([])
    const [uploading, setUploading] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [parentTaskTagged, setParentTaskTagged]=useState('')
    const [subTaskTagged, setSubTaskTagged]=useState('')
    const [taskTagged, setTaskTagged]=useState('')
    const[completedToday, setCompletedToday]=useState("")
    const[forTomorrow, setForTomorrow]=useState("")
    const[delays, setDelays]=useState("")
    const [materialInputFields, setMaterialInputFields] = useState([
        { materialName: '', materialUniqueId: '', materialQuantity: '', materialPrice: '' }
    ])
    const [equipmentInputFields, setEquipmentInputFields] = useState([
        { equipmentName: '', equipmentNumber: '', equipmentRatePrice: '' }
    ]);
    const [labourInputFields, setLabourInputFields] = useState([
        { labourName: '', labourNumber: '', labourRatePrice: '' }
    ]);
    const [hsInputFields, setHsInputFields] = useState([
        { hsIndicatorType: '', hsIndicator: '', hsQuantity: '', hsComment:'' }
    ]);
    const [comments, setComments] = useState("")
    const [taskData, setTaskData] = useState({
        projectId: params.id,
        userId: JSON.parse(localStorage.getItem('authUser')).user.id,
        logDate: '',
        weatherCondition: '',
        taskTagged: '',
        description: '',
        image:[]
    })
    const PARENT_TASK_URL = process.env.REACT_APP_API_BASE + "parenttasks/project/" + params.id
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getParentTasks = async ()=>{
        const parentTasks = await axios.get(PARENT_TASK_URL, config)
        setParentTask(parentTasks.data.data)
    }
    const submitForm1 = async (e) => {
        e.preventDefault();
        if (!taskData.logDate){
            toast.error('Select Log Date',{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        }
        if(taskData.taskTagged){
            setUploading(true)
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('image', file);
            });
            const arrays = [
                { array: labourInputFields, prefix: 'labour' },
                { array: materialInputFields.filter(item => Object.values(item).some(value => value)), prefix: 'material' },
                { array: equipmentInputFields, prefix: 'equipment' },
                { array: hsInputFields, prefix: 'healthAndSafety' },
            ];

            arrays.forEach(({ array, prefix }) => {
                if (array.length > 0) {
                    array.forEach((item, index) => {
                        Object.entries(item).forEach(([key, value]) => {
                            formData.append(`${prefix}[${index}][${key}]`, value);
                        });
                    });
                }
            });
            formData.append('logDate', taskData.logDate);
            formData.append('projectId', taskData.projectId);
            formData.append('userId', taskData.userId);
            formData.append('workCompletedToday', completedToday );
            formData.append('workPlanForTomorrow', forTomorrow );
            formData.append('workChallenges', delays );
            formData.append('parentTaskTagged', parentTaskTagged)
            formData.append('subTaskTagged', subTaskTagged)
            formData.append('taskTagged', taskTagged)
            formData.append('taskType', taskType)
            formData.append('weatherCondition', 'Sunny')
            formData.append('description', comments)
            var config = {
                headers: {
                    'Content-Type': 'multipart/form-data', // Use multipart/form-data for FormData
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
                }
            };


            var api_url = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS;
            setDisableButton(true)
            console.log(formData)
            axios.post(api_url, formData, config)

                .then(res => {
                    toast.success("Log added successfully",{
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: "logId"
                    })
                    
                    // Display notifications if they exist in the response
                    if (res.data.notifications && res.data.notifications.length > 0) {
                        res.data.notifications.forEach((notification, index) => {
                            toast.warning(notification, {
                                position: toast.POSITION.TOP_RIGHT,
                                toastId: `notification-${index}`,
                                autoClose: 5000
                            });
                        });
                    }
                    
                    setUploading(false)
                    refreshList()
                    handleClose()
                    setTaskTagged("")
                    setParentTask("")
                    setParentTaskTagged("")
                    setSubTaskTagged("")
                    setSubTask("")
                    setTaskType("")
                })
                .catch(error => {
                    toast.error(error.response.data.error,{
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: "logId"
                    })
                    setUploading(false)
                    if (error.response) {
                        console.log(error.response)
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        } else {
            toast.error("Select a task to continue",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        }

    }


    useEffect(() => {
        getParentTasks()

    }, []);
    //Select ParentTask
    const handleParentTaskSelect = (id) => {
        setTaskType('parenttask')
        if(id){
            setParentTaskTagged(id)
            let filteredParentTasks = parentTask.filter(parent => parent._id === id)
            setSubTask(filteredParentTasks[0].subtasks)
        } else {
            setTaskList([])
            setSubTask([])
        }
    }
    const handleSubTaskSelect = (id) => {
        setSubTaskTagged(id)
        let filteredSubTasks = subTask.filter(sub => sub._id === id)
        setTaskType('subtask')
        setTaskList(filteredSubTasks[0].subtasks)
    }
    return (
        <>
            <ToastContainer autoClose={3000} />
            <div className={"adduser-role"}>
                <Modal
                    className={"add-log-modal"}
                    centered
                    isOpen={addLog}
                    fullscreen={true}
                    dialogClassName="slide-in-right"
                    backdrop={true}
                    toggle={handleClose}
                >
                    <div className="modal-header">
                        <h5 className="card-title mt-0" id="myModalLabel">
                           New Daily Log
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <Row>
                                <Col xl={12} md={12} xs={12}>
                                    <Label>Log Date</Label>
                                    <Input
                                        name="logDate"
                                        className="form-control"
                                        type="date"
                                        id="example-datetime-local-input"
                                        required={true}
                                        onChange={(e) => setTaskData({ ...taskData, logDate: e.target.value })}
                                    />
                                </Col>
                               {/* <Col xl={4} md={4} xs={12}>
                                    <Label>Log Name</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Log Name (Optional)"
                                        id="project-name"
                                        name="name"
                                        required />
                                </Col>

                                <Col xl={4} md={4} xs={12} className={"mt-3"}>
                                    <Label>Weather</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Sunny"
                                        id="project-name"
                                        name="name"
                                        required />
                                </Col>*/}
                                <Col className={"mt-3"}  xl={4} md={4}>
                                    <Label className="form-label">Parent Task</Label>
                                    <select
                                        className="form-select"

                                        onChange={(e) => handleParentTaskSelect(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        {map(parentTask, (taskItem, index) => (
                                            <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                        ))}
                                    </select>

                                </Col>
                                {
                                    subTask.length > 0 && (
                                        <Col className={"mt-3"}  xl={4} md={4} >
                                            <Label className="form-label">Sub Task</Label>
                                            <select
                                                className="form-select"
                                                name="subtaskTagged"
                                                onChange={(e) => handleSubTaskSelect(e.target.value)}

                                            >
                                                <option value="">Select</option>
                                                {map(subTask, (taskItem, index) => (
                                                    <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    )
                                }

                                {
                                    taskList.length > 0 && (
                                        <Col className={"mt-3"}  xl={4} md={4}>
                                            <Label className="form-label">Task</Label>
                                            <select
                                                className="form-select"
                                                name="taskTagged"
                                                onChange={(e) => {
                                                    setTaskData({ ...taskData, taskTagged: e.target.value })
                                                    setTaskType('task')
                                                    setTaskTagged(e.target.value)
                                                } }
                                            >
                                                <option value="">Select</option>
                                                {map(taskList, (taskItem, index) => (
                                                    <option value={taskItem._id} key={index}>{taskItem.taskName}</option>
                                                ))}
                                            </select>

                                        </Col>
                                    )
                                }
                            </Row>
                            <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                <h5 className="title">Work Progress</h5>
                                <label className={"fw-lighter"}>Completed Today</label>
                                <textarea   onChange={(e)=>setCompletedToday(e.target.value)} className={"form-control"} rows={2} placeholder={"Add notes"}></textarea>

                                <label className={"fw-lighter  mt-3"}>Planned for Tomorrow</label>
                                <textarea onChange={(e)=>setForTomorrow(e.target.value)} className={"form-control"} rows={2} placeholder={"Add notes"}></textarea>

                                <label className={"fw-lighter mt-3"}>Delays and Challenges</label>
                                <textarea onChange={(e)=>setDelays(e.target.value)} className={"form-control"} rows={2} placeholder={"Add notes"}></textarea>
                            </div>
                            <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                <h5 className="title">Log Items</h5>
                                <Nav tabs>
                                    {
                                        topNavigation.map((topnav, index)=>(
                                            <>
                                                <NavItem key={index}>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === topnav.key })}
                                                        onClick={() => toggle(topnav.key)}
                                                    >
                                                        {topnav.title}
                                                    </NavLink>
                                                </NavItem>
                                            </>
                                        ))
                                    }
                                </Nav>
                                <TabContent className={"logs-tab bg-white border border-top-0"} activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <AddMaterialLogs inputFields={materialInputFields} setInputFields={setMaterialInputFields} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <AddEquipmentLog inputFields={equipmentInputFields} setInputFields={setEquipmentInputFields} />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <AddLabourLog inputFields={labourInputFields} setInputFields={setLabourInputFields} />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <HealthandSafety inputFields={hsInputFields} setInputFields={setHsInputFields} />
                                    </TabPane>
                                    <TabPane tabId="5">
                                     <PhotoLogs onDrop={onDrop} files={files} setFiles={setFiles} draggedFiles={draggedFiles} setDraggedFiles={setDraggedFiles} />
                                    </TabPane>
                                </TabContent>
                            </div>
                            <div className="container-fluid mt-3 p-3" style={{ background: "#F7F7F7" }}>
                                <h5 className="title">Extra Comments</h5>
                                <textarea  onChange={(e)=>setComments(e.target.value)} className={"form-control"} rows={2} placeholder={"Add notes"}></textarea>
                            </div>
                            <div className={"container-fluid d-flex justify-content-end mt-3"}>
                                <button className={"btn submit-button"} onClick={submitForm1}>
                                   Save & Submit
                                </button>
                            </div>
                        </div>
                    </form>

                </Modal>
            </div>
        </>
    )
}