import React, {useEffect, useState} from "react"
import {Card, CardBody, Col, Row} from "reactstrap";
import axios from "axios";
import {useParams} from "react-router-dom";
export default function ProjectDetailsReport(){
    const [loading, setLoading] = useState(true);
    const {id} = useParams()
    const[projectDetails, setProjectDetails]=useState(null)
    const getReportDetails = async ()=>{
        const api_url = process.env.REACT_APP_API_BASE + `projects/report/${id}`;
        try {
            const response = await axios.get(api_url, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token,
                }})
            const formattedData = {
                ...response.data.data,
                startDate:new Date(response.data.data.startDate).toLocaleDateString(),
                endDate:new Date(response.data.data.endDate).toLocaleDateString()
            }
            setProjectDetails(formattedData)

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }

    }
    useEffect(()=>{
        getReportDetails()
    }, [])
    if (loading) return <p>Loading...</p>;
    return (
        <>
            {
                projectDetails && (
                    <Card className="mt-3 p-1 rounded-0 black-50-bg w-100 h-100 mb-0 progress-section">
                        <CardBody>
                            <h5 className="primary-text-color fw-bold">
                                Report Summary
                            </h5>
                            <div className={"bg-white py-2 px-3 mt-3"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Project Name</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.projectName}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Project Progress</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.progress}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Project Details</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>Start Date <span>{projectDetails.startDate}</span></h6>
                                        <h6 className={"custom-report-summary-right"}>Completion Date <span>{projectDetails.endDate}</span></h6>
                                        <h6 className={"custom-report-summary-right"}>Lapsed time <span>{projectDetails.elapsedTime}</span></h6>
                                        <h6 className={"custom-report-summary-right"}>Time Remaining <span>{projectDetails.timeRemaining}</span></h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Client</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.client}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Engineer</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.engineer}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Architect</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.architect}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Quantity Surveyor</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.quantitySurveyor}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Structural Engineer</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.structuralEngineer}</h6>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Contractor</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.contractor}</h6>
                                    </Col>
                                </Row>
                            </div>

                            <div className={"bg-white py-2 px-3 mt-2"}>
                                <Row>
                                    <Col xl={4} md={4} lg={4}>
                                        <h6 className={"custom-report-summary-left"}>Project Description</h6>
                                    </Col>
                                    <Col>
                                        <h6 className={"custom-report-summary-right"}>{projectDetails.description}</h6>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                )
            }

        </>
    )
}