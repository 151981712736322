import React, {useEffect, useState} from "react"
import {Alert, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {map} from "lodash";
import * as url from "../../helpers/jengasmart_api_urls";
import axios from "axios";
import {useParams} from "react-router-dom";
export default function AddMaterialLogs({inputFields, setInputFields}){
    const {id} = useParams()
    const [error, setError] = useState(false);
    const [materialsModal, setMaterialsModal] = useState(false);
    const [materialsList, setMaterialsList] = useState([]);
    const [materialButtonDisabled, setMaterialButtonDisabled] = useState(true);
    const [materialInputFields, setMaterialInputFields] = useState([
        { materialName: '', materialUniqueId: '', materialQuantity: '', materialPrice: '' }
    ]);
    const [materialStuff, setMaterialStuff] = useState(false);
    function tog_materialsModal() {
        setMaterialsModal(!materialsModal);
    }
    const handleMaterialFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    };
    const handleMaterialFormSelChange = (index, event) => {
        let data = [...inputFields];
        data[index]['materialName'] = event.target.value;
        data[index]['materialUniqueId'] = event.target[event.target.selectedIndex].getAttribute('data-matunique');
        data[index]['materialPrice'] = event.target[event.target.selectedIndex].getAttribute('data-matprice');
        setInputFields(data);

    };
    const addMaterialFields = () => {
        let newfield = { materialName: '', materialUniqueId: '', materialQuantity: '', materialPrice: '' };
        setInputFields([...inputFields, newfield]);
    };
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getMaterialsList = async () => {
        var api_url = process.env.REACT_APP_API_BASE + url.POST_MATERIAL_DETAILS + '/project/' + id;
        axios.get(api_url, config)
            .then(res => {
                setMaterialsList(res.data.data);
                setMaterialButtonDisabled(false);
            })
            .catch(error => {
                setMaterialButtonDisabled(true);
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        /*  props.history.push('/login');*/
                    } else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }
    const removeMaterialFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };
    useEffect(() => {
        getMaterialsList()
    }, [])


    return (
        <>
            <Modal
                isOpen={materialsModal}
                toggle={() => {
                    tog_materialsModal();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Materials
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setMaterialsModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {map(inputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="6" xs="6">
                                <div className="mb-3">
                                    <label>Material</label>
                                    <select
                                        className="form-select"
                                        name="materialName"
                                        onChange={(e) => handleMaterialFormSelChange(key, e)}
                                        value={item.materialName}>
                                        <option value="">Select Item</option>
                                        {map(materialsList, (materialsItem, index) => (
                                            <option value={materialsItem.materialName} key={index} data-matunique={materialsItem.matUniqueIdentifier} data-matprice={materialsItem.price}>{materialsItem.materialName}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                            <Col md="5" xs="5">
                                <FormGroup className="mb-3">
                                    <label>Quantity</label>
                                    <Input
                                        name="materialQuantity"
                                        className="form-control"
                                        placeholder="Enter quantity"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleMaterialFormChange(key, e)}
                                        value={item.materialQuantity} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1"  className={"d-flex align-items-center"} style={{ paddingLeft: 0 }}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeMaterialFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn add-new"
                                onClick={addMaterialFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add material item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_materialsModal();
                        }}
                        className="btn cancel-button"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn submit-button" onClick={() => {
                        setMaterialsModal(false);
                        setMaterialStuff(true);
                    }}>
                        Add
                    </button>
                </div>
                {/* </Form> */}
            </Modal>

            <div className={"container d-flex justify-content-between align-items-center  px-3 py-3"}>
                <h5>Materials Used</h5>
                <button type={"button"} className={"btn submit-button py-2"} onClick={tog_materialsModal}>+ Add Materials</button>
            </div>
            <div className={"container px-3"}>
                <table className={"table task-progress mt-3 border"}>
                    <thead>
                    <tr>
                        <th>Material</th>
                        <th>Quantity</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        inputFields ? <>
                            {
                               inputFields.map((material, index)=>(
                                    <tr key={index}>
                                        <td>{material.materialName}</td>
                                        <td>{material.materialQuantity}</td>

                                    </tr>
                                ))
                            }
                        </> : <>

                        </>
                    }
                    </tbody>
                </table>
            </div>

        </>
    )
}