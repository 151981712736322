import React, {useEffect} from "react";

export default function MaterialCostBreakdown({data}){
    useEffect(()=>{
        console.log(data, "data")
    }, [])
    return (
        <>
            <table className={"table task-progress mt-3 border"}>
                <thead>
                <tr>
                    <th>Material</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                    <th>Total Price</th>
                </tr>
                </thead>
                <tbody>
                {
                    data.length > 0 ? <>
                        {
                            data.map((item, index)=>(
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.unit_price}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.total_price}</td>
                                </tr>
                            ))
                        }
                    </> : <>
                   <tr>
                       <td colSpan={4}>No records found</td>
                   </tr>
                    </>
                }
                <tr>

                </tr>
                </tbody>

            </table>
        </>
    )
}