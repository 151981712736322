import React, {useState, useEffect} from "react"
import {Col, Input, Label, Row} from "reactstrap";
import axios from "axios";
import * as url from "../../../../helpers/jengasmart_api_urls";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
export default function ProjectStakeholders({project}){
    const {id} = useParams()
    const [formData, setFormData] = useState({
        client: "",
        engineer: "",
        architect: "",
        quantitySurveyor: "",
        structuralEngineer: "",
        contractor:"",
        mechanicalEngineer:"",
        electricalEngineer:"",

    });
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const api_url = process.env.REACT_APP_API_BASE + 'projects/' + id;
    useEffect(() => {
        if (project) {
            setFormData({
                client: project.client || "",
                engineer: project.engineer || "",
                architect: project.architect || "",
                quantitySurveyor: project.quantitySurveyor || "",
                structuralEngineer: project.structuralEngineer || "",
                contractor:project.contractor || ""
            });
        }
    }, [project]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = { ...formData };
        try {
            await axios.put(api_url, payload, config)
            toast.success("Project stakeholders updated successfully",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        } catch (e) {
            toast.error("An error occurred! Please try again later",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        }

    };

    return (
        <>

            <h4 className="card-title fw-bold">Project Stakeholders</h4>

            <form onSubmit={handleSubmit}>
                <Row>
                    <Col xl={6} md={6}>
                        <div className="mb-3 mt-3">
                            <Label>Client</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="client"
                                value={formData.client}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <Label>Lead Consultant/Project Manager</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="engineer"
                                value={formData.engineer}
                                onChange={handleChange}

                            />
                        </div>

                        <div className="mb-3">
                            <Label>Architect</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="architect"
                                value={formData.architect}
                                onChange={handleChange}

                            />
                        </div>

                        <div className="mb-3">
                            <Label>Quantity Surveyor</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="quantitySurveyor"
                                value={formData.quantitySurveyor}
                                onChange={handleChange}

                            />
                        </div>

                        <div className="mb-3">
                            <Label>Structural Engineer</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="structuralEngineer"
                                value={formData.structuralEngineer}
                                onChange={handleChange}

                            />
                        </div>
                        <div className="mb-3">
                            <Label>Mechanical Engineer</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="structuralEngineer"
                                value={formData.mechanicalEngineer}
                                onChange={handleChange}

                            />
                        </div>
                        <div className="mb-3">
                            <Label>Electrical Engineer</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="structuralEngineer"
                                value={formData.electricalEngineer}
                                onChange={handleChange}

                            />
                        </div>
                        <div className="mb-3">
                            <Label>Contractor</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="contractor"
                                value={formData.contractor}
                                onChange={handleChange}

                            />
                        </div>
                        <button type="submit" className="btn w-100 text-white" style={{ backgroundColor: "#194973" }}>
                            Save
                        </button>
                    </Col>
                </Row>
            </form>
        </>
    )
}