import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import axios from "axios";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Row, Table} from "reactstrap";
import FeatherIcon from "feather-icons-react";
export default function ValuationReport() {
    const[tasksList, setTasksList]=useState([])
    const [openTask, setOpenTask] = useState(null);
    const [openSubTask, setOpenSubTask] = useState(null);
    const [valuation, setValuation]=useState([])
    const toggleTask = (taskId) => {
        setOpenTask(openTask === taskId ? null : taskId);
    };
    const toggleSubTask = (subTaskId) => {
        setOpenSubTask(openSubTask === subTaskId ? null : subTaskId); // Toggle the open task
    };
    const {id}=useParams()
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    var api_url =  process.env.REACT_APP_API_BASE + `valuation-reports/${id}`;
    const getValuationReports = async () =>{
        try {
            const response = await axios(api_url, config)
            setTasksList(response.data.results.parentTasks)
            setValuation(response.data.results)
        } catch (e) {

        } finally {

        }
    }
    useEffect(()=>{
        getValuationReports()
    }, [])
    return (
        <>
            <table className={"table task-progress mt-3 border"}>
                <thead>
                <tr>
                    <th>Description</th>
                    <th>Contract Amount (Kshs)</th>
                    <th>% Complete</th>
                    <th>Amount Due</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td></td>
                    <td className={"fw-bold"}><span style={{color:"black"}}>{valuation.totalContractAmount}</span></td>
                    <td></td>
                    <td className={"fw-bold"}><span style={{color:"black"}}>{valuation.totalAmountDue} </span></td>
                </tr>
                {tasksList.length > 0 ? (
                    tasksList.map((task) => (
                        <React.Fragment key={task.taskUniqueIdentifier}>
                            <tr onClick={() => toggleTask(task.taskUniqueIdentifier)}>
                                <td> {openTask === task.taskUniqueIdentifier ? <>
                                    <FeatherIcon
                                        icon="chevron-right"
                                        className={"table-icon"}
                                    />
                                </> : <>
                                    <FeatherIcon
                                        icon="chevron-down"
                                        className={"table-icon"}
                                    />
                                </>}{task.taskName}</td>
                                <td>{task.contractAmount}</td>
                                <td></td>
                                <td>{task.amountDue}</td>
                            </tr>
                            {task.subtasks && task.subtasks.length > 0 && (
                                task.subtasks.map((subtask, index) => (
                                    <>
                                        <tr onClick={()=>toggleSubTask(subtask.taskUniqueIdentifier)}  key={`${task.taskUniqueIdentifier}-${index}`} className={"subtask"}>
                                            <td> {subtask.subtasks.length > 0 && <>
                                                {openSubTask === subtask.taskUniqueIdentifier ? <>
                                                    <FeatherIcon
                                                        icon="chevron-right"
                                                        className={"table-icon"}
                                                    />
                                                </> : <>
                                                    <FeatherIcon
                                                        icon="chevron-down"
                                                        className={"table-icon"}
                                                    />
                                                </>}
                                            </>}
                                                {subtask.taskName}</td>
                                            <td>{subtask.contractAmount}</td>
                                            <td>{subtask.percentageProgress} %</td>
                                            <td>{subtask.amountDue}</td>
                                        </tr>
                                        {
                                            subtask.subtasks && subtask.subtasks.length > 0 && (
                                                subtask.subtasks.map((subtask1, subtask1index)=>(
                                                    <>
                                                        <tr key={`${subtask.taskUniqueIdentifier}-${subtask1index}`} className={"subtask1"}>
                                                            <td>{subtask1.taskName}</td>
                                                            <td>{subtask1.contractAmount}</td>
                                                            <td>{subtask1.percentageProgress} %</td>
                                                            <td>{subtask1.amountDue}</td>
                                                        </tr>
                                                    </>
                                                ))
                                            )
                                        }
                                    </>
                                ))
                            )}

                        </React.Fragment>
                    ))
                ) : (
                    <tr>
                        <td colSpan={5}>No tasks completed</td>
                    </tr>
                )}
                </tbody>

            </table>
        </>
    )
}