import React, {useEffect, useState} from "react"
import {Alert, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {map} from "lodash";
import * as url from "../../helpers/jengasmart_api_urls";
import axios from "axios";
import {useParams} from "react-router-dom";
export default function AddEquipmentLog({inputFields, setInputFields}){
    const {id} = useParams()
    const [error, setError] = useState(false);
    const [equipmentModal, setEquipmentModal] = useState(false);
    const [equipmentStuff, setEquipmentStuff] = useState(false);
    const [equipmentList, setEquipmentList] = useState([]);
    const [equipmentButtonDisabled, setEquipmentButtonDisabled] = useState(true);
    function tog_equipmentModal() {
        setEquipmentModal(!equipmentModal);
    }

    /* Equipment Items */
    const handleEquipmentFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    };

    const handleEquipmentFormSelChange = (index, event) => {
        let data = [...inputFields];
        data[index]['equipmentName'] = event.target.value;
        data[index]['equipmentRatePrice'] = event.target[event.target.selectedIndex].getAttribute('data-equrateprice');
        setInputFields(data);
    };

    const addEquipmentFields = () => {
        let newfield = { equipmentName: '', equipmentNumber: '', equipmentRatePrice: '' };

        setInputFields([...inputFields, newfield]);
    };

    const removeEquipmentFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getEquipmentList = async () => {
        var api_url1 = process.env.REACT_APP_API_BASE + url.POST_EQUIPMENT_DETAILS + '/project/' + id;
        axios.get(api_url1, config)
            .then(res => {
                setEquipmentList(res.data.data);
                setEquipmentButtonDisabled(false);
            })
            .catch(error => {
                setEquipmentButtonDisabled(true);
                if (error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        /*props.history.push('/login');*/
                    }
                    else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }
    useEffect(() => {
        getEquipmentList()
    }, [])


    return (
        <>

            <Modal
                isOpen={equipmentModal}
                toggle={() => {
                    tog_equipmentModal();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Equipment
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setEquipmentModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {map(inputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="7" xs="7">
                                <div className="mb-3">
                                    <label>Equipment</label>
                                    <select
                                        className="form-select"
                                        name="equipmentName"
                                        onChange={(e) => handleEquipmentFormSelChange(key, e)}
                                        value={item.equipmentName}>
                                        <option value="">Select Equipment</option>
                                        {map(equipmentList, (equipmentItem, index) => (
                                            <option value={equipmentItem.equipmentName} key={index} data-equrateprice={equipmentItem.ratePrice}>{equipmentItem.equipmentName}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                            <Col md="4" xs="4">
                                <FormGroup className="mb-3">
                                    <label>Quantity</label>
                                    <Input
                                        name="equipmentNumber"
                                        className="form-control"
                                        placeholder="Enter quantity"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleEquipmentFormChange(key, e)}
                                        value={item.equipmentNumber} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1" className={"d-flex align-items-center"}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeEquipmentFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn add-new"
                                onClick={addEquipmentFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add equipment item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_equipmentModal();
                        }}
                        className="btn cancel-button "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn submit-button" onClick={() => {
                        setEquipmentModal(false);
                        setEquipmentStuff(true);
                    }}>
                        Add
                    </button>
                </div>
            </Modal>
            <div className={"container d-flex justify-content-between align-items-center  px-3 py-3"}>
                <h5>Equipment Used</h5>
                <button type={"button"} className={"btn submit-button py-2"} onClick={tog_equipmentModal}>+ Add Equipment</button>
            </div>
            <div className={"container px-3"}>
                <table className={"table task-progress mt-3 border"}>
                    <thead>
                    <tr>
                        <th>Equipment</th>
                        <th>Quantity</th>

                    </tr>
                    </thead>
                    <tbody>
                    {
                        inputFields ? <>
                            {
                                inputFields.map((item, index)=>(
                                    <tr key={index}>
                                        <td>{item.equipmentName}</td>
                                        <td>{item.equipmentNumber}</td>
                                    </tr>
                                ))
                            }
                        </> : <>

                        </>
                    }
                    </tbody>
                </table>
            </div>

        </>
    )
}