import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import axios from "axios";
export default function EquipmentReports(){
    const {id}=useParams()
    const[equipment, setEquipment]=useState([])
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    var api_url =  process.env.REACT_APP_API_BASE + `reports/equipment/${id}?startDate=${localStorage.getItem('startDate')}&endDate=${localStorage.getItem('endDate')}`;
    const getEquimpmentReports = async () =>{
        try {
            const response = await axios(api_url, config)
           setEquipment(response.data.data)
        } catch (e) {

        } finally {

        }
    }
    useEffect(()=>{
        getEquimpmentReports()
    }, [])
    return (
        <>
            <table className={"table task-progress mt-3 border"}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Supplier</th>
                </tr>
                </thead>
                <tbody>
                {
                    equipment.length > 0 ? <>
                        {
                            equipment.map((equip, index)=>(
                                <tr key={index}>
                                    <td>{equip.equipmentName}</td>
                                    <td>{equip.totalQuantity}</td>
                                    <td>{equip.owner}</td>
                                </tr>
                            ))
                        }
                    </> : <>

                    </>
                }

                </tbody>

            </table>
        </>
    )
}