import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Row, Table} from "reactstrap";
import {map} from "lodash";
import {EquipmentModal} from "../../../components/Modals/EquipmentModal";
import {useParams} from "react-router-dom";
import ChangeOrdersModal from "../../../components/Modals/ChangeOrdersModal";
import DashboardNavigation from "./DashboardNavigation";
import axios from "axios";
import UpdateChangeOrderModal from "../../../components/Modals/UpdateChangeOrderModal";
export default function Financials(){
    const{id}=useParams()
    const [showChangeOrdersModal, setChangeOrdersModal] = useState(false)
    const [showUpdateChangeOrdersModal, setUpdateChangeOrdersModal] = useState(false)
    const [changeOrderList, setChangeOrderList] = useState([])
    const [selectedChangeOrder, setSelectedChangeOrder] = useState(null);
    const tog_changeOrderModal = () =>{
        setChangeOrdersModal(!showChangeOrdersModal)
    }
    const tog_updateChangeOrderModal = (item) =>{
        setSelectedChangeOrder(item)
        setUpdateChangeOrdersModal(true)
    }
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getChangeOrders = async ()=>{
        const CHANGE_ORDER_URL = process.env.REACT_APP_API_BASE + "change-orders/project/" + id
        const response =  await axios.get(CHANGE_ORDER_URL, config)
        setChangeOrderList(response.data.data)
    }
    const refresh = ()=>{
        getChangeOrders()
    }
    useEffect(()=>{
        getChangeOrders()
    }, [])
    return (
        <>
            <div className="page-content">
                <DashboardNavigation projectTitle={"Change Orders"} />
                <ChangeOrdersModal showModal={showChangeOrdersModal} refresh={refresh} closeModal={tog_changeOrderModal} />
                <UpdateChangeOrderModal showModal={showUpdateChangeOrdersModal} selectedOrder={selectedChangeOrder} refresh={refresh} closeModal={() =>setUpdateChangeOrdersModal(false)} />
                <div className={"container-fluid px-3"}>
                    <Row>
                        <Col sm="12">
                            <Row className={"d-flex align-items-center"}>
                                <Col xl={6} md={6} xs={12}>
                                    <h4 className="card-title">Change Orders</h4>
                                </Col>
                                <Col xl={6} md={6} xs={12} style={{ textAlign: "right" }}>
                                    <Button
                                        type="button"
                                        className={"add-new mb-3"}
                                        onClick={() => setChangeOrdersModal(true)}
                                    >
                                        + New Change Order
                                    </Button>
                                </Col>
                            </Row>
                            <Card>
                                <CardBody className={"p-0"}>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead>
                                            <tr>
                                                <th>CO #</th>
                                                <th>Title</th>
                                                <th>Status</th>
                                                <th>Scope</th>
                                                <th>Date Added</th>
                                                <th>Date Due</th>
                                                <th>Requestor</th>
                                                <th>Total Costs</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {changeOrderList.length === 0 ? (
                                                <tr>
                                                    <td colSpan={7} className="text-center">
                                                        No Change Orders Added
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {map(changeOrderList, (item, key) => (
                                                        <tr key={key} onClick={()=>tog_updateChangeOrderModal(item)}>
                                                            <td scope="row">{key + 1}</td>
                                                            <td>{item.changeOrderTitle}</td>

                                                            <td className={"text-capitalize"} ><span className={item.changeOrderStatus==="approved" ? "badge status-badge": "badge progress-status-badge"}>{item.changeOrderStatus}</span></td>
                                                            <td>{item.taskType === 'parenttask' ? (item.parentTaskTagged?.taskName || 'N/A') : 
                                                               item.taskType === 'subtask' ? (item.subTaskTagged?.taskName || 'N/A') : 
                                                               item.taskType === 'task' ? (item.taskTagged?.taskName || 'N/A') : 
                                                               'Out of Scope'}</td>
                                                            <td>{new Date(item.createdAt).toLocaleString()}</td>
                                                            <td>{new Date(item.changeOrderDate).toLocaleDateString()}</td>
                                                            <td>{item.requestedBy.firstName} {item.requestedBy.lastName}</td>
                                                            <td>{item.totalCosts}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}