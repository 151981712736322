import React, {useEffect, useState} from "react"
import {Alert, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {map} from "lodash";
import * as url from "../../helpers/jengasmart_api_urls";
import axios from "axios";
import {useParams} from "react-router-dom";
export default function AddLabourLog({inputFields, setInputFields}){
    const {id} = useParams()
    const [error, setError] = useState(false);
    const [labourModal, setLabourModal] = useState(false);
    const [labourStuff, setLabourStuff] = useState(false);
    const [labourList, setLabourList] = useState([]);
    const [labourInputFields, setLabourInputFields] = useState([
        { labourName: '', labourNumber: '', labourRatePrice: '' }
    ]);
    const [LabourButtonDisabled, setLabourButtonDisabled] = useState(true);
    function tog_labourModal() {
        setLabourModal(!labourModal);
    }

    /* Labour Items */
    const handleLabourFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setLabourInputFields(data);
    };

    const handleLabourFormSelChange = (index, event) => {
        let data = [...inputFields];
        data[index]['labourName'] = event.target.value;
        data[index]['labourRatePrice'] = event.target[event.target.selectedIndex].getAttribute('data-labrateprice');
        setInputFields(data);
    };

    const addLabourFields = () => {
        let newfield = { labourName: '', labourNumber: '', labourRatePrice: '' };

        setInputFields([...inputFields, newfield]);
    };

    const removeLabourFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getLabourList = async () => {
        var api_url2 = process.env.REACT_APP_API_BASE + url.POST_LABOUR_DETAILS + '/project/' + id;
        axios.get(api_url2, config)
            .then(res => {
                setLabourList(res.data.data);
                setLabourButtonDisabled(false);
            })
            .catch(error => {
                setLabourButtonDisabled(true);
                if (error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        /*  props.history.push('/login');*/
                    }
                    else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }
    useEffect(() => {
        getLabourList()
    }, [])


    return (
        <>
            <Modal
                isOpen={labourModal}
                toggle={() => {
                    tog_labourModal();
                }}
            >
                {/* <Form onSubmit={e => addMaterial(e)}> */}
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adding Labour
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setLabourModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {map(inputFields, (item, key) => (
                        <Row className="mb-3" key={key}>
                            <Col md="6" xs="6">
                                <div className="mb-3">
                                    <label>Labour</label>
                                    <select
                                        className="form-select"
                                        name="labourName"
                                        onChange={(e) => handleLabourFormSelChange(key, e)}
                                        value={item.labourName}>
                                        <option value="">Select Role</option>
                                        {map(labourList, (labourItem, index) => (
                                            <option value={labourItem.labourName} key={index} data-labrateprice={labourItem.rate}>{labourItem.labourName}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                            <Col md="5" xs="5">
                                <FormGroup className="mb-3">
                                    <label>Quantity</label>
                                    <Input
                                        name="labourNumber"
                                        className="form-control"
                                        placeholder="Enter number"
                                        type="number"
                                        id="example-datetime-local-input1"
                                        min={0}
                                        onChange={(e) => handleLabourFormChange(key, e)}
                                        value={item.labourNumber} />
                                </FormGroup>
                            </Col>
                            {
                                key === 0 ? (
                                    <Col md="1" xs="1" />
                                ) : (
                                    <Col md="1" xs="1" className={"d-flex align-items-center"}>
                                        <i className="bx bx-x label-icon" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => removeLabourFields(key)}></i>
                                    </Col>
                                )
                            }

                        </Row>
                    ))}

                    <Row>
                        <Col md="12" className="text-right">
                            <button
                                type="button"
                                className="btn add-new"
                                onClick={addLabourFields}
                            >
                                <i className="bx bx-plus label-icon"></i> Add labour item
                            </button>
                        </Col>
                    </Row>

                    <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_labourModal();
                        }}
                        className="btn cancel-button "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn submit-button" onClick={() => {
                        setLabourModal(false);
                        setLabourStuff(true);
                    }}>
                        Add
                    </button>
                </div>
                {/* </Form> */}
            </Modal>
            <div className={"container d-flex justify-content-between align-items-center  px-3 py-3"}>
                <h5>Labour Used</h5>
                <button type={"button"} className={"btn submit-button py-2"} onClick={tog_labourModal}>+ Add Labour</button>
            </div>
            <div className={"container px-3"}>
                <table className={"table task-progress mt-3 border"}>
                    <thead>
                    <tr>
                        <th>Labour</th>
                        <th>Quantity</th>

                    </tr>
                    </thead>
                    <tbody>
                    {
                        inputFields ? <>
                            {
                                inputFields.map((item, index)=>(
                                    <tr key={index}>
                                        <td>{item.labourName}</td>
                                        <td>{item.labourNumber}</td>

                                    </tr>
                                ))
                            }
                        </> : <>

                        </>
                    }
                    </tbody>
                </table>
            </div>

        </>
    )
}