import React, {useState, useEffect} from "react"
import {Card, CardBody, Col, Modal, Progress} from "reactstrap";
import moment from "moment";
import RadialChart from "../RadialChart";
import {APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import * as url from "../../../helpers/jengasmart_api_urls";
import axios from "axios";
import {useParams} from "react-router-dom";
import DashboardNavigation from "./DashboardNavigation";

export default function Dashboard(props){
    const {id} = useParams()
    const [project, setProject] = useState({});
    const [stats, setStats] = useState({});
    const [projectOwner, setProjectOwner] = useState({});
    const [acceptModal, setAcceptModal] = useState(false);
    const [pageCollabItem, setPageCollabItem] = useState({});
    const [progress, setProgress] = useState([])
    const [weatherDetails, setWeatherDetails] = useState({});
    const [graphLoading, setGraphLoading] = useState(true);
    const GMAP_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/' + id;
    var stats_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/project/' + id;
    var progress_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/tasks/' + id;
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getProjectDetails = async () => {
        const projectinfo = await axios.get(api_url, config);
        setProject(projectinfo.data.data);

        var weather_url = `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_APICOM_KEY}&q=${projectinfo.data.data.latitude},${projectinfo.data.data.longitude}`
        var weatherRes = await axios.get(weather_url, config);
        setWeatherDetails(weatherRes.data);

        var collabItem = projectinfo.data.data.projectCollaborators.find(item => {
            return item.creator === true;
        });

        setProjectOwner(collabItem);

        var collabAcceptedItem = projectinfo.data.data.projectCollaborators.find(item => {
            return item.memberEmail === JSON.parse(localStorage.getItem('authUser')).user.email;
        });

        if(collabAcceptedItem.accepted === false) {
            // setPageCollabItem(collabItem);
            setAcceptModal(true);
        } else {
            setAcceptModal(false)
        }

        //Get Stats
        const projectstats = await axios.get(stats_url, config)
        setStats(projectstats.data.data);

        //Get Progress
        const prog = await axios.get(progress_url, config)
        setProgress(prog.data)
        setGraphLoading(false)
    }
    const acceptProject = async () => {
        var data = {
            memberEmail: JSON.parse(localStorage.getItem('authUser')).user.email
        };

        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/collaborators/accept/' + id;

        try {
            const acceptproj = await axios.put(api_url, data, config)
            setAcceptModal(false);
            window.location.reload();
        } catch (e) {
            console.log(e);
        }

    };

    const declineProject = () => {
        var data = {
            memberEmail: JSON.parse(localStorage.getItem('authUser')).user.email
        };
        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/collaborators/decline/' + id;
        try {

            const declineproj =  axios.put(api_url, data, config)
            window.location.href = '/projects';
        } catch (e) {
            console.log(e);
        }
    };

    /* const getWeatherDetails = async ()=>{
       var config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        var weather_url = `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_APICOM_KEY}&q=-0.299194,36.061444`
        axios.get(weather_url, config)
            .then(res => {
                setWeatherDetails(res.data);
            });
    } */


    useEffect(() => {
        getProjectDetails()
        // getWeatherDetails()
    }, []);

    return (
        <>
            <div className="page-content">
                <DashboardNavigation projectTitle={project.projectName}/>
                <Modal
                    isOpen={acceptModal}
                    /* toggle={() => {
                            tog_acceptModal();
                          }} */
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Accept Invite
                        </h5>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <p>
                                Please click to accept the invite before you can interact with
                                this project.
                            </p>
                            <p>
                                Alternatively you can decline to be a part of this project.
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                declineProject();
                            }}
                            className="btn btn-outline-primary"
                            data-dismiss="modal"
                        >
                            Decline
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => acceptProject()}
                        >
                            Accept
                        </button>
                    </div>
                </Modal>
                <div className={"container-fluid px-3"}>
                    <div className="d-flex flex-row flex-wrap flex-xl-nowrap  gap-3 w-100 ">
                        <div className="d-flex flex-column col-12 col-lg-8">
                            <div className="d-flex flex-row flex-md-wrap w-100 gap-2 mb-3">
                                <Col className="p-3 rounded-3 border flex-grow-1">
                                    <h6 className="fw-semibold text-black">
                                        Start Date
                                    </h6>
                                    <p className="fw-semibold primary-text-color fs-5 mb-0">
                                        {moment(project.startDate).format(
                                            "MMMM Do, YYYY"
                                        )}
                                    </p>
                                </Col>
                                <Col className="p-3 rounded-3 border flex-grow-1">
                                    <h6 className="fw-semibold text-black">Due Date</h6>
                                    <p className="fw-semibold primary-text-color fs-5 mb-0">
                                        {moment(project.endDate).format("MMMM Do, YYYY")}
                                    </p>
                                </Col>
                                <Col className="p-3 rounded-3 border flex-grow-1">
                                    <h6 className="fw-semibold text-black">Timeline</h6>
                                    {/* <p style={{ color: ((moment(new Date()) < moment(project.endDate)) ? 'green' : 'red') }}>{moment().to(moment(project.endDate))}</p> */}
                                    <p
                                        className="fw-semibold primary-text-color fs-5 mb-0"
                                        style={{
                                            color:
                                                moment(new Date()) < moment(project.endDate)
                                                    ? ""
                                                    : "red",
                                        }}
                                    >
                                        {Math.abs(
                                                moment(project.endDate).diff(
                                                    moment(new Date()),
                                                    "days"
                                                )
                                            ) +
                                            " " +
                                            (moment(new Date()) < moment(project.endDate)
                                                ? "days left"
                                                : "days overdue")}
                                    </p>
                                </Col>
                            </div>
                            <div className="d-flex flex-row w-100 gap-2 mb-3">
                                <div className="flex-grow-1">
                                    <Card className="p-3 rounded-3 border black-50-bg w-100 h-100  mb-0">
                                        <CardBody className="p-1">
                                            <div className="d-flex flex-row justify-content-between mb-4">
                                                <h6 className="primary-text-color fw-bold">
                                                    {" "}
                                                    Project Details{" "}
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="d-flex flex-row justify-between mb-3 border-bottom">
                                                    <div className="w-50">
                                                        <h6 className="fw-bold">
                                                            Project creator{" "}
                                                        </h6>
                                                    </div>
                                                    <div className="w-50">
                                                        <span>{projectOwner?.memberEmail}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-between mb-3 border-bottom">
                                                    <div className="w-50">
                                                        <h6 className="fw-bold">Collaborators</h6>
                                                    </div>
                                                    <div className="w-50">
                                                        <span>{project.projectCollaborators?.length}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-between mb-3 border-bottom">
                                                    <div className="w-50">
                                                        <h6 className="fw-bold">Client</h6>
                                                    </div>
                                                    <div className="w-50">
                                                        <span>{project.client}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-between mb-3 border-bottom">
                                                    <div className="w-50">
                                                        <h6 className="fw-bold">Type</h6>
                                                    </div>
                                                    <div className="w-50">
                                                        <span>{project.projectType}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="flex-grow-1">
                                    <Card className="p-3 rounded-3 border black-50-bg w-100  mb-0">
                                        <CardBody className="p-1">
                                            <div className="d-flex flex-row justify-content-between mb-2">
                                                <h6 className="primary-text-color fw-bold">
                                                    Cost Tracking
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div
                                                    className="d-flex flex-row justify-content-center align-items-center gap-3">
                                                    <div className="w-50">
                                                        {graphLoading ? (
                                                            <div className="text-center mt-5 mb-5">
                                                                <p className="placeholder-glow">
                                            <span
                                                className="placeholder col-4"
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                    borderRadius: "50%",
                                                }}
                                            ></span>
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <RadialChart
                                                                currency={project.currency}
                                                                expenditure={stats.totalExpenditure}
                                                                expectedBudget={project.budget}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="d-flex w-50 flex-column">
                                                        <div className="mb-2 border-bottom">
                                                            <h6 className="primary-text-color fw-bold mb-1 fs-5">
                                                                Expenditure
                                                            </h6>
                                                            <p className="fw-semibold">
                                                                {project.currency} {stats.totalExpenditure ? stats.totalExpenditure.toLocaleString(undefined, {minimumFractionDigits: 2}) : 0}
                                                            </p>
                                                        </div>
                                                        <div className="mb-2 border-bottom">
                                                            <h6 className="primary-text-color fw-bold mb-1 fs-5">
                                                                Total Budget
                                                            </h6>
                                                            <p className="fw-semibold">
                                                                {project.currency} {project.budget ? project.budget.toLocaleString(undefined, {minimumFractionDigits: 2}) : 0}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className="d-flex flex-row w-100">
                                <Card className="p-3 rounded-3 border black-50-bg w-100">
                                    <CardBody>
                                        <div className="d-flex flex-row justify-content-between mb-4">
                                            <h6 className="primary-text-color fw-bold">
                                                {" "}
                                                Project Progress{" "}
                                            </h6>
                                            <a
                                                onClick={() => toggle("2")}
                                                // to={`/projects/${props.match.params.id}?selectedtab=2`}
                                                className="d-flex flex-row primary-text-color fw-semibold align-items-baseline"
                                            >
                                                <span> Open Scheduler </span>
                                                <i
                                                    className="fas fa-chevron-right primary-text-color"
                                                    style={{
                                                        fontSize: "13px",
                                                        color: "",
                                                        marginLeft: "5px",
                                                    }}
                                                ></i>
                                            </a>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between mb-3">
                                            <div>
                                                <h6 className="fw-medium text-black-50">
                                                    {/* Check of the percentageCompletedTask is null*/}
                                                    {
                                                        stats.percentageCompletedTask ? <>
                                                            {Math.round(stats.percentageCompletedTask)}% Completed
                                                        </> : <>
                                                            0% Completed
                                                        </>
                                                    }
                                                </h6>
                                                <h6 className="fw-semibold fs-6">
                                                    {Math.abs(
                                                        moment(project.endDate).diff(
                                                            moment(new Date()),
                                                            "days"
                                                        )
                                                    )}{" "}
                                                    Days Remaining for {project.projectName}
                                                </h6>
                                            </div>
                                            <div>
                                                <h6 className="fw-medium text-black-50">
                                                    {" "}
                                                    Target Completion Date{" "}
                                                </h6>
                                                <h6 className="fw-semibold fs-6">
                                                    {" "}
                                                    {moment(project.endDate).format(
                                                        "MMMM Do, YYYY"
                                                    )}{" "}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row mb-3">
                                            <div className="w-100">
                                                <Progress
                                                    value={stats.percentageCompletedTask}
                                                    className="progress-lg"
                                                    style={{
                                                        height: "22px",
                                                        backgroundColor: "#0000001F",
                                                        borderRadius: "24px",
                                                    }}
                                                ></Progress>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row gap-3">
                                            <div className="d-flex border-end task-border-color align-items-center">
                                                <h6 className="fw-bold mb-0">
                                                    {" "}
                                                    Total Tasks{" "}
                                                </h6>
                                                <div className="task-badge mx-2">
                                                    <h6 className="fw-bold primary-text-color mb-0">
                                                        {progress.totalTasks}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="d-flex border-end task-border-color align-items-center">
                                                <h6 className="fw-bold mb-0"> Completed </h6>
                                                <div className="task-badge mx-2">
                                                    <h6 className="fw-bold primary-text-color mb-0">
                                                        {progress.totalCompletedTasks}
                                                    </h6>
                                                </div>
                                            </div>
                                            {/*<div className="d-flex border-end task-border-color align-items-center">
                                  <h6 className="fw-bold mb-0"> In Progress </h6>
                                  <div className="task-badge mx-2">
                                    <h6 className="fw-bold primary-text-color mb-0">
                                     {scheduleStats.inProgress}
                                    </h6>
                                  </div>
                                </div>*/}
                                            <div className="d-flex  task-border-color align-items-center">
                                                <h6 className="fw-bold mb-0"> Not Started </h6>
                                                <div className="task-badge mx-2">
                                                    <h6 className="fw-bold primary-text-color mb-0">
                                                        {progress.totalNotStartedTasks}
                                                    </h6>
                                                </div>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        {/* second column */}
                        <div className="d-flex flex-column flex-grow-1">
                            {/* <div className="card p-3 rounded-3 border black-50-bg w-100"></div> */}
                            {/* weather */}
                            <Card className="p-3 rounded-3 border black-50-bg w-100 mb-3" style={{height: "50%"}}>
                                {/* <CardBody className="p-2"> */}
                                <h6 className="primary-text-color fw-bold">
                                    {weatherDetails?.location?.name}
                                </h6>
                                <p className="fw-semibold">{moment(weatherDetails?.location?.localtime).format(
                                    "MMM Do, YYYY, h:mm"
                                )}</p>

                                <div className="mb-2 text-center">
                                    <img src={weatherDetails?.current?.condition?.icon} alt=""/>
                                </div>

                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                                        <div className="w-20">
                                            <div className="mb-2">
                                                <h1 className="primary-text-color fw-regular mb-1">
                                                    {weatherDetails?.current?.temp_c}&deg;C
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="d-flex w-80 flex-column">
                                            <div className="mb-2">
                                                <p className="primary-text-color fw-regular mb-1">
                                                    {weatherDetails?.current?.condition?.text}
                                                </p>
                                                <p className="primary-text-color fw-regular mb-1">
                                                    Humidity: <b>{weatherDetails?.current?.humidity}</b>
                                                </p>
                                                <p className="primary-text-color fw-regular">
                                                    Wind: <b>{weatherDetails?.current?.wind_kph}</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <p className="primary-text-color fw-regular">
                                            Powered by <a href="https://www.weatherapi.com/"
                                                          title="Weather API">WeatherAPI.com</a>
                                        </p>
                                    </div>
                                </div>
                                {/* <ReactWeather
                              isLoading={isLoading}
                              errorMessage={errorMessage}
                              data={data}
                              lang="en"
                              locationLabel="Nairobi"
                              unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                              showForecast
                            /> */}

                                {/* </CardBody> */}
                            </Card>
                            {/* MAP */}
                            <Card className="rounded-3 border black-50-bg w-100 mb-3" style={{height: "50%"}}>
                                {
                                    project.latitude ? <>
                                        <APIProvider apiKey={GMAP_KEY}>
                                            <Map
                                                zoom={15}
                                                center={{lat: project.latitude, lng: project.longitude}}
                                                gestureHandling={"greedy"}
                                                disableDefaultUI={true}
                                            >
                                                <Marker position={{lat: project.latitude, lng: project.longitude}}/>
                                            </Map>
                                        </APIProvider>
                                    </> : <>
                                        <APIProvider apiKey={GMAP_KEY}>
                                            <Map
                                                zoom={15}
                                                center={{lat: -0.299194, lng: 36.061444}}
                                                gestureHandling={"greedy"}
                                                disableDefaultUI={true}
                                            >
                                                <Marker position={{lat: -0.299194, lng: 36.061444}}/>
                                            </Map>
                                        </APIProvider>
                                    </>
                                }
                            </Card>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};