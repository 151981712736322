import React, {useEffect, useState} from 'react'
import {Card, CardBody, Col, Row} from "reactstrap";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {useParams} from "react-router-dom";
export default function CustomReports({handleViewReport, setSelectedReportTypes}){
    const reportTypes = [
        'Progress Report',
        'Cost Report',
        'Equipment',
        'Labour',
        'Materials',
        'Photos',
        'Change Orders',
        'Health Safety Report',
        'Valuation Report'
    ]
    const [checkedState, setCheckedState] = useState(
        new Array(reportTypes.length).fill(false)
    );
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const[description, setDescription]=useState("")
    const {id}=useParams()
    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };
    const [reports, setReports] = useState([])
    const handleOnChange = (position) => {
        setCheckedState(prevState => {
            const updatedCheckedState = [...prevState];
            updatedCheckedState[position] = !prevState[position];
            return updatedCheckedState;
        });
    };
    const  config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token,
        } }
    const [selectAll, setSelectAll] = useState(false);
    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setCheckedState(new Array(reportTypes.length).fill(newSelectAll));
    };
    const getSelectedReports = async () => {
        if (startDate.length > 0 && endDate.length > 0){
            const reporttyps = reportTypes.filter((_, index) => checkedState[index]);
            localStorage.setItem('startDate', startDate)
            localStorage.setItem('endDate', endDate)
            setSelectedReportTypes(reporttyps)
            if (reporttyps.length > 0) {
                handleViewReport()
                const api_url = process.env.REACT_APP_API_BASE + `reports`;
                const payload = {
                    projectId: id,
                    description:description,
                    report:reporttyps,
                    startDate:startDate,
                    endDate: endDate,
                    userId:JSON.parse(localStorage.getItem('authUser')).user.id
                }
                try {
                    const response = await axios.post(api_url, payload, config)
                    getGeneratedReports()
                }catch (e) {

                }

            } else {
                toast.error("Select at least one report",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
            }

        } else {
            toast.error("Enter the start and end date",{
                position: toast.POSITION.TOP_RIGHT,
                toastId: "logId"
            })
        }

    };
    const getGeneratedReports = async ()=>{
        try {
            const api_url = process.env.REACT_APP_API_BASE + `reports/project/${id}`;
            const response = await axios.get(api_url,config )
            setReports(response.data.data)
        } catch (e) {

        }

    }

    useEffect(()=>{
        getGeneratedReports()
    }, [])
    return (
        <>
            <ToastContainer autoClose={5000} />
            <div className={"container-fluid px-3"}>
                <h6 className="primary-text-color fw-bold">
                    Create Report
                </h6>
                <Card className="p-1 rounded-0 black-50-bg w-100 h-100 mb-0">
                    <CardBody>
                        <div className={"d-flex flex-row flex-md-wrap w-100 gap-1 mb-3"}>
                            <Col className="p-2 rounded-3 flex-grow-1">
                                <div className={"mb-3"}>
                                    <label>Start Date</label>
                                   <input type={"date"} className={"form-control"} placeholder={"Start Date"} value={startDate}
                                          onChange={handleStartDateChange}/>
                                </div>
                            </Col>
                            <Col className="p-2 rounded-3 flex-grow-1">
                                <div className={"mb-3"}>
                                    <label>End Date</label>
                                    <input type={"date"} className={"form-control"} placeholder={"End Date"} value={endDate}
                                           onChange={handleEndDateChange}/>
                                </div>
                            </Col>
                        </div>
                        <div className={"container-fluid d-flex justify-content-between mb-3"}>
                            <h5>Choose Reports</h5>
                            <button className={"btn btn-primary-outline"} onClick={handleSelectAllChange}>Select All</button>
                        </div>
                        <Row>
                            {
                                reportTypes.map((report, index)=>(
                                   <>
                                       <Col xl={3} md={3} lg={3} xs={6} >
                                            <div className={"container custom-report-checkbox mb-3 d-flex align-items-center p-2"}>
                                                <label key={index}>
                                                    <input
                                                        type="checkbox"
                                                        name={report}
                                                        value={report}
                                                        checked={checkedState[index]}
                                                        onClick={() => handleOnChange(index)}
                                                    /> {report}
                                                </label>
                                            </div>

                                       </Col>
                                   </>
                                ))
                            }

                        </Row>
                        <div className={"mb-3"}>
                            <label>Description (Optional)</label>
                            <textarea onChange={(e)=>setDescription(e.target.value)} className={"form-control"} rows={4} placeholder={"Add description"}>

                            </textarea>
                        </div>
                        <div className={"container-fluid d-flex justify-content-end"}>
                            <button onClick={()=>getSelectedReports()} className={"btn text-white"} style={{background:"rgb(25, 73, 115)"}}>
                                Generate Report
                            </button>
                        </div>
                    </CardBody>
                </Card>
                <div className={"container-fluid mt-4"}>
                    <h6 className="primary-text-color fw-bold">
                        Generated Reports
                    </h6>
                    <table className={"table task-progress mt-3 border"}>
                        <thead>
                        <tr>
                            <th>Date Created</th>
                            <th>Report Start Date</th>
                            <th>Report End Date</th>
                            <th>Created By</th>
                            <th className={"text-end"}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            reports.length > 0 ? <>
                                {
                                    reports.map((report, index)=>(
                                        <>
                                            <tr key={index}>
                                                <td>{new Date(report.createdAt).toLocaleString()}</td>
                                                <td>{new Date(report.startDate).toLocaleDateString()}</td>
                                                <td>{new Date(report.endDate).toLocaleDateString()}</td>
                                                <td>{report.userId?.firstName} {report.userId?.lastName}</td>
                                                <td className={"text-end"}><img src={"/Trash.png"}/></td>
                                            </tr>
                                        </>
                                    ))
                                }
                            </> : <>

                            </>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}