import PropTypes from 'prop-types'
import React , {useState,useEffect} from "react"
import {
    Modal,
    ModalBody,
    Alert, Row, Col
} from "reactstrap"
import { Formiz, useForm } from '@formiz/core'
import { InputField } from "../forms/InputField.js";
import { SelectField } from '../forms/SelectField.js';
import { useCompanyInventory } from '../../hooks/inventoryHook.js';
import {LoadingIndicator} from '../../components/Common'
import { useEquipment } from '../../hooks/equipmentHook.js';
import { TextAreaField } from '../forms/TextAreaField.js';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export function EditequipmentModal({isOpen,onCloseClick,projectId , data}){
    const [isitemSource,setItemSource] = useState(true)
    const {companyInventory} = useCompanyInventory()
    const { updateInvEquipment,isEquipmentUpdateLoading} = useEquipment(onCloseClick,projectId)
    

    const equipmentForm = useForm()
    const handleSubmit = async (values) => {
        // console.log("values",values)
        // console.log("data",data)
        let results = {
            ...values,
            _id: data._id
        }
         try {
            await updateInvEquipment(results)      
          } catch (error){
            console.log("error" , error)
          }

      

    }

    const handleItemSource = (type) => {
        setItemSource(prev => !prev)
      }

    const inventoryItems = companyInventory.map((item) => {
        return {
            label: item.inventoryName , value: item._id
        }
    })  

    return (
      <Modal isOpen={isOpen} toggle={onCloseClick} centered={true} size={"lg"}>
        <Formiz
          connect={equipmentForm}
          onValidSubmit={handleSubmit}
          initialValues={data}
        >
          <form
            className="w-100 needs-validation"
            noValidate
            onSubmit={equipmentForm.submit}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Equipment
              </h5>
              <button
                type="button"
                onClick={onCloseClick}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col xl={6} md={6} sm={12} lg={6} xs={6}>
                        <div className="mb-1">
                            <label htmlFor="equipment-name" className="form-Label">
                                Select Item Source
                            </label>
                            <SelectField
                                name={"capitalInvestment"}
                                //   required={"please select an item "}
                                data={[
                                    { label: "Self", value: true },
                                    { label: "loaned", value: false },
                                ]}
                                defaultOption="Select item source"
                            />
                        </div>
                    </Col>
                    <Col xl={6} md={6} sm={12} lg={6} xs={6}>
                        <div className="mb-1">
                            <label className="form-Label">Item Name</label>
                            <InputField
                                name={"equipmentName"}
                                required={"Please enter the name of the equipment"}
                                type="text"
                                isDefault={false}
                            />
                        </div>
                    </Col>
                    <Col xl={6} md={6} sm={12} lg={6} xs={6}>
                        <div className="mb-1">
                            <label className="form-Label">Owner</label>
                            <InputField
                                name={"owner"}
                                //   required={"Please enter the name of the equipment"}
                                type="text"
                                isDefault={false}
                            />
                        </div>
                    </Col>
                    <Col xl={6} md={6} sm={12} lg={6} xs={6}>
                        <div  className="mb-1">
                            <label className="form-Label">Total Quantity</label>
                            <InputField
                                name={"totalQuantity"}
                                required={"Please enter total quantity"}
                                type="number"
                                isDefault={false}
                            />
                        </div>
                    </Col>
                    <Col xl={6} md={6} sm={12} lg={6} xs={6}>
                        <div  className="mb-1" >
                            <label htmlFor="" className="form-Label">
                                Rate
                            </label>
                            <SelectField
                                name={"rate"}
                                //   required={"please select an item "}
                                data={[
                                    { label: "hour", value: "hour" },
                                    { label: "day", value: "day" },
                                ]}
                                defaultOption="Select rate"
                            />
                        </div>
                    </Col>
                    <Col xl={6} md={6} sm={12} lg={6} xs={6}>
                        <div  className="mb-1">
                            <label className="form-Label">Hiring price</label>
                            <InputField
                                name={"ratePrice"}
                                //   required={"Please enter total quantity"}
                                type="number"
                                isDefault={false}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="mb-1">
                    <label className="form-Label">Description</label>
                    <TextAreaField
                        name={"description"}
                        required={"please add a description"}
                        type="text"
                        placeholder={"Enter a description"}
                    />
                </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={onCloseClick}
                  className="btn cancel-button "
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn submit-button "
                  disabled={isEquipmentUpdateLoading}
                >
                  {isEquipmentUpdateLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <span>Save Equipment</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </Formiz>
        <ToastContainer />
      </Modal>
    );
}

EditequipmentModal.propTypes = {
    onCloseClick: PropTypes.func,
    isOpen: PropTypes.bool,
    data: PropTypes.object
    // projectId: PropTypes.string,
    // taskType: PropTypes.number,
    // data: PropTypes.object

}