import React, {useCallback, useState} from "react"
import {Col, Label, Row} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import {useDropzone} from "react-dropzone";
export default function PhotoLogs({onDrop, files, setFiles, draggedFiles, setDraggedFiles}){
    const onDropHandler = useCallback(
        (acceptedFiles) => {
            if (onDrop) {
                onDrop(acceptedFiles);
            }
        },
        [onDrop]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            setDraggedFiles(acceptedFiles);
            onDropHandler(acceptedFiles);
            const fileInfo = acceptedFiles.map(({ name, size, type, lastModified, preview }) => ({
                name,
                size,
                type,
                lastModified,

            }));

            setFiles(acceptedFiles.map(file=>Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        },
        accept: {
            'image/png': ['.png'],
            'image/jpg':['.jpg'],
            'image/jpeg':['.jpeg'],
        },

    });
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumb = {
        display: 'flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
        position:'relative'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };
    const handleDeleteImage = (imageToDelete) => {
        setFiles(prevFiles => prevFiles.filter(file => file.preview !== imageToDelete.preview));
    };

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
                <button
                    onClick={() => handleDeleteImage(file)}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: 'white',
                        backgroundColor:"red",
                        border: '1px solid red',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        height:'16px',
                        width:'16px',
                        justifyContent:"center",
                        alignItems:"center",
                        fontSize:"8px"
                    }}
                >
                    X
                </button>
            </div>
        </div>
    ));
    return (
        <>
            <div className={"container"}>
                <Row>
                    <Col md="12 my-3">
                        <Label htmlFor="basicpill-address-input" className="form-Label">Upload Photos</Label>
                        <div {...getRootProps()} className={`dropzone  ${isDragActive ? 'active text-center' : 'text-center'}`}>
                            <input {...getInputProps({accept: 'image/jpeg, image/png'})} />
                            {isDragActive ? (
                                <p>  <FeatherIcon
                                    icon="plus-circle"
                                /> Drop the files here ...</p>
                            ) : (
                                <>

                                    <p> <FeatherIcon
                                        icon="plus-circle"
                                    />  <br/>   Drag 'n' drop images here, or click to select files</p>
                                </>
                            )}
                        </div>
                        <aside style={thumbsContainer}>
                            {thumbs}
                        </aside>
                    </Col>
                </Row>
            </div>
        </>
    )
}